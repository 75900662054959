let _objectionDetailsHelper = {
  objectionReasonId: {
    required: true,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Grounds for objection",
    errorText: "",
  },
  contendedSiteValue: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "",
    errorText: "",
  },
  contendedCapitalImprovedValue: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "",
    errorText: "",
  },
  contendedNetAnnualValue: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "",
    errorText: "",
  },
  objectionComment: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Objection comments",
    errorText: "",
  },
};

class ObjectionDetailsHelper {
  getObjectionDetailsHelper() {
    return _objectionDetailsHelper;
  }

  getFieldDetail(field) {
    let value = _objectionDetailsHelper[field];
    return value;
  }
}

// we need to instantiate the store
const objectionDetailsHelper = new ObjectionDetailsHelper();

export default objectionDetailsHelper;
