export function valueCheck(
  value,
  checkNull,
  checkEmpty,
  checkNotSelected,
  notSelectedValue
) {
  let isValidValue = true;

  if (
    !value ||
    (checkNull && value === null) ||
    (checkEmpty && value === "") ||
    (checkNotSelected && value === notSelectedValue)
  ) {
    isValidValue = false;
  }

  return isValidValue;
}
