//#region imports
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import AttachmentStore from "../../../stores/attachmentsStore";
import * as AttachmentActions from "../../../actions/attachmentsActions";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ClearIcon from "@material-ui/icons/Clear";
import { startLoader, stopLoader } from "../../../actions/applicationActions";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import {
  deleteRequest,
  deleteUpload,
  uploadRequest,
  getAttachmentUrlRequest,
} from "../../../api/apiRequest";
//#endregion imports

function PropertyDetailsAttachments(props) {
  var propertyId = props.propertyId;
  var objectorId = props.objectorId;

  const [filesState, setFilesState] = useState({
    open: false,
    files: [],
  });
  const [attachments, setAttachments] = useState(
    AttachmentStore.getAttachmentsByPropertyId(propertyId)
  );

  useEffect(() => {
    AttachmentStore.addChangeListener(onAttachmentchange);

    document.title = "Object to rating valuation - Property details";

    function onAttachmentchange() {
      setAttachments(AttachmentStore.getAttachmentsByPropertyId(propertyId));
    }

    return () => AttachmentStore.removeChangeListener(onAttachmentchange); //cleans up on unmount of component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region Change event handlers

  function handleClose() {
    setFilesState({
      files: [],
      open: false,
    });
  }

  function handleSave(files) {
    //Saving files to state for further use and closing Modal.
    setFilesState({
      files: files,
      open: false,
    });

    fileHandleChange(files);
  }

  function handleOpen() {
    setFilesState({
      open: true,
    });
  }

  function fileHandleChange(files) {
    if (!files) return;

    startLoader();
    for (let i = 0; i < files.length; i++) {
      let apiUrl =
        "/objector/" +
        encodeURIComponent(objectorId) +
        "/property/" +
        encodeURIComponent(propertyId) +
        "/attachment";

      getAttachmentUrlRequest(apiUrl, "POST", {
        mimeType: files[i].type,
        fileName: files[i].name,
      }).then((result) => {
        if (result) {
          uploadRequest(result.signedURL, "PUT", files[i]).then(() => {
            // we are not expecting a response from upload to signed URL
            let attachments = [];
            attachments.push({
              propertyId: propertyId,
              attachmentId: result.attachmentId,
              fileName: files[i].name,
              mimeType: files[i].type,
            });
            AttachmentActions.dispatchSaveAttachments(attachments);
            if (i === files.length - 1) {
              stopLoader();
            }
          });
        }
      });
    }
  }

  function deleteAttachment(event, attachment) {
    startLoader();

    if (attachment && attachment.attachmentId) {
      let apiUrl =
        "/objector/" +
        objectorId +
        "/property/" +
        propertyId +
        "/attachment/" +
        attachment.attachmentId;

      deleteRequest(apiUrl).then((response) => {
        deleteUpload(response.signedURL).then((result) => {
          // Updating the Flux store by removing the deleted attachment
          AttachmentActions.dispatchDeleteAttachments(attachment.attachmentId);

          setTimeout(() => {
            stopLoader();
          }, 100);
        });
      });
    }
  }

  return (
    <>
      <MDBContainer>
        <MDBRow className="helper-text-background">
          <MDBCol>
            <h5>
              <b>
                <b>Supporting Evidence</b>
              </b>
            </h5>
            Attach documents or photos which you believe may assist in the
            review of your valuation.
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
            <div className="attachment-section">
              <div className="new-attachment" id="attachment-button">
                <div>
                  <Button
                    aria-labelledby="attachment-button"
                    onClick={handleOpen}
                    className="button-style"
                  >
                    Add attachments <AttachmentIcon className="md-36" />
                  </Button>
                  <DropzoneDialog
                    open={filesState.open}
                    onSave={handleSave}
                    showFileNames={true}
                    maxFileSize={5000000}
                    onClose={handleClose}
                    acceptedFiles={[
                      "image/jpg",
                      "image/jpeg",
                      "image/png",
                      "image/bmp",
                      "image/gif",
                      "image/tiff",
                      "application/pdf",
                      "application/msword",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      "application/vnd.ms-excel",
                      "image/svg+xml",
                      "text/plain",
                    ]}
                  />
                </div>
              </div>
              <div className="existing-attachments">
                {attachments &&
                attachments &&
                attachments.length > 0 &&
                attachments[0].attachmentId !== ""
                  ? "Uploaded files:"
                  : "No files uploaded yet…"}{" "}
                {attachments &&
                  attachments.length > 0 &&
                  attachments.map((value, index) => {
                    return (
                      <div key={index}>
                        {value.attachmentId && value.attachmentId !== "" && (
                          <div>
                            <ClearIcon
                              className="md-36"
                              onClick={(event) =>
                                deleteAttachment(event, value)
                              }
                            />{" "}
                            &nbsp; &nbsp;
                            {value.fileName} &ensp;{" "}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default PropertyDetailsAttachments;
