import React from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import Councils from "../../Lists/Councils";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import { getNamefromList } from "../../utilities/getNameFromList";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Tooltip from "@material-ui/core/Tooltip";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import "./PropertyNoticeDetails.scss";

function PropertyNoticeDetails(props) {
  return (
    <div
      id="property-notice-details-label"
      className="property-notice-details-section"
    >
      <MDBRow>
        <MDBCol
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="mb-3 verify-property-delete pr-2"
        >
          {props.value.status !== "submitted" &&
          props.value.status !== "archived" ? (
            <Tooltip title="Delete property">
              <ClearIcon
                aria-label="delete"
                id={props.value.propertyId}
                onClick={props.deleteProperty}
              />
            </Tooltip>
          ) : null}
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="3" lg="3" md="3" sm="12" xs="12" className="mb-3 mb-lg-0">
          <Typography gutterBottom className="verified-property-heading">
            <span>Council</span> <br />
            {getNamefromList(Councils, props.value.councilId)}
          </Typography>
        </MDBCol>

        <MDBCol xl="3" lg="3" md="3" sm="12" xs="12" className="mb-3 mb-lg-0">
          <Typography gutterBottom className="verified-property-heading">
            <span>Address</span> <br />
            {props.value.address}
          </Typography>
        </MDBCol>

        <MDBCol xl="2" lg="2" md="2" sm="12" xs="12" className="mb-3 mb-lg-0">
          <Typography gutterBottom className="verified-property-heading">
            <span>Assessment number</span> <br />
            {props.value.assessmentNumber}
          </Typography>
        </MDBCol>

        <MDBCol
          xl="2"
          lg="2"
          md="2"
          sm="12"
          xs="12"
          className="verified-property-heading mb-3 mb-lg-0 property-verified-text"
        >
          {props.value.status !== "submitted" &&
           props.value.status !== "archived" ? (
            <div className="verified-property-icons">
              <div>
                <span>Verified</span> <br />
                <CheckCircleIcon
                  aria-label="Verified"
                  className="check-circle-icon"
                />
              </div>
            </div>
          ) : (
            <div className="verified-property-heading property-verified-text">
              <span>Submitted</span> <br />
              <DoneAllIcon
                aria-label="submitted"
                className="objection-submittied-icon mt-3"
              />
            </div>
          )}
        </MDBCol>

        {props.value.status !== "submitted" &&
         props.value.status !== "archived" &&  (
          <MDBCol xl="2" lg="2" md="2" sm="12" xs="12" className="">
            <div>
              <MDBBtn
                aria-labelledby="property-notice-details-label"
                className="button-style begin-property-button"
                type="submit"
                onClick={() => {
                  props.onVerifyClick(
                    props.value.status,
                    props.value.propertyId
                  );
                }}
                id={props.value.status}
                value={props.value.propertyId}
              >
                {"Begin"}
              </MDBBtn>
            </div>
          </MDBCol>
        )}
      </MDBRow>
    </div>
  );
}

export default PropertyNoticeDetails;
