export default {
  SAVE_OBJECTOR: "SAVE_OBJECTOR",
  UPDATE_OBJECTOR: "UPDATE_OBJECTOR",
  LOAD_OBJECTOR: "LOAD_OBJECTOR",
  SAVE_PROPERTY: "SAVE_PROPERTY",
  SAVE_PROPERTIES: "SAVE_PROPERTIES",
  SET_PROPERTY_LOGIN_ERROR: "SET_PROPERTY_LOGIN_ERROR",

  ADD_PROPERTY: "ADD_PROPERTY",
  DELETE_PROPERTY: "DELETE_PROPERTY",
  ADD_VERIFIED_PROPERTY: "ADD_VERIFIED_PROPERTY",
  UPDATE_PROPERTY: "UPDATE_PROPERTY",
  UPDATE_SALE_EVIDENCE: "UPDATE_SALE_EVIDENCE",
  UPDATE_ALL_SALE_EVIDENCE: "UPDATE_ALL_SALE_EVIDENCE",

  DELETE_SALE_EVIDENCE_BY_PROPERTY_ID: "DELETE_SALE_EVIDENCE_BY_PROPERTY_ID",
  SUBMIT_PROPERTY: "SUBMIT_PROPERTY",
  SET_PROPERTY_ID: "SET_PROPERTY_ID",

  ADD_SALES_EVIDENCE: "ADD_SALES_EVIDENCE",
  ADD_DEFAULT_SALES_EVIDENCE: "ADD_DEFAULT_SALES_EVIDENCE",

  // attachments
  SAVE_ATTACHMENTS: "SAVE_ATTACHMENTS",
  DELETE_ATTACHMENT: "DELETE_ATTACHMENT",
  INITIALISE_ATTACHMENT: "INITIALISE_ATTACHMENT",

  CURRENT_ROUTE: "CURRENT_ROUTE",
  UPDATE_ROUTE: "UPDATE_ROUTE",
  IS_ROUTING: "IS_ROUTING",

  //Actions at application level
  START_LOADER: "START_LOADER",
  STOP_LOADER: "STOP_LOADER",
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",

  // Fields helper actions
  UPDATE_OBJECTOR_HELPER: "UPDATE_OBJECTOR_HELPER",
  UPDATE_PROPERTY_IDENTIFICATION_HELPER:
    "UPDATE_PROPERTY_IDENTIFICATION_HELPER",
  UPDATE_OBJECTION_DETAILS_HELPER: "UPDATE_OBJECTION_DETAILS_HELPER",
  UPDATE_PROPERTY_DETAILS_HELPER: "UPDATE_PROPERTY_DETAILS_HELPER",
  UPDATE_SALES_EVIDENCE_HELPER: "UPDATE_SALES_EVIDENCE_HELPER",
  UPDATE_VALIDATE_OBJECTOR_HELPER: "UPDATE_VALIDATE_OBJECTOR_HELPER",

  SET_LANDING_PAGE_TEXT: "SET_LANDING_PAGE_TEXT",
  SET_PROPERTY_VERIFICATION_MODAL_TEXT: "SET_PROPERTY_VERIFICATION_MODAL_TEXT",
  SET_OBJECTING_TO_VALUES_TEXT: "SET_OBJECTING_TO_VALUES_TEXT",
  SET_OBJECTION_COMMENTS_TEXT: "SET_OBJECTION_COMMENTS_TEXT",
  SET_PROPERTY_DETAILS_TEXT: "SET_PROPERTY_DETAILS_TEXT",
  SET_SALES_EVIDENCE_TEXT: "SET_SALES_EVIDENCE_TEXT",
  SET_OBJECTING_TO_VALUES_WARNING: "SET_OBJECTING_TO_VALUES_WARNING",
  SET_COUNCIL_RATES_NOTICE: "SET_COUNCIL_RATES_NOTICE",
};
