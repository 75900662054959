import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import constants from "../components/Constants";
import {
  retrieveItem,
  removeItem,
  storeItem,
} from "../components/Session/Session";

const CHANGE_EVENT = "change";
let _attachments = []; //private

let _attachment = {
  propertyId: "",
  attachmentId: "",
  mimeType: "",
  fileName: "",
};

class AttachmentStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getAttachmentsByPropertyId(propertyId) {
    if (propertyId === null) {
      let newAttachment = {};
      newAttachment = Object.assign(newAttachment, _attachment);
      return [newAttachment];
    }

    if (!_attachments || _attachments.length === 0) {
      let value = retrieveItem(constants.ATTACHMENT);
      if (value && value.length > 0) {
        _attachments = value;
      }
    }
    let attachment = _attachments.filter(
      (x) => x.propertyId === propertyId.toString()
    );

    let newAttachment = {};
    newAttachment = Object.assign(newAttachment, _attachment);
    return attachment && attachment.length > 0 ? attachment : [newAttachment];
  }

  initialiseAttachment() {
    _attachments = [];

    _attachment = {
      propertyId: "",
      attachmentId: "",
      mimeType: "",
      fileName: "",
    };
    removeItem(constants.ATTACHMENT);
  }
}

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.INITIALISE_ATTACHMENT:
      _attachments = [];

      _attachment = {
        propertyId: "",
        attachmentId: "",
        mimeType: "",
        fileName: "",
      };
      removeItem(constants.ATTACHMENT);
      break;

    case actionTypes.SAVE_ATTACHMENTS:
      // removing attachments which are already present
      if (action.attachments) {
        action.attachments.forEach((attachment) => {
          _attachments = _attachments.filter(
            (x) => x.attachmentId !== attachment.attachmentId
          );
        });
      }
      // now merging the old and new attachments
      _attachments = [..._attachments, ...action.attachments];
      removeItem(constants.ATTACHMENT);
      storeItem(_attachments, constants.ATTACHMENT);
      store.emitChange();
      break;

    case actionTypes.DELETE_ATTACHMENT:
      _attachments = _attachments.filter(
        (x) => x.attachmentId !== action.attachmentId
      );
      removeItem(constants.ATTACHMENT);
      storeItem(_attachments, constants.ATTACHMENT);
      store.emitChange();
      break;

    default:
      //do nothing
      break;
  }
});

// we need to instantiate the store
const store = new AttachmentStore();

export default store;
