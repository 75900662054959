//#region imports
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import PropertyStore from "../../stores/propertyStore";
import CommonStore from "../../stores/commonStore";
import eraBuilt from "../../Lists/EraBuilt";
import propertyTypes from "../../Lists/PropertyTypes";
import constructionMaterials from "../../Lists/ConstructionMaterials";
import dwellingTypes from "../../Lists/DwellingTypes";
import MDBTextArea from "../../controls/MDBReact-InputTextArea";
import * as PropertyActions from "../../actions/propertyActions";
import PropertyDetailsHelper from "../../FieldHelper/propertyDetailsHelper";
import MaterialInputText from "../../controls/MaterialUI-InputText";
import { getNamefromList } from "../../utilities/getNameFromList";
import Councils from "../../Lists/Councils";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import ObjectorStore from "../../stores/objectorStore";
import constants from "../Constants";
import FormatCurrency from "../../utilities/currencyformatter";
import {
  fieldValidator,
  fieldLevelValidation,
} from "../../utilities/fieldValidator";
import MaterialSelect from "../../controls/MaterialUI-Select";
import PropertyConditions from "../../Lists/PropertyConditions";
import Steppers from "../../controls/Steppers/Steppers";
import MobileLinearProgress from "../../controls/MobileLinearProgress/MobileLinearProgress";
import { isEmailLink } from "../../utilities/isEmailLink";
import "./PropertyDetails.scss";
import MaterialAutoComplete from "../../controls/MaterialUI-AutoComplete";
import propertyCategories from "../../Lists/PropertyCategories";
import PropertyDetailsAttachments from "./PropertyDetailsAttachments/PropertyDetailsAttachments";
import PropertyLeaseDetails from "./PropertyLeaseDetails/PropertyLeaseDetails";
import { dateFormat } from "../../utilities/dateFormat";
import Alert from  "@material-ui/lab/Alert";
import { ScrollTo } from "../../utilities/focusErrorControl";
//#endregion imports

function PropertyDetails(props) {
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }

  const PropertyType_Residential = "4";
  const PropertyType_Commercial = "2";
  const PropertyType_Industrial = "3";
  const PropertyType_Rural = "1";
  const Objection_Reason_AVPCC = "7";

  //#region Component variables
  //#region verifying if the loading of this component was using
  // routing or Did user entered a URL and opened this page
  var objector = ObjectorStore.getObjector();
  var propertyId = null;
  if (!objector || !objector.objectorId) {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ObjectorDetails",
      });
  } else {
    // we came here means, we do have an Objector in flux / session
    // now check if we have a propertyId
    if (props && props.location && props.location.propertyId && props.location.propertyId !== '0') {
      propertyId = props.location.propertyId;
    } else {
      props &&
        props.history &&
        props.history.push({
          pathname: "/PropertyIdentification",
          objectorId: objector.objectorId,
        });
    }
  }
  //#endregion verifying the loading of this component
  const [errorMessage, setErrorMessage] = useState(null);
  const [propertyDetailsText] = useState(CommonStore.getPropertyDetailsText());
  const [property, setProperty] = useState(
    PropertyStore.getPropertyById(propertyId)
  );

  const [propertyDetailsHelper, setPropertyDetailsHelper] = useState(
    PropertyStore.getPropertyDetailsHelper()
  );
  //#endregion Component variables
  const LandAreaUnitMeterSquareId = "1";
  const LandAreaUnitMeterSquare = "m²";
  //#region UseEffect Hook
  useEffect(() => {
    PropertyStore.addChangeListener(onchange);

    document.title = "Object to rating valuation - Property details";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // setting the default value of land Area to meter square
    if (!property.landAreaUnitID) {
      let updateProperty = {};
      updateProperty = Object.assign(updateProperty, property);
      updateProperty.landAreaUnitID = LandAreaUnitMeterSquareId;
      updateProperty.landAreaUnit = LandAreaUnitMeterSquare;
      setProperty(updateProperty);
    }

    function onchange() {
      setProperty(PropertyStore.getPropertyById(propertyId));
      setPropertyDetailsHelper(PropertyStore.getPropertyDetailsHelper());
    }

    return () => PropertyStore.removeChangeListener(onchange); //cleans up on unmount of component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion UseEffect Hook

  //#region Change event handlers
  function handleChange({ target }) {
    setProperty({ ...property, [target.name]: target.value });
  }

  function onAutoCompleteChange(event, value) {
    if (value) {
      let updateProperty = {};
      updateProperty = Object.assign(updateProperty, property);
      updateProperty.contended_property_code = value.id;
      updateProperty.contended_property_desc = value.name;
      setProperty(updateProperty);
    }
  }

  function handlePropertyTypeChange({ target }) {
    let updateProperty = {};
    if (target.value === PropertyType_Residential) {
      // clear all controls which are not required
      updateProperty = Object.assign(updateProperty, property);
      updateProperty.propertyTypeId = target.value;
      updateProperty.netLettableArea = "";
      updateProperty.carParks = "";
      updateProperty.leaseTerm = "";
      updateProperty.leaseAmountPA = "";
      updateProperty.leaseCommenced = null;
      updateProperty.reviewFrequency = "";
      updateProperty.reviewBasis = "";
      updateProperty.options = "";
      updateProperty.reviewDetail = "";
      updateProperty.tenantOutgoings = "";
      updateProperty.ownerOutgoings = "";
      setProperty(updateProperty);
    } else if (
      target.value === PropertyType_Commercial ||
      target.value === PropertyType_Industrial
    ) {
      updateProperty = Object.assign(updateProperty, property);
      updateProperty.numberBathrooms = "";
      updateProperty.numberBedrooms = "";
      updateProperty.numberRooms = "";
      updateProperty.propertyTypeId = target.value;
      setProperty(updateProperty);
    } else {
      setProperty({ ...property, [target.name]: target.value });
    }
  }

  function onBlur(event) {
    // field level required validation
    let fieldDetails = PropertyDetailsHelper.getFieldDetail(event.target.name);
    let finalValue = event.target.value;
    if (finalValue && finalValue !== "") {
      if (fieldDetails && fieldDetails.type === "currency") {
        finalValue = FormatCurrency.removeCurrencyFormat(event.target.value);
        if (fieldDetails.isPositiveOnly) {
          finalValue = finalValue < 0 ? finalValue * -1 : finalValue;
        }
        finalValue = FormatCurrency.formatCurrency(
          FormatCurrency.removeCurrencyFormat(finalValue)
        );
      }
    }
    fieldLevelValidation(propertyDetailsHelper[event.target.name], finalValue);
    PropertyActions.updatePropertyDetailsHelper(propertyDetailsHelper);
    setProperty({ ...property, [event.target.name]: finalValue });
  }
  //#endregion Change event handler

  function savePropertyDetails() {
    startLoader();

    let isFieldValidationSuccess = fieldValidator(
      constants.PROJECT_DETAILS,
      property,
      propertyDetailsHelper
    );

    if (!isFieldValidationSuccess) {
      setTimeout(() => {
        stopLoader();
        PropertyActions.dispatchUpdateProperty(property);
        PropertyActions.updatePropertyDetailsHelper(propertyDetailsHelper);
      }, 100);
      return;
    }

    // we want the local date fields to stay in DATE() type
    // but passing it to the api, we are changing them to  yyyy-mm-dd format string
    let updatedProperty = {};
    updatedProperty = Object.assign(updatedProperty, property);
    updatedProperty.leaseCommenced = dateFormat(
      updatedProperty.leaseCommenced,
      "yyyy-mm-dd"
    );

    let errorMessage = 'Property details failed to save. Click Back then Next to reload the step and try again.	If the problem persists, contact the council on your notice and lodge your objection directly with the rates team.';

    PropertyActions.updatePropertyDetails(
      updatedProperty,
      objector.objectorId
    ).then((response) => {
      // if api returns an error thats handled by the server, it would be 
      // wrapped in the response
      if (response && response.errors && response.errors.length > 0) {
        showError(errorMessage);
        stopLoader();
        return;
      } else {
        PropertyActions.dispatchUpdateProperty(property);
        setTimeout(() => {
          stopLoader();
            props &&
            props.history &&
            props.history.push({
              pathname: "/SaleEvidence",
              propertyId: property.propertyId,
              objectorId: objector.objectorId,
            });
          PropertyActions.updatePropertyDetailsHelper(propertyDetailsHelper);
        }, 2000);
      }
    },
    (error) => {
      showError(errorMessage);
      stopLoader();
    });
  }

  function showError(message) {
    setErrorMessage(message);
    let element = document.getElementById("propertyDetailsError");
    if (element) {
      ScrollTo(element);
    }
  }

  function radioChanged({ target }) {
    setProperty({
      ...property,
      [target.name]: target.value,
    });
  }

  function goToObjectionDetails() {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ObjectionDetails",
        propertyId: property.propertyId,
        objectorId: objector.objectorId,
      });
  }

  function onDateChange(date, name) {
    // if notice date is not valid, make the Date helper = Required
    let updateHelper = {};
    updateHelper = Object.assign(updateHelper, propertyDetailsHelper);

    if (isNaN(date) || date === null || date > new Date()) {
      updateHelper[name].hasError = true;
      setProperty({ ...property, [name]: null });
    } else {
      setProperty({ ...property, [name]: date });
      updateHelper[name].hasError = false;
    }

    setTimeout(() => {
      setPropertyDetailsHelper(updateHelper);
    }, 100);
  }

  return (
    <>
      <MDBContainer>
        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <h4>{property.address}</h4>
            <h5>{getNamefromList(Councils, property.councilId)}</h5>
            <h5>Assessment number: {property.assessmentNumber}</h5>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol className="d-none d-md-block stepper-aligned">
            <Steppers location={props.location} />
          </MDBCol>
          <MDBCol className="d-block d-md-none stepper-aligned">
            <MobileLinearProgress
              location={props.location}
            ></MobileLinearProgress>
          </MDBCol>
        </MDBRow>

        {propertyDetailsText && (
          <>
            <MDBRow className="helper-text-background mb-3">
              <MDBCol>
                <div
                  className="cms-content-property-details"
                  dangerouslySetInnerHTML={{ __html: propertyDetailsText }}
                ></div>
              </MDBCol>
            </MDBRow>
          </>
        )}

        <MDBRow>
          <MDBCol xl="12" lg="12" md="12" sm="12" className="mb-3">
            <div id="propertyDetailsError"> 
            { errorMessage && 
              <Alert severity="error">
                { errorMessage }
              </Alert>
            }
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialSelect
              inputLabel="Property type"
              name="propertyTypeId"
              value={property.propertyTypeId}
              onSelectChange={handlePropertyTypeChange}
              list={propertyTypes}
              formHelperText={propertyDetailsHelper.propertyTypeId.errorText}
              disabled={false}
              required={propertyDetailsHelper.propertyTypeId.required}
              hasError={propertyDetailsHelper.propertyTypeId.hasError}
            />
          </MDBCol>
        </MDBRow>

        {property.objectionReasonId === Objection_Reason_AVPCC && (
          <div>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialSelect
                  inputLabel="Current property code"
                  name="property_code"
                  value={property.property_code}
                  list={propertyCategories}
                  disabled={true}
                  required={false}
                  hasError={false}
                />
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <div className="contended-property-layout">
                  <MaterialAutoComplete
                    name="contended_property_desc"
                    defaultValue={propertyCategories.findIndex(
                      (x) => x.id === property.contended_property_code
                    )}
                    id={property.contended_property_desc}
                    label="Contended property code"
                    onChange={onAutoCompleteChange}
                    list={propertyCategories}
                    disabled={false}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        )}

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialInputText
              label="Land area"
              name="landArea"
              value={property.landArea}
              onChange={handleChange}
              disabled={false}
              helperText={propertyDetailsHelper.landArea.errorText}
              required={propertyDetailsHelper.landArea.required}
              hasError={propertyDetailsHelper.landArea.hasError}
              onBlur={onBlur}
            ></MaterialInputText>
          </MDBCol>
          <MDBCol
            xl="6"
            lg="6"
            md="6"
            sm="6"
            xs="6"
            className="unit-land-radio mb-3"
          >
            <div className="radio-list">
              <div className="radio-option">
                <input
                  aria-labelledby={"landAreaUnitID1"}
                  type="radio"
                  id="1"
                  name="landAreaUnitID"
                  value="1"
                  checked={property.landAreaUnitID === "1" ? true : false}
                  onChange={radioChanged}
                />
                <label className="radio-option-label" id="landAreaUnitID1">
                  m²
                </label>
              </div>
              <div className="radio-option">
                <input
                  aria-labelledby={"landAreaUnitID2"}
                  type="radio"
                  id="2"
                  name="landAreaUnitID"
                  value="2"
                  checked={property.landAreaUnitID === "2" ? true : false}
                  onChange={radioChanged}
                />
                <label className="radio-option-label" id="landAreaUnitID2">
                  ha
                </label>
              </div>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialInputText
              label="Gross floor area (m²)"
              name="buildAreaM2"
              value={property.buildAreaM2}
              onChange={handleChange}
              disabled={false}
              required={propertyDetailsHelper.buildAreaM2.required}
              hasError={propertyDetailsHelper.buildAreaM2.hasError}
              helperText={propertyDetailsHelper.buildAreaM2.errorText}
              onBlur={onBlur}
            ></MaterialInputText>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6"></MDBCol>
        </MDBRow>

        {(property.propertyTypeId === PropertyType_Commercial ||
          property.propertyTypeId === PropertyType_Industrial ||
          property.propertyTypeId === PropertyType_Rural) && (
          <>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputText
                  label="Net lettable area (m²)"
                  name="netLettableArea"
                  value={property.netLettableArea}
                  onChange={handleChange}
                  disabled={false}
                  helperText={propertyDetailsHelper.netLettableArea.errorText}
                  required={propertyDetailsHelper.netLettableArea.required}
                  hasError={propertyDetailsHelper.netLettableArea.hasError}
                  onBlur={onBlur}
                ></MaterialInputText>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputText
                  label="Car parks"
                  name="carParks"
                  value={property.carParks}
                  onChange={handleChange}
                  disabled={false}
                  helperText={propertyDetailsHelper.carParks.errorText}
                  required={propertyDetailsHelper.carParks.required}
                  hasError={propertyDetailsHelper.carParks.hasError}
                  onBlur={onBlur}
                ></MaterialInputText>
              </MDBCol>
            </MDBRow>
          </>
        )}

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialSelect
              inputLabel="Improvement type"
              name="dwellingTypeId"
              value={property.dwellingTypeId}
              onSelectChange={handleChange}
              list={dwellingTypes}
              formHelperText={propertyDetailsHelper.dwellingTypeId.errorText}
              disabled={false}
              required={propertyDetailsHelper.dwellingTypeId.required}
              hasError={propertyDetailsHelper.dwellingTypeId.hasError}
            ></MaterialSelect>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialSelect
              inputLabel="Construction material"
              name="constructionMaterialId"
              value={property.constructionMaterialId}
              onSelectChange={handleChange}
              list={constructionMaterials}
              formHelperText={
                propertyDetailsHelper.constructionMaterialId.errorText
              }
              disabled={false}
              required={propertyDetailsHelper.constructionMaterialId.required}
              hasError={propertyDetailsHelper.constructionMaterialId.hasError}
            ></MaterialSelect>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialSelect
              inputLabel="Era built"
              name="yearBuilt"
              value={property.yearBuilt}
              onSelectChange={handleChange}
              list={eraBuilt}
              formHelperText={propertyDetailsHelper.yearBuilt.errorText}
              disabled={false}
              required={propertyDetailsHelper.yearBuilt.required}
              hasError={propertyDetailsHelper.yearBuilt.hasError}
              onBlur={onBlur}
            ></MaterialSelect>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <MaterialSelect
              inputLabel="Building condition"
              name="buildingConditionId"
              value={property.buildingConditionId}
              onSelectChange={handleChange}
              list={PropertyConditions}
              formHelperText={
                propertyDetailsHelper.constructionMaterialId.helperText
              }
              disabled={false}
              required={propertyDetailsHelper.constructionMaterialId.required}
              hasError={propertyDetailsHelper.constructionMaterialId.hasError}
            ></MaterialSelect>
          </MDBCol>
        </MDBRow>

        {(property.propertyTypeId === PropertyType_Commercial ||
          property.propertyTypeId === PropertyType_Industrial) && (
          <>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputText
                  label="Renovation year"
                  name="yearRenovation"
                  value={property.yearRenovation}
                  onChange={handleChange}
                  disabled={false}
                  helperText={propertyDetailsHelper.yearRenovation.errorText}
                  required={propertyDetailsHelper.yearRenovation.required}
                  hasError={propertyDetailsHelper.yearRenovation.hasError}
                  onBlur={onBlur}
                ></MaterialInputText>
              </MDBCol>
            </MDBRow>
          </>
        )}

        {(!property.propertyTypeId ||
          property.propertyTypeId === "-1" ||
          property.propertyTypeId === PropertyType_Residential ||
          property.propertyTypeId === PropertyType_Rural) && (
          <>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputText
                  label="Renovation year"
                  name="yearRenovation"
                  value={property.yearRenovation}
                  onChange={handleChange}
                  disabled={false}
                  helperText={propertyDetailsHelper.yearRenovation.errorText}
                  required={propertyDetailsHelper.yearRenovation.required}
                  hasError={propertyDetailsHelper.yearRenovation.hasError}
                  onBlur={onBlur}
                ></MaterialInputText>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputText
                  label="Number of main rooms"
                  name="numberRooms"
                  value={property.numberRooms}
                  onChange={handleChange}
                  disabled={false}
                  helperText={propertyDetailsHelper.numberRooms.errorText}
                  required={propertyDetailsHelper.numberRooms.required}
                  hasError={propertyDetailsHelper.numberRooms.hasError}
                  onBlur={onBlur}
                ></MaterialInputText>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputText
                  label="Bedrooms"
                  name="numberBedrooms"
                  value={property.numberBedrooms}
                  onChange={handleChange}
                  disabled={false}
                  helperText={propertyDetailsHelper.numberBedrooms.errorText}
                  required={propertyDetailsHelper.numberBedrooms.required}
                  hasError={propertyDetailsHelper.numberBedrooms.hasError}
                  onBlur={onBlur}
                ></MaterialInputText>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputText
                  label="Bathrooms"
                  name="numberBathrooms"
                  value={property.numberBathrooms}
                  onChange={handleChange}
                  disabled={false}
                  helperText={propertyDetailsHelper.numberBathrooms.errorText}
                  required={propertyDetailsHelper.numberBathrooms.required}
                  hasError={propertyDetailsHelper.numberBathrooms.hasError}
                  onBlur={onBlur}
                ></MaterialInputText>
              </MDBCol>
            </MDBRow>
          </>
        )}
        {property.propertyTypeId === PropertyType_Rural && (
          <div className="mb-3">
            Please detail any irrigation, bore licenses, soil types, fencing or
            crop information below:
          </div>
        )}

        {property.propertyTypeId !== PropertyType_Rural && (
          <>
            <MDBRow className="mb-3">
              <MDBCol>
                Provide any extra information to assist the valuer in their
                review.
              </MDBCol>
            </MDBRow>
          </>
        )}

        <MDBRow className="mb-3">
          <MDBCol>
            <MDBTextArea
              label="Property description"
              name="dwellingDescription"
              value={property.dwellingDescription}
              onChange={handleChange}
              disabled={false}
              hasError={propertyDetailsHelper.dwellingDescription.hasError}
              required={propertyDetailsHelper.dwellingDescription.required}
              helperText={propertyDetailsHelper.dwellingDescription.errorText}
            ></MDBTextArea>
          </MDBCol>
        </MDBRow>

        <PropertyLeaseDetails
          property={property}
          onBlur={onBlur}
          helper={propertyDetailsHelper}
          handleChange={handleChange}
          onDateChange={onDateChange}
        ></PropertyLeaseDetails>

        <PropertyDetailsAttachments
          propertyId={propertyId}
          objectorId={objector.objectorId}
        />

        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <div className="float-left" id="backButton">
              <MDBBtn
                aria-labelledby="backButton"
                className="button-style"
                onClick={goToObjectionDetails}
              >
                Back
              </MDBBtn>
            </div>

            <div className="float-right" id="nextButton">
              <MDBBtn
                aria-labelledby="nextButton"
                className="button-style"
                onClick={savePropertyDetails}
              >
                Next
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default PropertyDetails;
