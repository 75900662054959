export default {
  OBJECTOR: "OBJECTOR",
  PROPERTIES: "PROPERTIES",
  PROPERTY_ID: "PROPERTY_ID",
  OBJECTION: "OBJECTION",
  SALE_EVIDENCE: "SALE_EVIDENCE",
  PROJECT_DETAILS: "PROJECT_DETAILS",
  ATTACHMENT: "ATTACHMENT",
  PROPERTY_IDENTIFICATION: "PROPERTY_IDENTIFICATION",
  VALIDATE_OBJECTOR: "VALIDATE_OBJECTOR",
  LANDING_PAGE_TEXT: "LANDING_PAGE_TEXT",
  PROPERTY_VERIFICATION_MODAL_TEXT: "PROPERTY_VERIFICATION_MODAL_TEXT",
  OBJECTING_TO_VALUES_TEXT: "OBJECTING_TO_VALUES_TEXT",
  OBJECTION_COMMENTS_TEXT: "OBJECTION_COMMENTS_TEXT",
  PROPERTY_DETAILS_TEXT: "PROPERTY_DETAILS_TEXT",
  SALES_EVIDENCE_TEXT: "SALES_EVIDENCE_TEXT",
  OBJECTING_TO_VALUES_WARNING: "OBJECTING_TO_VALUES_WARNING",
  COUNCIL_RATES_NOTICE: "COUNCIL_RATES_NOTICE",
  OBJECTON_GROUNDS_TEXT: "OBJECTON_GROUNDS_TEXT",
  REVIEW_AND_SUBMIT_TEXT: "REVIEW_AND_SUBMIT_TEXT",
  OBJECTOR_DETAILS_TEXT: "OBJECTOR_DETAILS_TEXT",
  PROPERTY_VERIFY_MAIN_TEXT: "PROPERTY_VERIFY_MAIN_TEXT",
  PROPERTY_VERIFY_FOOTER_TEXT: "PROPERTY_VERIFY_FOOTER_TEXT",
};