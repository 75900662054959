const objectionReasons = [
  { id: "1", name: "The value assigned is too high or too low" },
  {
    id: "2",
    name:
      "The interests held by various persons in the land have not been correctly apportioned",
  },
  { id: "3", name: "The apportionment of the valuation is not correct" },
  {
    id: "4",
    name:
      "Lands that should have been included in one valuation have been valued separately",
  },
  {
    id: "5",
    name:
      "Lands that should have been valued separately have been included in one valuation",
  },
  {
    id: "6",
    name:
      "The person named in the notice of valuation, assessment notice or other document is not liable to be so named",
  },
  {
    id: "7",
    name:
      "The area, dimensions or description of the land including the AVPCC allocated to the land are not correctly stated in the notice of valuation, assessment notice or other document",
  },
];

export default objectionReasons;
