const leaseTerms = [
  { id: "-1", name: "Please select lease term" },
  { id: "1", name: "< 6 months" },
  { id: "2", name: "12 months" },
  { id: "3", name: "24 months" },
  { id: "4", name: "36 months" },
  { id: "5", name: "5 years" },
  { id: "6", name: "10 years" },
  { id: "7", name: "15 years" },
  { id: "8", name: "> 30 years" },
];

export default leaseTerms;
