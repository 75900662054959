let _propertyDetailsHelper = {
  landArea: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    isPositiveOnly: true,
    fieldName: "Land area",
    errorText: "",
    max: 1000000,
    min: 0,
  },
  propertyTypeId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Property type",
    errorText: "",
  },
  landAreaUnitID: {
    required: false,
    hasError: false,
    helperText: "",
    type: "radio",
    fieldName: "Land area unit",
    errorText: "",
  },
  dwellingTypeId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Improvement type",
    errorText: "",
  },
  constructionMaterialId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Construction material",
    errorText: "",
  },
  buildingConditionId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Building condition",
    errorText: "",
  },
  buildAreaM2: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    isPositiveOnly: true,
    fieldName: "Build area",
    errorText: "",
    max: 100000,
    min: 0,
  },
  numberRooms: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    fieldName: "Number of rooms",
    errorText: "",
    min: 0,
    max: 100,
  },
  numberBedrooms: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    fieldName: "Number of bedrooms",
    errorText: "",
    min: 0,
    max: 100,
  },
  numberBathrooms: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    fieldName: "Number of bathrooms",
    errorText: "",
    min: 0,
    max: 100,
  },
  yearBuilt: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Year built",
    errorText: "",
  },
  yearRenovation: {
    required: false,
    hasError: false,
    helperText: "",
    type: "integer",
    fieldName: "Renovation year",
    errorText: "",
    min: 1770, // needs to be an enum
    max: new Date().getFullYear(),
  },
  dwellingDescription: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Dwelling description",
    errorText: "",
  },
  netLettableArea: {
    required: false,
    hasError: false,
    helperText: "",
    type: "integer",
    fieldName: "Net lettable area (m²)",
    errorText: "",
    min: 0,
    max: 100000,
  },
  carParks: {
    required: false,
    hasError: false,
    helperText: "",
    type: "integer",
    fieldName: "Car parks",
    errorText: "",
    min: 0,
    max: 10000,
  },
  leaseAmountPA: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Gross rent per year",
    errorText: "",
    max: 50000000000,
    min: 0,
  },
  leaseTerm: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Lease term",
    errorText: "",
  },
  options: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Options",
    errorText: "",
  },
  leaseCommenced: {
    required: false,
    hasError: false,
    helperText: "",
    type: "date",
    fieldName: "Lease commenced",
    errorText: "",
  },
  reviewFrequency: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Review frequency",
    errorText: "",
  },
  reviewBasis: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Review basis",
    errorText: "",
  },
  reviewDetail: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Review detail",
    errorText: "",
  },
  tenantOutgoings: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Tenant amount paid ",
    errorText: "",
    max: 50000000000,
    min: 0,
  },
  ownerOutgoings: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Owner amount paid ",
    errorText: "",
    max: 50000000000,
    min: 0,
  },
};

class PropertyDetailsHelper {
  getPropertyDetailsHelper() {
    return _propertyDetailsHelper;
  }

  getFieldDetail(field) {
    let value = _propertyDetailsHelper[field];
    return value;
  }
}

// we need to instantiate the store
const propertyDetailsHelper = new PropertyDetailsHelper();

export default propertyDetailsHelper;
