export function dateFormat(date, format) {
  try {
    if (!date) {
      return null;
    }

    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (format === "yyyy-mm-dd") {
      return [year, month, day].join("-");
    } else if (format === "dd/mm/yyyy") {
      return [day, month, year].join("/");
    } else if (format === "mm/dd/yyyy") {
      return [month, day, year].join("/");
    }
  } catch {
    return null;
  }
}
