const reviewBasis = [
  { id: "-1", name: "Please select review basis" },
  { id: "1", name: "Fixed %" },
  { id: "2", name: "CPI (Consumer Price Index)" },
  { id: "3", name: "Agreed value" },
  { id: "4", name: "Market" },
  { id: "5", name: "Other" },
];

export default reviewBasis;
