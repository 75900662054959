import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";

const CHANGE_EVENT = "change";
let _breadcrumbRoutes = [
  {
    id: 0,
    name: "Objection Portal",
    to: "/",
    desc: "Objection Portal",
    enabled: true,
    isSelected: true,
  },
  {
    id: 1,
    name: "Your details",
    to: "/ObjectorDetails",
    desc: "Your details",
    enabled: false,
    isSelected: false,
  },
  {
    id: 2,
    name: "Property identification",
    desc: "Property identification",
    to: "/PropertyIdentification",
    enabled: false,
    isSelected: false,
  },
  {
    id: 3,
    name: "Grounds for objection",
    to: "/ObjectionDetails",
    desc: "Grounds for objection",
    enabled: false,
    isSelected: false,
  },
  {
    id: 4,
    name: "Property details",
    to: "/PropertyDetails",
    desc: "Property details",
    enabled: false,
    isSelected: false,
  },
  {
    id: 5,
    name: "Sale evidence",
    to: "/SaleEvidence",
    desc: "Sale evidence",
    enabled: false,
    isSelected: false,
  },
  {
    id: 6,
    name: "Review and submit",
    to: "/ReviewSubmission",
    desc: "Review and submit",
    enabled: false,
    isSelected: false,
  },
];

class BreadcrumbStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getBreadcrumbRoutes() {
    return _breadcrumbRoutes;
  }
}

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.IS_ROUTING:
      // this is just to trigger onChange of Header.js
      store.emitChange();
      break;

    case actionTypes.CURRENT_ROUTE:
      let currentRouteId = 0;
      _breadcrumbRoutes.forEach((item) => {
        if (item.to === action.route) {
          item.isSelected = true;
          currentRouteId = item.id;
        } else {
          item.isSelected = false;
        }
      });

      _breadcrumbRoutes.forEach((item) => {
        if (item.id <= currentRouteId) {
          item.enabled = true;
        } else {
          item.enabled = false;
        }
      });

      store.emitChange();
      break;

    default:
      //do nothing
      break;
  }
});

// we need to instantiate the store
const store = new BreadcrumbStore();

export default store;
