const propertyCategories = [
  { id: "0", name: "0 - No Code Assigned" },
  { id: "100", name: "100 - Vacant Residential Dwelling Site/Surveyed Lot" },
  { id: "101", name: "101 - Residential Development Site" },
  { id: "102", name: "102 - Vacant In globo Residential Subdivisional Land" },
  { id: "102.1", name: "102.1 - Unspecified" },
  { id: "102.2", name: "102.2 - Subdivisional Land (Multi Lot)" },
  { id: "102.3", name: "102.3 - Subdivisional Land (In globo/Potential)" },
  { id: "103", name: "103 - Vacant Residential Rural/Rural Lifestyle" },
  {
    id: "104",
    name:
      "104 - Vacant Residential Rural/Rural Lifestyle (with permit refused)",
  },
  { id: "109", name: "109 - Residential Airspace" },
  { id: "110", name: "110 - Detached Dwelling" },
  { id: "110.1", name: "110.1 - Unspecified" },
  { id: "110.2", name: "110.2 - Detached Dwelling (new)" },
  { id: "110.3", name: "110.3 - Detached Dwelling (existing)" },
  {
    id: "110.4",
    name: "110.4 - Detached Dwelling Non-Conforming Use-Commercial",
  },
  {
    id: "110.5",
    name: "110.5 - Detached Dwelling Non-Conforming Use-Industrial",
  },
  { id: "111", name: "111 - Separate Dwelling and Curtilage" },
  { id: "112", name: "112 - Semi-Detached/Terrace/Row House" },
  { id: "112.1", name: "112.1 - Unspecified" },
  { id: "112.2", name: "112.2 - Semi-detached" },
  { id: "112.3", name: "112.3 - Terrace" },
  { id: "112.4", name: "112.4 - Row House" },
  { id: "112.5", name: "112.5 - Half Pair or Duplex" },
  { id: "113", name: "113 - Granny Flat/Studio" },
  { id: "114", name: "114 - Dwelling and Dependant Unit" },
  { id: "115", name: "115 - Shack/Hut/Donga" },
  {
    id: "116",
    name:
      "116 - Cabin/Accommodation (rental/leased individual residential site)",
  },
  { id: "117", name: "117 - Residential Rural/Rural Lifestyle" },
  {
    id: "118",
    name: "118 - Residential Land (with buildings that add no value)",
  },
  { id: "120", name: "120 - Single Strata Unit/Villa Unit/Townhouse" },
  { id: "120.1", name: "120.1 - Unspecified" },
  { id: "120.2", name: "120.2 - Single Strata Unit" },
  { id: "120.3", name: "120.3 - Villa Unit" },
  { id: "120.4", name: "120.4 - Townhouse" },
  { id: "120.5", name: "120.5 - OYO Subdivided Dwelling" },
  { id: "120.6", name: "120.6 - OYO Subdivided Unit" },
  { id: "120.7", name: "120.7 - OYO Unit" },
  { id: "121", name: "121 - Conjoined Strata Unit/Townhouse" },
  { id: "121.1", name: "121.1 - Unspecified" },
  { id: "121.2", name: "121.2 - Half Pair or Duplex" },
  { id: "121.3", name: "121.3 - Conjoined Strata Unit" },
  { id: "121.4", name: "121.4 - Townhouse" },
  { id: "123", name: "123 - Residential Company Share Unit (ground level)" },
  { id: "123.1", name: "123.1 - Unspecified" },
  { id: "123.2", name: "123.2 - OYO Company Share Unit" },
  { id: "123.3", name: "123.3 - OYO Stratum Flat" },
  { id: "123.4", name: "123.4 - OYO Stratum Unit" },
  { id: "123.5", name: "123.5 - OYO Company Share Flat" },
  { id: "123.6", name: "123.6 - OYO Cluster Unit" },
  {
    id: "124",
    name:
      "124 - Residential Company Share Unit (within multi-storey development)",
  },
  { id: "125", name: "125 - Strata unit or flat" },
  { id: "125.1", name: "125.1 - Unspecified" },
  { id: "125.2", name: "125.2 - Strata Dwelling" },
  { id: "125.3", name: "125.3 - OYO Subdivided Flat" },
  { id: "125.4", name: "125.4 - OYO Strata Flat" },
  { id: "126", name: "126 - Individual Car Park" },
  { id: "127", name: "127 - Individual Berth" },
  { id: "128", name: "128 - Individual Flat" },
  {
    id: "129",
    name: "129 - Common Land associated with a residential development",
  },
  { id: "130", name: "130 - Boarding House" },
  { id: "131", name: "131 - Residential Investment Flats" },
  { id: "133", name: "133 - Short Term Holiday Accommodation" },
  {
    id: "135",
    name: "135 - Dormitory Accommodation/University Residential College",
  },
  { id: "135.1", name: "135.1 - Unspecified" },
  { id: "135.2", name: "135.2 - Dormitory Accommodation" },
  { id: "135.3", name: "135.3 - University Residential College" },
  { id: "140", name: "140 - Retirement Village Unit" },
  { id: "141", name: "141 - Retirement Village Complex" },
  { id: "142", name: "142 - Aged Care Complex/Nursing Home" },
  { id: "142.1", name: "142.1 - Unspecified" },
  { id: "142.2", name: "142.2 - Aged Care Complex" },
  { id: "142.3", name: "142.3 - Nursing Home" },
  { id: "143", name: "143 - Special Accommodation" },
  { id: "144", name: "144 - Disability Housing" },
  { id: "150", name: "150 - Miscellaneous Improvements on Residential Land" },
  { id: "150.1", name: "150.1 - Unspecified" },
  { id: "150.2", name: "150.2 - Storage Area" },
  { id: "150.3", name: "150.3 - Garage/Outbuilding" },
  {
    id: "151",
    name: "151 - Miscellaneous Improvements on Residential Rural Land",
  },
  { id: "151.1", name: "151.1 - Unspecified" },
  { id: "151.2", name: "151.2 - Storage Area" },
  { id: "151.3", name: "151.3 - Garage/Outbuilding" },
  { id: "151.4", name: "151.4 - Site Improvements" },
  { id: "200", name: "200 - Commercial Development Site" },
  { id: "201", name: "201 - Vacant In globo Commercial Land" },
  {
    id: "202",
    name: "202 - Commercial Land (with buildings that add no value)",
  },
  { id: "209", name: "209 - Commercial Airspace" },
  { id: "210", name: "210 - Retail Premises (single occupancy)" },
  { id: "210.1", name: "210.1 - Unspecified" },
  { id: "210.2", name: "210.2 - Bank" },
  { id: "210.3", name: "210.3 - Retail Store/Showroom" },
  { id: "210.4", name: "210.4 - Shop" },
  { id: "210.5", name: "210.5 - Café" },
  { id: "210.6", name: "210.6 - Timber Yard/Trade Supplies" },
  { id: "210.7", name: "210.7 - Display Yard" },
  { id: "210.8", name: "210.8 - Convenience Store/Fast Food" },
  { id: "210.9", name: "210.9 - Plant/Tree Nursery" },
  { id: "211", name: "211 - Retail Premises (multiple occupancies)" },
  { id: "211.1", name: "211.1 - Unspecified" },
  { id: "211.2", name: "211.2 - Shop and Dwelling (single occupancy)" },
  { id: "211.3", name: "211.3 - Office and Dwelling (single occupancy)" },
  { id: "212", name: "212 - Mixed Use Occupation" },
  { id: "212.1", name: "212.1 - Unspecified" },
  { id: "212.3", name: "212.3 - Office" },
  { id: "212.4", name: "212.4 - Shop" },
  { id: "212.5", name: "212.5 - Studio" },
  { id: "212.6", name: "212.6 - Workroom" },
  { id: "213", name: "213 - Shopping Centre" },
  { id: "213.1", name: "213.1 - Unspecified" },
  { id: "213.2", name: "213.2 - Super Regional " },
  { id: "213.3", name: "213.3 - Major Regional" },
  { id: "213.4", name: "213.4 - Regional" },
  { id: "213.5", name: "213.5 - Sub Regional" },
  { id: "213.6", name: "213.6 - Neighbourhood" },
  { id: "214", name: "214 - National Company Retail" },
  { id: "214.1", name: "214.1 - Unspecified" },
  { id: "214.2", name: "214.2 - Supermarket" },
  { id: "214.3", name: "214.3 - Department/Discount Department Store" },
  { id: "214.4", name: "214.4 - Bulky Goods" },
  { id: "215", name: "215 - Fuel Outlet/Garage/Service Station" },
  { id: "216", name: "216 - Multi-Purpose Fuel Outlet (fuel/food/groceries)" },
  { id: "217", name: "217 - Bottle Shop/Licensed Liquor Outlet" },
  { id: "218", name: "218 - Licensed Retail Premises" },
  { id: "219", name: "219 - Market Stall" },
  { id: "220", name: "220 - Office Premises" },
  { id: "220.1", name: "220.1 - Unspecified" },
  { id: "220.2", name: "220.2 - Office (Converted dwelling)" },
  { id: "220.3", name: "220.3 - Serviced Office" },
  { id: "220.4", name: "220.4 - Strata/Subdivided Office" },
  { id: "221", name: "221 - Low Rise Office Building" },
  { id: "222", name: "222 - Multi-Level Office Building" },
  { id: "222.1", name: "222.1 - Unspecified" },
  { id: "222.2", name: "222.2 - Medium rise (4 to 50 levels)" },
  { id: "222.3", name: "222.3 - High Rise (50+ levels)" },
  { id: "223", name: "223 - Special Purpose (built-in technology)" },
  { id: "230", name: "230 - Residential Hotel/Motel/Apartment Hotel Complex" },
  { id: "230.1", name: "230.1 - Unspecified" },
  { id: "230.2", name: "230.2 - Residential Hotel" },
  { id: "230.3", name: "230.3 - Motel" },
  { id: "230.4", name: "230.4 - Apartment Hotel Complex" },
  { id: "230.5", name: "230.5 - Tourist Resort Complex" },
  { id: "230.6", name: "230.6 - Hotel" },
  { id: "230.7", name: "230.7 - Private Hotel" },
  { id: "231", name: "231 - Residential Hotel/Motel/Apartment Hotel Units" },
  { id: "232", name: "232 - Serviced Apartments/Holiday Units" },
  { id: "232.1", name: "232.1 - Unspecified" },
  { id: "232.2", name: "232.2 - Holiday Units" },
  { id: "232.3", name: "232.3 - Serviced Apartments" },
  { id: "233", name: "233 - Bed and Breakfast" },
  { id: "234", name: "234 - Tourist Park/Caravan Park/Camping Ground" },
  { id: "235", name: "235 - Guest Lodge/Back Packers/Bunkhouse/Hostel" },
  { id: "235.1", name: "235.1 - Unspecified" },
  { id: "235.2", name: "235.2 - Guest Lodge" },
  { id: "235.3", name: "235.3 - Back Packers/ Hostel" },
  { id: "235.4", name: "235.4 - Bunkhouse" },
  { id: "236", name: "236 - Ski lodge/ Member facility" },
  { id: "237", name: "237 - Recreation Camp" },
  {
    id: "240",
    name:
      "240 - Pub/Tavern/Hotel/Licensed Club/Restaurant/Licensed Restaurant/Nightclub",
  },
  { id: "240.1", name: "240.1 - Unspecified" },
  { id: "240.2", name: "240.2 - Pub" },
  { id: "240.3", name: "240.3 - Tavern" },
  { id: "240.4", name: "240.4 - Hotel" },
  { id: "240.5", name: "240.5 - Licensed Club" },
  { id: "240.6", name: "240.6 - Restaurant" },
  { id: "240.7", name: "240.7 - Licensed Restaurant" },
  { id: "240.8", name: "240.8 - Nightclub/Cabaret" },
  { id: "240.9", name: "240.9 - Reception/Function Centre" },
  { id: "241", name: "241 - Hotel–Gaming" },
  { id: "242", name: "242 - Club–Gaming – stand alone" },
  { id: "243", name: "243 - Member Club Facility" },
  { id: "244", name: "244 - Casino" },
  { id: "245", name: "245 - National Company Restaurant" },
  { id: "246", name: "246 - Kiosk" },
  { id: "247", name: "247 - Conference/Convention centre" },
  { id: "250", name: "250 - Live Entertainment – Major Multi-Purpose Complex" },
  { id: "251", name: "251 - Cinema Complex" },
  { id: "252", name: "252 - Playhouse/Traditional Theatre" },
  { id: "253", name: "253 - Drive-In" },
  { id: "260", name: "260 - Large Theme Attraction/Park" },
  { id: "261", name: "261 - Amusement Park" },
  {
    id: "262",
    name:
      "262 - Major Infrastructure Attractions (often associated with a major historic or feature natural location).",
  },
  { id: "263", name: "263 - Tourism Infrastructure – Local Attractions" },
  { id: "270", name: "270 - Health Surgery" },
  { id: "271", name: "271 - Health Clinic" },
  { id: "271.1", name: "271.1 - Unspecified" },
  { id: "271.2", name: "271.2 - Diagnostic Centre/X-Ray" },
  { id: "271.3", name: "271.3 - Medical Centre/Surgery" },
  { id: "271.4", name: "271.4 - Dental Clinic" },
  { id: "271.5", name: "271.5 - Super Clinic" },
  { id: "272", name: "272 - Brothel" },
  { id: "273", name: "273 - Crematorium/Funeral Services" },
  { id: "274", name: "274 - Automatic Teller Machine" },
  { id: "275", name: "275 - Veterinary Clinic" },
  { id: "280", name: "280 - Ground Level Parking" },
  { id: "281", name: "281 - Multi-Storey Car Park" },
  { id: "282", name: "282 - Individual Car Park Site" },
  { id: "282.1", name: "282.1 - Unspecified" },
  { id: "282.2", name: "282.2 - Car park – Under Cover " },
  { id: "282.3", name: "282.3 - Car park – Open Air " },
  { id: "283", name: "283 - Car Wash" },
  { id: "284", name: "284 - Vehicle Sales Centre" },
  { id: "285", name: "285 - Vehicle Rental Centre" },
  { id: "290", name: "290 - Advertising Sign" },
  { id: "290.1", name: "290.1 - Unspecified " },
  { id: "290.2", name: "290.2 - Self-Standing Pole" },
  { id: "290.3", name: "290.3 - Bridge Fixed" },
  { id: "290.4", name: "290.4 - Roof Mounted " },
  { id: "290.5", name: "290.5 - Wall Fixed" },
  {
    id: "293",
    name: "293 - Electronic Stadium/Street TV Relay Screen/Scoreboard",
  },
  { id: "300", name: "300 - Industrial Development Site" },
  { id: "301", name: "301 - Vacant Industrial In globo Land" },
  { id: "302", name: "302 - Industrial Airspace" },
  {
    id: "303",
    name: "303 - Industrial Land (with buildings which add no value)",
  },
  { id: "310", name: "310 - General Purpose Factory" },
  { id: "310.1", name: "310.1 - Unspecified" },
  { id: "310.2", name: "310.2 - Factory Unit" },
  { id: "310.3", name: "310.3 - Factory" },
  { id: "310.4", name: "310.4 - Garage/Motor Vehicle Repairs" },
  { id: "310.5", name: "310.5 - Office/Factory" },
  { id: "310.6", name: "310.6 - Workshop" },
  { id: "311", name: "311 - Food Processing Factory" },
  { id: "311.1", name: "311.1 - Unspecified" },
  { id: "311.2", name: "311.2 - Processing Plant" },
  { id: "311.3", name: "311.3 - Dairy" },
  {
    id: "312",
    name: "312 - Major Industrial Complex – Special Purpose Improvements",
  },
  { id: "320", name: "320 - General Purpose Warehouse" },
  { id: "320.1", name: "320.1 - Unspecified" },
  { id: "320.2", name: "320.2 - Warehouse" },
  { id: "320.3", name: "320.3 - Warehouse/Office" },
  { id: "320.4", name: "320.4 - Warehouse/Factory" },
  { id: "320.5", name: "320.5 - Warehouse/Showroom" },
  { id: "320.6", name: "320.6 - Depot" },
  { id: "320.7", name: "320.7 - Store" },
  { id: "321", name: "321 - Open Area Storage" },
  { id: "321.1", name: "321.1 - Unspecified" },
  { id: "321.2", name: "321.2 - Hardstand/Storage Yard" },
  { id: "321.3", name: "321.3 - Wrecking Yard" },
  { id: "321.4", name: "321.4 - Concrete Batching Plant" },
  { id: "321.5", name: "321.5 - Container storage" },
  { id: "322", name: "322 - Bulk Grain Storage (structures) " },
  {
    id: "323",
    name: "323 - Bulk Grain Storage (earthen walls and flooring - pit bunker)",
  },
  { id: "324", name: "324 - Bulk Liquid Storage Fuel Depot/Tank Farm" },
  { id: "325", name: "325 - Coolstore/Coldstore" },
  { id: "326", name: "326 - Works Depot" },
  { id: "330", name: "330 - Tannery/Skins Depot and Drying" },
  { id: "331", name: "331 - Abattoirs" },
  { id: "332", name: "332 - Stock sales yards" },
  { id: "333", name: "333 - Rendering Plant" },
  { id: "334", name: "334 - Oil Refinery" },
  { id: "335", name: "335 - Petro-chemical Manufacturing" },
  { id: "336", name: "336 - Sawmill" },
  { id: "400", name: "400 - Sand" },
  { id: "401", name: "401 - Gravel/Stone" },
  { id: "402", name: "402 - Manufacturing Materials" },
  { id: "403", name: "403 - Soil" },
  { id: "404", name: "404 - Coal" },
  { id: "405", name: "405 - Minerals/Ores" },
  { id: "406", name: "406 - Precious Metals" },
  { id: "407", name: "407 - Uranium" },
  { id: "408", name: "408 - Quarry/Mine (open cut) – Exhausted (dry)" },
  { id: "409", name: "409 - Quarry/Mine (open cut) – Exhausted (wet)" },
  { id: "410", name: "410 - Sand" },
  { id: "411", name: "411 - Gravel/Stone" },
  { id: "412", name: "412 - Manufacturing Materials" },
  { id: "413", name: "413 - Soil" },
  { id: "420", name: "420 - Black or Brown Coal" },
  { id: "421", name: "421 - Iron Ore" },
  { id: "422", name: "422 - Bauxite" },
  { id: "423", name: "423 - Gold" },
  { id: "424", name: "424 - Metals (other than gold)" },
  { id: "425", name: "425 - Precious Stones" },
  { id: "426", name: "426 - Uranium" },
  { id: "427", name: "427 - Non Metals (other than Uranium)" },
  { id: "430", name: "430 - Non-metals" },
  { id: "431", name: "431 - Black Coal" },
  { id: "432", name: "432 - Precious Stones" },
  { id: "433", name: "433 - Gold" },
  { id: "434", name: "434 - Metals (other than gold)" },
  { id: "439", name: "439 - Closed Mine Shaft" },
  { id: "440", name: "440 - Tailings Dump (minerals)" },
  { id: "441", name: "441 - Tailings Dump (non-minerals)" },
  { id: "450", name: "450 - Oil" },
  { id: "451", name: "451 - Gas" },
  { id: "452", name: "452 - Water (mineral)" },
  { id: "453", name: "453 - Water (stock and domestic)" },
  { id: "454", name: "454 - Water (irrigation)" },
  { id: "459", name: "459 - Disused Bore/Well" },
  { id: "460", name: "460 - Lake – Salt Extraction" },
  { id: "461", name: "461 - Man-made Evaporative Basin" },
  { id: "470", name: "470 - Dredging (minerals)" },
  { id: "471", name: "471 - Dredging (non-minerals)" },
  { id: "480", name: "480 - Extractive less than 2 Metres" },
  { id: "481", name: "481 - Operating mine unspecified" },
  { id: "482", name: "482 - Vacant Land mining unspecified" },
  { id: "500", name: "500 - Vacant Land - Native Vegetation/Bushland" },
  {
    id: "501",
    name:
      "501 - Vacant Land - Native Vegetation/Bushland with Covenant/Agreement",
  },
  { id: "510", name: "510 - General Cropping" },
  { id: "510.1", name: "510.1 - Unspecified" },
  { id: "510.2", name: "510.2 - Crop Production – Mixed/Other" },
  { id: "510.3", name: "510.3 - Crop Production – Other Grains/Oil Seeds" },
  { id: "510.4", name: "510.4 - Crop Production – Wheat" },
  { id: "510.5", name: "510.5 - Crop Production – Fodder Crops" },
  { id: "511", name: "511 - Specialised Cropping" },
  { id: "520", name: "520 - Domestic Livestock Grazing" },
  { id: "521", name: "521 - Non-Native Animals" },
  { id: "522", name: "522 - Native Animals" },
  { id: "523", name: "523 - Livestock Production – Sheep" },
  { id: "524", name: "524 - Livestock Production – Beef Cattle" },
  { id: "525", name: "525 - Livestock Production – Dairy Cattle" },
  { id: "530", name: "530 - Mixed farming and grazing" },
  { id: "530.1", name: "530.1 - Unspecified" },
  {
    id: "530.2",
    name: "530.2 - Mixed farming and grazing with infrastructure",
  },
  {
    id: "530.3",
    name: "530.3 - Mixed farming and grazing without infrastructure",
  },
  { id: "540", name: "540 - Cattle Feed Lot" },
  { id: "541", name: "541 - Poultry – Open Range" },
  { id: "542", name: "542 - Poultry (egg production)" },
  { id: "543", name: "543 - Poultry (broiler production)" },
  { id: "544", name: "544 - Horse Stud/Training Facilities/Stables " },
  { id: "544.1", name: "544.1 - Unspecified" },
  { id: "544.2", name: "544.2 - Horse Stud" },
  { id: "544.3", name: "544.3 - Training Facilities" },
  { id: "544.4", name: "544.4 - Stables" },
  { id: "545", name: "545 - Piggery" },
  { id: "546", name: "546 - Kennel/Cattery" },
  { id: "550", name: "550 - Market Garden – Vegetables" },
  { id: "551", name: "551 - Orchards, Groves and Plantations" },
  { id: "561", name: "561 - Vineyard " },
  { id: "562", name: "562 - Plant/Tree Nursery" },
  { id: "563", name: "563 - Commercial Flower and Plant Growing – (outdoor)" },
  { id: "564", name: "564 - Glasshouse Plant/Vegetable Production" },
  { id: "570", name: "570 - Softwood Plantation" },
  { id: "571", name: "571 - Hardwood Plantation" },
  { id: "572", name: "572 - Native Hardwood (standing timber)" },
  { id: "580", name: "580 - Oyster Beds" },
  { id: "581", name: "581 - Fish Farming – Sea Water Based" },
  { id: "582", name: "582 - Yabby Farming" },
  {
    id: "583",
    name: "583 - Aquaculture Breeding/Research Facilities/ Fish Hatchery",
  },
  { id: "600", name: "600 - Vacant Land" },
  {
    id: "601",
    name: "601 - Unspecified – Transport, Storage, Utilities and Communication",
  },
  { id: "610", name: "610 -  Wells" },
  { id: "611", name: "611 - Production/Refinery" },
  { id: "612", name: "612 - Storage " },
  {
    id: "613",
    name:
      "613 - Transmission Pipeline (through easements, freehold and public land) ",
  },
  {
    id: "614",
    name:
      "614 - Distribution/Reticulation Pipelines (through easements, freehold and public land)",
  },
  {
    id: "620",
    name:
      "620 - Electricity Power Generators – Fuel Powered (includes brown coal, black coal, natural steam, gas, oil and nuclear)",
  },
  { id: "621", name: "621 - Hydroelectricity Generation" },
  { id: "622", name: "622 - Wind Farm Electricity Generation" },
  { id: "623", name: "623 - Electricity Substation/Terminal" },
  {
    id: "624",
    name:
      "624 - Electricity Transmission Lines (through easements, freehold and public land)",
  },
  {
    id: "625",
    name:
      "625 - Electricity Distribution/Reticulation Lines (through easements, freehold and public land)",
  },
  { id: "626", name: "626 - Solar Electricity Generation" },
  { id: "630", name: "630 - Refuse Incinerator" },
  { id: "631", name: "631 - Refuse Transfer Station" },
  { id: "632", name: "632 - Sanitary Land Fill" },
  { id: "633", name: "633 - Refuse Recycling" },
  { id: "634", name: "634 - Hazardous Materials/Toxic Storage Centre" },
  {
    id: "635",
    name: "635 - Toxic By-product Storage and Decontamination Site",
  },
  { id: "636", name: "636 - Sewerage/Stormwater Treatment Plant Site" },
  { id: "637", name: "637 - Sewerage/Stormwater Pump Stations" },
  {
    id: "638",
    name:
      "638 - Sewerage/Stormwater Pipelines (through easements, freehold and public land)",
  },
  { id: "638.1", name: "638.1 - Unspecified" },
  { id: "638.2", name: "638.2 - Public Utility – Drainage" },
  { id: "638.3", name: "638.3 - Public Utility – Sewerage" },
  { id: "638.4", name: "638.4 - Reserve for Drainage or Sewerage Purposes" },
  { id: "638.5", name: "638.5 - Retarding Basin" },
  { id: "640", name: "640 - Water Catchment Area" },
  { id: "641", name: "641 - Water Catchment Dam/Reservoir" },
  { id: "642", name: "642 - Water Storage Dam/Reservoir (Non-Catchment)" },
  { id: "643", name: "643 - Water Treatment Plant" },
  {
    id: "644",
    name:
      "644 - Water Storage Tanks, Pressure Control Towers and Pumping Stations.",
  },
  { id: "645", name: "645 - Major Water Conduits" },
  {
    id: "646",
    name:
      "646 - Water – Urban Distribution Network (through easements, freehold and public land)",
  },
  { id: "650", name: "650 - Freeways" },
  { id: "651", name: "651 - Main Highways (including national routes)" },
  { id: "652", name: "652 - Secondary Roads" },
  { id: "653", name: "653 - Suburban and Rural Roads" },
  { id: "654", name: "654 - Closed Roads" },
  { id: "655", name: "655 - Reserved Roads" },
  { id: "656", name: "656 - Bus Maintenance Depot" },
  { id: "657", name: "657 - Bus Interchange Centre/Bus Terminal" },
  { id: "658", name: "658 - Designated Bus/Taxi Stops/Stands/Shelters" },
  { id: "659", name: "659 - Weighbridge" },
  { id: "660", name: "660 - Railway Line in use" },
  { id: "661", name: "661 - Railway Switching and Marshalling Yards" },
  { id: "662", name: "662 - Railway Maintenance Facility" },
  {
    id: "663",
    name: "663 - Railway Passenger Terminal Facilities (including stations)",
  },
  { id: "664", name: "664 - Railway Freight Terminal Facilities" },
  {
    id: "665",
    name:
      "665 - Tramway/Light Rail Right of Way and Associated Track Infrastructure",
  },
  { id: "666", name: "666 - Tramway Maintenance /Terminal Storage" },
  { id: "667", name: "667 - Tram Stopping Facilities" },
  { id: "668", name: "668 - Railway/Tramway Line Closed/Unused" },
  { id: "670", name: "670 - Airfield (includes associated open space)" },
  { id: "671", name: "671 - Airstrip" },
  { id: "672", name: "672 - Airport Traffic Control Centre" },
  { id: "673", name: "673 - Airport Hangar Building" },
  { id: "674", name: "674 - Airport Terminal Building – Passengers" },
  { id: "675", name: "675 - Airport Terminal Building – Freight" },
  { id: "676", name: "676 - Heliport" },
  { id: "680", name: "680 - Port Channel" },
  { id: "681", name: "681 - Port Dock/Berth" },
  { id: "682", name: "682 - Port Wharf/Pier and Apron – Cargo" },
  { id: "683", name: "683 - Wharf – Storage Sheds" },
  {
    id: "684",
    name: "684 - Wharf – Passenger Terminal and Ferry Pier Facilities",
  },
  { id: "685", name: "685 - Piers, Storages and Slipways" },
  { id: "686", name: "686 -  Ramps and Jetties" },
  { id: "687", name: "687 - Marinas and Yacht Clubs" },
  { id: "688", name: "688 - Dockyard, Dry Dock and/or Ship Building Facility" },
  { id: "689", name: "689 - Lighthouse and Navigation Aids" },
  { id: "690", name: "690 - Post Offices" },
  { id: "691", name: "691 - Postal Exchange/Mail Sorting Centres" },
  { id: "692", name: "692 - Post Boxes" },
  { id: "693", name: "693 - Telecommunication Buildings/Maintenance Depots" },
  { id: "694", name: "694 - Telecommunication Towers and Aerials" },
  { id: "694.1", name: "694.1 - Unspecified" },
  { id: "694.2", name: "694.2 - Telecommunication Tower" },
  { id: "694.3", name: "694.3 - Telecommunication Aerial" },
  {
    id: "695",
    name:
      "695 - Cable Lines, Conduits and Special Purpose Below Street Level Communication Line Tunnels – not being sewers (through easements, freehold and public land)",
  },
  { id: "696", name: "696 - Television/Radio Station – Purpose Built" },
  { id: "697", name: "697 - Printing Works/Press " },
  { id: "698", name: "698 - Telephone Exchange – Purpose Built" },
  { id: "700", name: "700 - Vacant Health Services Development Site" },
  { id: "701", name: "701 - Vacant Education and Research Development Site" },
  {
    id: "702",
    name: "702 - Vacant Justice and Community Protection Development Site",
  },
  { id: "703", name: "703 - Vacant Religious Purposes Development Site" },
  { id: "704", name: "704 - Vacant Community Services Development Site" },
  {
    id: "705",
    name: "705 - Vacant Government Administration Development Site",
  },
  { id: "706", name: "706 - Vacant Defence Services Development Site" },
  { id: "707", name: "707 - Cemetery" },
  { id: "710", name: "710 - Public Hospital" },
  { id: "711", name: "711 - Private Hospital" },
  { id: "712", name: "712 - Welfare Centre" },
  { id: "713", name: "713 - Community Health Centre" },
  { id: "714", name: "714 - Centre for the Mentally ill" },
  { id: "715", name: "715 - Day Care Centre for Children" },
  {
    id: "720",
    name: "720 - Early Childhood Development Centre – Kindergarten",
  },
  { id: "720.1", name: "720.1 - Unspecified" },
  { id: "720.2", name: "720.2 - Early Childhood Development Centre" },
  { id: "720.3", name: "720.3 - Kindergarten" },
  { id: "720.4", name: "720.4 - Pre-School" },
  { id: "720.5", name: "720.5 - Child Welfare and Pre-School" },
  { id: "721", name: "721 - Government School" },
  { id: "721.1", name: "721.1 - Unspecified" },
  { id: "721.2", name: "721.2 - Primary School" },
  { id: "721.3", name: "721.3 - Secondary School/College" },
  { id: "721.4", name: "721.4 - Combined Primary/Secondary" },
  { id: "721.5", name: "721.5 - Technical School" },
  { id: "721.6", name: "721.6 - Playing Fields and Sporting Facilities" },
  { id: "722", name: "722 - School Camps" },
  { id: "723", name: "723 - Non-Government School" },
  { id: "723.1", name: "723.1 - Unspecified" },
  { id: "723.2", name: "723.2 - Primary School" },
  { id: "723.3", name: "723.3 - Secondary School/College" },
  { id: "723.4", name: "723.4 - Combined Primary/Secondary" },
  { id: "723.5", name: "723.5 - Technical School" },
  { id: "723.6", name: "723.6 - Playing Fields and Sporting Facilities" },
  { id: "724", name: "724 - Special Needs School" },
  { id: "725", name: "725 - University " },
  { id: "726", name: "726 - Technical and Further Education" },
  { id: "727", name: "727 - Research Institute – Public" },
  { id: "728", name: "728 - Observatory" },
  { id: "729", name: "729 - Residential College/Quarters - Defence forces" },
  { id: "730", name: "730 - Police Facility" },
  { id: "731", name: "731 - Court Facility" },
  {
    id: "732",
    name: "732 - Prison/Detention Centre/Gaol Complex/ Corrective Institution",
  },
  { id: "733", name: "733 - Fire Station Facility" },
  { id: "734", name: "734 - Ambulance Station Facility" },
  { id: "735", name: "735 - Emergency Services Complex" },
  {
    id: "736",
    name: "736 - Community Protection and Services Training Facility",
  },
  { id: "740", name: "740 - Place of Worship" },
  { id: "741", name: "741 - Religious Hall" },
  { id: "742", name: "742 - Religious Residence " },
  { id: "743", name: "743 - Religious Study Centre" },
  { id: "750", name: "750 - Halls and Service Clubrooms" },
  { id: "751", name: "751 - Rural and Community Camps" },
  { id: "752", name: "752 - Community Facility" },
  { id: "760", name: "760 - Parliament House" },
  { id: "761", name: "761 - Government House" },
  { id: "762", name: "762 - Local Government" },
  { id: "763", name: "763 - Civic Buildings" },
  { id: "770", name: "770 - Army Barracks/Administration Base" },
  { id: "771", name: "771 - Army Maintenance Depots" },
  { id: "772", name: "772 - Army Field Camps and Firing Ranges" },
  { id: "773", name: "773 - Naval Base/Administration Base" },
  { id: "774", name: "774 - Naval Specialised Facilities – Ground Based" },
  { id: "775", name: "775 - Naval Specialised Facilities – Water Based" },
  { id: "776", name: "776 - Air Force Base/Administration" },
  { id: "777", name: "777 - Airstrip and Specialised Facilities" },
  { id: "778", name: "778 - Munitions Storage Facility" },
  { id: "780", name: "780 - Public Conveniences" },
  {
    id: "781",
    name: "781 - Unspecified – Public, Education and Health Improved",
  },
  {
    id: "782",
    name: "782 - Unspecified – Public, Education and Health vacant",
  },
  { id: "783", name: "783 - Animal shelter" },
  { id: "800", name: "800 - Vacant Site – Sporting Use" },
  { id: "801", name: "801 - Vacant Site – Heritage Application" },
  { id: "802", name: "802 - Vacant Site – Cultural Use" },
  { id: "810", name: "810 - Major Sports Complex" },
  { id: "811", name: "811 - Major Indoor Sports Complex" },
  { id: "812", name: "812 - OutdoorIndoor Sports Complex - non major" },
  { id: "813", name: "813 - Outdoor Sports – Extended Areas/Cross Country" },
  { id: "814", name: "814 - Aquatic Complex" },
  { id: "815", name: "815 - Water Sports – Outdoor" },
  { id: "816", name: "816 - Motor Racing Tracks/Speedways" },
  { id: "817", name: "817 - Racecourse/Tracks" },
  { id: "818", name: "818 - Ski Fields" },
  { id: "820", name: "820 - Indoor Sports Centre" },
  { id: "820.1", name: "820.1 - Unspecified" },
  { id: "820.2", name: "820.2 - Squash Courts" },
  { id: "820.3", name: "820.3 - Gymnasium/Health Club" },
  { id: "820.4", name: "820.4 - Indoor Sports Complex" },
  { id: "820.5", name: "820.5 - Bowling Alley" },
  {
    id: "821",
    name: "821 - Outdoor Sports Grounds town or suburban facilities",
  },
  { id: "821.1", name: "821.1 - Unspecified" },
  { id: "821.2", name: "821.2 - Tennis Club" },
  { id: "821.3", name: "821.3 - Bowling Club" },
  { id: "821.4", name: "821.4 - Outdoor Park and Facilities" },
  { id: "822", name: "822 - Outdoor Sports – Extended Areas/Cross Country" },
  { id: "823", name: "823 - Swimming Pools/Aquatic Centres" },
  { id: "824", name: "824 - Water Sports – Outdoor" },
  { id: "825", name: "825 - Motor Race Tracks/Speedways" },
  { id: "826", name: "826 - Aero Club Facility" },
  { id: "827", name: "827 - Ski Fields " },
  { id: "828", name: "828 - Equestrian Centre" },
  { id: "829", name: "829 - Bike Track/Walking Trails" },
  { id: "830", name: "830 - Library/Archives" },
  { id: "831", name: "831 - Museum/Art Gallery" },
  { id: "832", name: "832 - Cultural Heritage Centre" },
  { id: "833", name: "833 - Wildlife Zoo" },
  { id: "834", name: "834 - Aquarium" },
  { id: "835", name: "835 - Botanical Gardens" },
  { id: "836", name: "836 - Monument/Memorial" },
  { id: "837", name: "837 - Culture, recreation and sport" },
  { id: "840", name: "840 - Library/Archives" },
  { id: "841", name: "841 - Museum/Art Gallery" },
  { id: "842", name: "842 - Cultural Heritage Centre" },
  { id: "843", name: "843 - Wildlife Zoo/Park/Aquarium" },
  { id: "844", name: "844 - Parks and Gardens" },
  { id: "845", name: "845 - Monument/Memorial" },
  { id: "850", name: "850 - Bathing Boxes" },
  { id: "851", name: "851 - Boat Sheds" },
  { id: "900", name: "900 - Vacant Land" },
  { id: "910", name: "910 - Nature Reserve" },
  { id: "920", name: "920 - World Heritage Area" },
  { id: "921", name: "921 - Local Wilderness Area" },
  { id: "930", name: "930 - National Park – Land" },
  { id: "931", name: "931 - National Park – Marine" },
  { id: "940", name: "940 - Natural Monument – Land" },
  { id: "941", name: "941 - Natural Monument – Marine" },
  { id: "950", name: "950 - Forest Reserves – Public" },
  { id: "951", name: "951 - Forest Reserves – Private" },
  { id: "960", name: "960 - Conservation Area – Public" },
  { id: "961", name: "961 - Conservation Area – Private" },
  { id: "970", name: "970 - Protected Landscape – Public" },
  { id: "971", name: "971 - Protected Landscape – Private" },
  { id: "972", name: "972 - Protected Seascape – Public" },
  { id: "973", name: "973 - Protected Seascape – Private" },
  { id: "980", name: "980 - River Reserve (fresh water)" },
  { id: "981", name: "981 - Creek Reserve (fresh water)" },
  { id: "982", name: "982 - River Reserve (salt water)" },
  { id: "983", name: "983 - Creek Reserve (salt water)" },
  { id: "984", name: "984 - Floodway Reserve" },
  { id: "985", name: "985 - Fresh Water Lake Reserve" },
  { id: "986", name: "986 - Salt Water Lake Reserve" },
  { id: "987", name: "987 - Inland Low Lying Tidal Estuary Wetlands Reserve" },
  { id: "988", name: "988 - Seabed – Open Sea/Ocean/Bays" },
  { id: "990", name: "990 - Game Reserve – Public" },
  { id: "991", name: "991 - Game Reserve – Private" },
];

export default propertyCategories;
