let _propertyIdentificationHelper = {
  councilId: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "select",
    fieldName: "",
    errorText: "",
  },
  assessmentNumber: {
    required: true,
    hasError: false,
    helperText: "Required",
    isPositiveOnly: true,
    type: "fixedLength",
    fieldName: "",
    errorText: "",
    length: 100,
  },
  siteValue: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "",
    errorText: "",
  },
  capitalImprovedValue: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "",
    errorText: "",
  },
  netAnnualValue: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "",
    errorText: "",
  },
  rateNoticeReceived: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "date",
    fieldName: "Rate notice received",
    errorText: "",
  },
};

class PropertyIdentificationHelper {
  getPropertyIdentificationHelper() {
    return _propertyIdentificationHelper;
  }

  getFieldDetail(field) {
    let value = _propertyIdentificationHelper[field];
    return value;
  }
}

// we need to instantiate the store
const propertyIdentificationHelper = new PropertyIdentificationHelper();

export default propertyIdentificationHelper;
