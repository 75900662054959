import React, { useState } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

function ModalPage(props) {
  const [showModal, setShowState] = useState(props.showModal);

  function toggle() {
    setShowState(!showModal);
  }

  const headerStyles = {
    backgroundColor: props.headerColor ? props.headerColor : "transparent",
  };

  const buttonStyles = {
    color: "white",
  };

  const textitalic = {
    "font-style": "italic",
  };

  return (
    <MDBContainer>
      <MDBModal isOpen={showModal} disableBackdrop={true} toggle={toggle}>
        <MDBModalHeader style={headerStyles} toggle={toggle}>
          {props.header}
        </MDBModalHeader>
        <MDBModalBody>
          {props.isPropertyIdentification ? (
            <>
              <p>
                {props.message}
                {props.landingPageText != null && (
                  <div
                    className="cms-content"
                    dangerouslySetInnerHTML={{ __html: props.landingPageText }}
                  ></div>
                )}
                {props.councilDetails != null && (
                  <div
                    className="cms-content"
                    dangerouslySetInnerHTML={{ __html: props.councilDetails }}
                  ></div>
                )}
                <span style={textitalic}>{props.messageItalic}</span>
                {props.messageEnd}
              </p>
              <p>{props.details}</p>
            </>
          ) : (
            <>
              <p>{props.message}</p>
              <p>{props.details}</p>
            </>
          )}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            style={buttonStyles}
            onClick={() => {
              toggle();
              props.modalToggled();
            }}
          >
            {props.buttonText ? props.buttonText : "OK"}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
}

export default ModalPage;
