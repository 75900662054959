import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";

export function currentRoute(route) {
  dispatcher.dispatch({
    actionType: actionTypes.CURRENT_ROUTE,
    route: route,
  });
}

export function isRouting() {
  dispatcher.dispatch({
    actionType: actionTypes.IS_ROUTING,
  });
}
