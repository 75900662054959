import ObjectorHelper from "../FieldHelper/objectorHelper";
import ObjectionDetailsHelper from "../FieldHelper/objectionDetailsHelper";
import PropertyDetailsHelper from "../FieldHelper/propertyDetailsHelper";
import constants from "../components/Constants";
import PropertyIdentificationHelper from "../FieldHelper/propertyIdentificationHelper";
import SaleEvidenceHelper from "../FieldHelper/salesEvidenceHelper";
import ValidateObjectorHelper from "../FieldHelper/validateObjectorHelper";

export const TYPES = {
  SELECT: "select",
  DATE: "date",
  FIXED_LENGTH: "fixedLength",
  URL: "url",
  NUMERIC: "numeric",
  INTEGER: "integer",
  EMAIL: "email"
};

const SELECT_DEFAULT = "-1";

export function fieldValidator(moduleConstant, objectToValidate, helper) {
  let isSuccess = true;
  for (const [key, value] of Object.entries(helper)) {
    let val = objectToValidate[key];
    let fieldDetails = getFieldDetails(moduleConstant, key);

    if (
      fieldDetails.required &&
      (((!fieldDetails.type ||
        fieldDetails.type !== TYPES.SELECT ||
        fieldDetails.type !== TYPES.DATE) &&
        (!val || (val.replace && val.replace(/\s/g, "") === ""))) ||
        (fieldDetails.type === TYPES.SELECT &&
          (!val || val === SELECT_DEFAULT)) ||
        (fieldDetails.type === TYPES.DATE && !val))
    ) {
      value.hasError = true;
      isSuccess = false;
      if (fieldDetails.fieldName && fieldDetails.fieldName !== "") {
        value.errorText = `${fieldDetails.fieldName} is required`;
      }
    } else {
      value.hasError = false;
      value.errorText = "";
    }

    // checking the entered value for value type
    let isDataCorrect = isFieldTypeValid(fieldDetails, val);
    if (!isDataCorrect) {
      isSuccess = isDataCorrect;
    }
  }
  return isSuccess;
}

export function isFieldTypeValid(fieldDetails, value) {
  if (!value) {
    return true;
  }

  switch (fieldDetails.type) {
    case TYPES.FIXED_LENGTH:
      return isTypeFixedLengthValid(fieldDetails, value);

    case TYPES.NUMERIC:
      return isTypeNumericValid(fieldDetails, value);

    case TYPES.INTEGER:
      return isTypeIntegerValid(fieldDetails, value);

    case TYPES.EMAIL:
      return isTypeEmailValid(fieldDetails, value);

    default:
      return true;
  }
}

export function fieldLevelValidation(fieldDetails, value) {
  if (
    fieldDetails.required &&
    (((!fieldDetails.type || fieldDetails.type !== TYPES.SELECT) &&
      (!value || value.replace(/\s/g, "") === "")) ||
      (fieldDetails.type === TYPES.SELECT &&
        (!value || value === SELECT_DEFAULT)))
  ) {
    fieldDetails.hasError = true;
    if (fieldDetails.fieldName && fieldDetails.fieldName !== "") {
      fieldDetails.errorText = `${fieldDetails.fieldName} is required`;
    }
  } else {
    fieldDetails.hasError = false;
    if (fieldDetails.fieldName && fieldDetails.fieldName !== "") {
      fieldDetails.errorText = "";
    }
  }

  // checking the entered value for value type
  return isFieldTypeValid(fieldDetails, value);
}

function getFieldDetails(moduleConstant, key) {
  let fieldDetails = {};

  switch (moduleConstant) {
    case constants.OBJECTOR:
      fieldDetails = ObjectorHelper.getFieldDetail(key);
      break;

    case constants.OBJECTION:
      fieldDetails = ObjectionDetailsHelper.getFieldDetail(key);
      break;

    case constants.PROJECT_DETAILS:
      fieldDetails = PropertyDetailsHelper.getFieldDetail(key);
      break;

    case constants.PROPERTY_IDENTIFICATION:
      fieldDetails = PropertyIdentificationHelper.getFieldDetail(key);
      break;

    case constants.SALE_EVIDENCE:
      fieldDetails = SaleEvidenceHelper.getFieldDetail(key);
      break;

    case constants.VALIDATE_OBJECTOR:
      fieldDetails = ValidateObjectorHelper.getFieldDetail(key);
      break;

    default:
      break;
  }

  return fieldDetails;
}

function getPattern(isPositiveOnly, type) {
  switch (type) {
    case TYPES.NUMERIC:
      return isPositiveOnly
        ? new RegExp("^[0-9]+\\.?[0-9]*$")
        : new RegExp("^[-]?[0-9]+\\.?[0-9]*$");

    case TYPES.INTEGER:
      return isPositiveOnly
        ? new RegExp("^[0-9]*$")
        : new RegExp("^[-]?[0-9]*$");

    case TYPES.EMAIL:
      // Basic email validation. Note that proper email validation is almost impossible
      // with a regular expression and not recommend. Basic validation is designed to catch
      // simple typos, no more.
      return new RegExp(
        /^[^@\s]+@[^@\s]+\.[^@\s]+$/
      );

    default:
      return null;
  }
}

function isTypeFixedLengthValid(fieldDetails, value) {
  if (fieldDetails.length && fieldDetails.length < value.length) {
    fieldDetails.hasError = true;
    fieldDetails.errorText = `Maximum of ${fieldDetails.length} characters are allowed.`;
    return false;
  }
  return true;
}

function isTypeNumericValid(fieldDetails, value) {
  let pattern = getPattern(fieldDetails.isPositiveOnly, TYPES.NUMERIC);
  if (!pattern.test(value)) {
    fieldDetails.hasError = true;
    fieldDetails.errorText = `${fieldDetails.fieldName} should be numeric.`;
    return false;
  }

  return areBoundaryValuesValid(fieldDetails, value);
}

function isTypeIntegerValid(fieldDetails, value) {
  let pattern = getPattern(true, TYPES.INTEGER);
  if (!pattern.test(value)) {
    fieldDetails.hasError = true;
    fieldDetails.errorText = `${fieldDetails.fieldName} should only contain whole numbers.`;
    return false;
  }
  return areBoundaryValuesValid(fieldDetails, value);
}

function areBoundaryValuesValid(fieldDetails, value) {
  let minValue = fieldDetails.min;
  let maxValue = fieldDetails.max;

  if ((minValue && value < minValue) || (maxValue && value > maxValue)) {
    fieldDetails.hasError = true;
    fieldDetails.errorText = `${fieldDetails.fieldName} values should be between ${minValue} and ${maxValue}`;
    return false;
  }
  return true;
}

function isTypeEmailValid(fieldDetails, value) {
  let pattern = getPattern(null, TYPES.EMAIL);
  if (!pattern.test(value.toLowerCase())) {
    fieldDetails.hasError = true;
    fieldDetails.errorText = `${fieldDetails.fieldName} is not valid.`;
    return false;
  }
  return true;
}
