import React, { useState } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import MaterialInputDate from "../../controls/MaterialUI-InputDate";
import MDBTextArea from "../../controls/MDBReact-InputTextArea";
import leaseTerms from "../../Lists/LeaseTerms";
import Comparability from "../../Lists/Comparability";
import MaterialInputText from "../../controls/MaterialUI-InputText";
import MaterialInputCurrency from "../../controls/MaterialUI-InputCurrency";
import MaterialSelect from "../../controls/MaterialUI-Select";
import ModalPage from "../../controls/MDBReact-Modal";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import "./SaleEvidenceDetails.scss";

function SaleEvidenceDetails(props) {
  // getting the property details

  const [modalProps] = useState({
    showModal: false,
    header: "Confirmation",
    message: "",
  });

  return (
    <>
      <MDBRow>
        <MDBCol className="page-sub-headings mb-3">
          <div id={"saleEvidenceId" + props.index}>
            Sale evidence {props.index + 1}
            <button
              className="float-right delete-sale-evidence"
              id={props.index}
              onClick={props.handleDelete}
            >
              <MDBIcon aria-label="Trash" icon="trash" />
            </button>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Address"
            name="address"
            value={props.salesEvidence.address}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={props.salesEvidenceHelper.address.errorText}
            required={props.salesEvidenceHelper.address.required}
            hasError={props.salesEvidenceHelper.address.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
            maxLength={props.salesEvidenceHelper.address.length}
          ></MaterialInputText>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Paste web address of an online listing"
            name="saleListingURL"
            value={props.salesEvidence.saleListingURL}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={props.salesEvidenceHelper.saleListingURL.errorText}
            required={props.salesEvidenceHelper.saleListingURL.required}
            hasError={props.salesEvidenceHelper.saleListingURL.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputText>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
          <MaterialInputText
            label="Land area (m²)"
            name="landAreaM2"
            value={props.salesEvidence.landAreaM2}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={props.salesEvidenceHelper.landAreaM2.errorText}
            required={props.salesEvidenceHelper.landAreaM2.required}
            hasError={props.salesEvidenceHelper.landAreaM2.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputText>
        </MDBCol>
        <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
          <MaterialInputText
            label="Gross floor area (m²)"
            name="buildAreaM2"
            value={props.salesEvidence.buildAreaM2}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={props.salesEvidenceHelper.buildAreaM2.errorText}
            required={props.salesEvidenceHelper.buildAreaM2.required}
            hasError={props.salesEvidenceHelper.buildAreaM2.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputText>
        </MDBCol>
        <MDBCol
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="3"
          className="mb-3 date-two-column"
        >
          <MaterialInputDate
            id="saleDateId"
            name="saleDate"
            label="Sale date"
            value={
              !props.salesEvidence.saleDate
                ? null
                : props.salesEvidence.saleDate
            }
            onChange={(event) =>
              props.onDateChange(event, "saleDate", props.index)
            }
            helperText={props.salesEvidenceHelper.saleDate.errorText}
            required={props.salesEvidenceHelper.saleDate.required}
            hasError={props.salesEvidenceHelper.saleDate.hasError}
          />
        </MDBCol>
        <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
          <MaterialInputCurrency
            label="Sale price ($)"
            name="salePrice"
            value={props.salesEvidence.salePrice}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={props.salesEvidenceHelper.salePrice.errorText}
            required={props.salesEvidenceHelper.salePrice.required}
            hasError={props.salesEvidenceHelper.salePrice.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputCurrency>
        </MDBCol>
      </MDBRow>

      <MDBRow className="mb-3">
        <MDBCol>
          If there was an active lease on the sale property, please detail
          below:
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
          <MaterialInputCurrency
            label="Lease amount per year ($)"
            name="leaseAmountPa"
            value={props.salesEvidence.leaseAmountPa}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            helperText={props.salesEvidenceHelper.leaseAmountPa.errorText}
            required={props.salesEvidenceHelper.leaseAmountPa.required}
            hasError={props.salesEvidenceHelper.leaseAmountPa.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputCurrency>
        </MDBCol>
        <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
          <MaterialSelect
            inputLabel="Lease term"
            name="leaseTermId"
            value={props.salesEvidence.leaseTermId}
            onSelectChange={(event) => props.handleChange(event, props.index)}
            list={leaseTerms}
            formHelperText={props.salesEvidenceHelper.leaseTermId.errorText}
            disabled={false}
            required={props.salesEvidenceHelper.leaseTermId.required}
            hasError={props.salesEvidenceHelper.leaseTermId.hasError}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialSelect>
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <MaterialInputText
            label="Lease renewal options"
            name="leaseOptions"
            value={props.salesEvidence.leaseOptions}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            required={props.salesEvidenceHelper.leaseOptions.required}
            hasError={props.salesEvidenceHelper.leaseOptions.hasError}
            helperText={props.salesEvidenceHelper.leaseOptions.errorText}
            onBlur={(event) => props.onBlur(event, props.index)}
          ></MaterialInputText>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
          <Typography
            gutterBottom
            aria-label="salesEvidence-buildingConditionId"
          >
            Sale comparability
          </Typography>
          <Slider
            id="buildingConditionId"
            name="buildingConditionId"
            aria-labelledby="salesEvidence-buildingConditionId"
            step={1}
            min={1}
            max={3}
            onKeyDown={(event, value) => {
              props.sliderChange(
                event,
                value,
                props.index,
                "buildingConditionId"
              );
            }}
            onChange={(event, value) => {
              props.sliderChange(
                event,
                value,
                props.index,
                "buildingConditionId"
              );
            }}
            defaultValue={
              props.salesEvidence.buildingConditionId
                ? props.salesEvidence.buildingConditionId
                : 2
            }
            marks={Comparability}
            required={props.salesEvidenceHelper.buildingConditionId.required}
            error={props.salesEvidenceHelper.buildingConditionId.hasError}
          />
        </MDBCol>
        <MDBCol xl="6" lg="6" md="6" sm="6" xs="6"></MDBCol>
      </MDBRow>

      <MDBRow className="mb-3">
        <MDBCol>
          Detail the comparison between this sale and the subject property:
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol>
          <MDBTextArea
            label="Comparability to your property"
            name="saleDescription"
            value={props.salesEvidence.saleDescription}
            onChange={(event) => props.handleChange(event, props.index)}
            disabled={false}
            hasError={props.salesEvidenceHelper.saleDescription.hasError}
            required={props.salesEvidenceHelper.saleDescription.required}
          ></MDBTextArea>
        </MDBCol>
      </MDBRow>

      {modalProps.showModal && (
        <ModalPage
          header={modalProps.header}
          showModal={modalProps.showModal}
          message={modalProps.message}
        />
      )}
    </>
  );
}

export default SaleEvidenceDetails;
