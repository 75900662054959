import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import CommonStore from "./../stores/commonStore";

export async function getLandingPageText() {
  return getPageContentBySlug('vic_obj_landingpagetext');
}

export function dispatchLandingPageText(landingPageText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_LANDING_PAGE_TEXT,
    landingPageText: landingPageText,
  });
}

export function getPropertyVerificationModalText() {
  return getPageContentBySlug('vic_obj_out-of-time');
}

export function dispatchPropertyVerificationModalText(
  propertyVerificationModalText
) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_PROPERTY_VERIFICATION_MODAL_TEXT,
    propertyVerificationModalText: propertyVerificationModalText,
  });
}

export function getObjectingToValuesText() {
  return getPageContentBySlug(
    'vic_obj_objecting-to-values-text', 
    dispatchObjectingToValuesText);
}

export function dispatchObjectingToValuesText(objectingToValuesText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_OBJECTING_TO_VALUES_TEXT,
    objectingToValuesText: objectingToValuesText,
  });
}

export function getObjectionCommentsText() {
  return getPageContentBySlug(
    'vic_obj_objection-comment-text', 
    dispatchObjectionCommentsText);
}

export function dispatchObjectionCommentsText(objectionCommentsText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_OBJECTION_COMMENTS_TEXT,
    objectionCommentsText: objectionCommentsText,
  });
}

export function getPropertyDetailsText() {
  return getPageContentBySlug(
    'vic_obj_helper-property-details', 
    dispatchPropertyDetailsText);
}

export function dispatchPropertyDetailsText(propertyDetailsText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_PROPERTY_DETAILS_TEXT,
    propertyDetailsText: propertyDetailsText,
  });
}

export function getSalesEvidenceText() {
  return getPageContentBySlug(
    'vic_obj_helper-sales-text', 
    dispatchSalesEvidenceText);
}

export function dispatchSalesEvidenceText(salesEvidenceText) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_SALES_EVIDENCE_TEXT,
    salesEvidenceText: salesEvidenceText,
  });
}

export function getObjectingToValuesWarning() {
  return getPageContentBySlug(
    'vic_obj_warning-blank-values', 
    dispatchObjectingToValuesWarning);
}

export function dispatchObjectingToValuesWarning(objectingToValuesWarning) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_OBJECTING_TO_VALUES_WARNING,
    objectingToValuesWarning: objectingToValuesWarning,
  });
}

function getPageContentBySlug(slug, dispatchTo) {
  const url =
    new URL('/pages.json', process.env.REACT_APP_CONTENT_URL).href;

  return fetch(url)
    .then(response => response.json())
    .then(json => {
      let content;
      json.forEach(x => {
          if (x.slug === slug) {
            content = x.content.rendered;
            if (dispatchTo) {
              dispatchTo(content);
            }
            return false;
          }
          return true;
      });
      return content;
    })
    .catch(error => console.error(error));
}

export function getCouncilRatesNotice(lgaCode) {
  const url =
    new URL("/lga-ratenotice-example.json", process.env.REACT_APP_CONTENT_URL).href;

  return fetch(url)
    .then(response => response.json())
    .then(json => {
        let content = null;
        json.forEach(x => {
          if (x.lga_code===lgaCode) {
            content = x.content.rendered;
            dispatchCouncilRateNotice(lgaCode, content);
            return false;
          }
        });
        return content;
    })
    .catch(error => console.error(error));
}

export function dispatchCouncilRateNotice(councilId, councilRatesNotice) {
  dispatcher.dispatch({
    actionType: actionTypes.SET_COUNCIL_RATES_NOTICE,
    councilId: councilId,
    councilRatesNotice: councilRatesNotice,
  });
}

export function getHelperTexts() {
  const url =
    new URL('/pages.json', process.env.REACT_APP_CONTENT_URL).href;

  fetch(url)
    .then(response => response.json())
    .then(json => {
      json.forEach(x => {
        switch (x.slug) {
          case "vic_obj_warning-blank-values":
            CommonStore.setWarningBlankValues(x.content.rendered);
            break;

          case "vic_obj_helper-sales-text":
            CommonStore.setSalesEvidenceText(x.content.rendered);
            break;

          case "vic_obj_helper-property-details":
            CommonStore.setPropertyDetails(x.content.rendered);
            break;

          case "vic_obj_objection-comment-text":
            CommonStore.setObjectionComments(x.content.rendered);
            break;

          case "vic_obj_objecting-to-values-text":
            CommonStore.setObjectingToValuesText(x.content.rendered);
            break;

          case "vic_obj_out-of-time":
            CommonStore.setPropertyVerificationModalText(x.content.rendered);
            break;

          case "vic-obj-propverify-footer-instruction-text":
            CommonStore.setPropertyVerifyFooterText(x.content.rendered);
            break;

          case "vic-obj-propverify-main-instruction-text":
            CommonStore.setPropertyVerifyMainText(x.content.rendered);
            break;

          case "vic-obj-helper-objector-details-text":
            CommonStore.setObjectorDetailsText(x.content.rendered);
            break;

          case "vic-obj-helper-review-and-submit-text":
            CommonStore.setReviewAndSubmitText(x.content.rendered);
            break;

          case "vic-obj-helper-objection-grounds-text":
            CommonStore.setObjectionGroundsText(x.content.rendered);
            break;

          default:
            break;
        }
      });
  })
  .catch(error => console.error(error));
}
