//#region Imports
import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import constants from "../components/Constants";
import {
  removeItem,
  retrieveItem
} from "../components/Session/Session";
import PropertyIdentificationHelper from "../FieldHelper/propertyIdentificationHelper";
import ObjectionDetailsHelper from "../FieldHelper/objectionDetailsHelper";
import PropertyDetailsHelper from "../FieldHelper/propertyDetailsHelper";
import SalesEvidenceHelper from "../FieldHelper/salesEvidenceHelper";

//#endregion Imports

/*
  WE WOULD MAKE COMMUNICATION BETWEEN THE FLUX STORE AND ACTIONS TWO WAY
  1 - ACTION WOULD TRIGGER DISPATCHER AND CALL FLUX STORE TO UPDATE THE OBJECT
  2 - IN CASE THE FLUX OBJECT IS EMPTY AND THE VALUE IS IN SESSION, STORE WILL 
  CALL THE ACTION TO TRIGGER ITS OWN DISPATCH
*/

//#region declarations
const CHANGE_EVENT = "change";
let _propertyId = {}; //private member, holds the Current PropertyId for which objection is being used
let _properties = []; //private
let _salesEvidenceHelpers = [];
let _propertyIdentificationHelper = PropertyIdentificationHelper.getPropertyIdentificationHelper();
let _objectionDetailsHelper = ObjectionDetailsHelper.getObjectionDetailsHelper();
let _propertyDetailsHelper = PropertyDetailsHelper.getPropertyDetailsHelper();
let _salesEvidenceHelper = SalesEvidenceHelper.getSalesEvidenceHelper();
let _newProperty = {
  address: "",
  propertyId: "",
  objectorId: "",
  status: "new",
  councilId: "-1",
  assessmentNumber: "",
  siteValue: "",
  contendedSiteValue: "",
  capitalImprovedValue: "",
  contendedCapitalImprovedValue: "",
  netAnnualValue: "",
  contendedNetAnnualValue: "",
  rateNoticeReceived: null,
  objectionReasonId: "",
  objectionReason: "",
  objectionComment: "",
  salesEvidence: [],
  attachment: [],
  propertyTypeId: "-1",
  propertyType: "",
  dwellingTypeId: "-1",
  dwellingType: "",
  numberBathrooms: "",
  landArea: "",
  landAreaUnitID: "1",
  landAreaUnit: "",
  yearBuilt: "-1",
  yearBuiltDescription: "",
  landDepthM2: "",
  numberBedrooms: "",
  constructionMaterialId: "-1",
  constructionMaterial: "",
  buildAreaM2: "",
  yearRenovation: "",
  landWidthM2: "",
  numberRooms: "",
  dwellingDescription: "",
  buildingConditionId: "-1",
  buildingCondition: "",
  netLettableArea: "",
  carParks: "",
  leaseAmountPA: "",
  leaseTerm: "",
  leaseTermDescription: "",
  options: "",
  leaseCommenced: null,
  reviewFrequency: "",
  reviewFrequencyDescription: "",
  reviewBasis: "",
  reviewBasisDescription: "",
  reviewDetail: "",
  tenantOutgoings: "",
  ownerOutgoings: "",
  property_category: "",
  assessment_id: "",
  property_code: "0",
  contended_property_code: "0",
  contended_property_desc: "No Code Assigned",
  objectorAcknowledgedNoValues: false,
  hasBlankValues: false, // this field is just for UI purposes
  displayedAcceptenceCheck: false, // this field is just for UI purposes
  blankValuesError: false,  // this field is just for UI purposes
  isNetAnnualValueObjected: false,
  isCapitalImprovedValueObjected: false,
  isSiteValueObjected: false,
  objectorAcknowledgedNoObjection: false,
  displayObjectionAcceptenceCheck: false,
};

let _propertyLoginError = null;

//#endregion declarations

class PropertyStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback); // change is the event
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getNewPropertyAsArray() {
    return [_newProperty];
  }

  getNewProperty() {
    let property = {};
    property = Object.assign(property, _newProperty);
    return property;
  }

  getProperties(objectorId) {
    // check if properties are in flux store
    if (_properties && _properties.length > 0) {
      return _properties.filter((x) => x.objectorId === objectorId);
    }

    // now check if there is anything in local storage
    let propertiesInSession = retrieveItem(constants.PROPERTIES);
    if (propertiesInSession) {
      propertiesInSession = propertiesInSession.filter(
        (x) => x.objectorId === objectorId
      );

      _properties = Object.assign(_properties, propertiesInSession);
    }

    return _properties;
  }

  getPropertyById(id) {
    if (id === null) {
      return _newProperty;
    }

    if (!_properties || _properties.length === 0) {
      let value = retrieveItem(constants.PROPERTIES);
      if (value && value.length > 0) {
        _properties = value;
      }
    }
    let property = _properties.filter((x) => x.propertyId === id.toString());
    return property && property.length > 0 ? property[0] : _newProperty;
  }

  getPropertyId() {
    let propertyId = retrieveItem(constants.PROPERTY_ID);
    if (propertyId) {
      return propertyId;
    } else {
      return null;
    }
  }

  getAttachments() {
    // check the flux object first
    if (_properties && _properties.length > 0) {
      let currentProperty = _properties.filter(
        (x) => x.propertyId === _propertyId
      );
      if (currentProperty && currentProperty.length > 0) {
        return currentProperty[0].attachment;
      }
    }

    // if not in flux, check the cache
    let cachedProperties = retrieveItem(constants.PROPERTIES);
    let cachedPropertyId = retrieveItem(constants.PROPERTY_ID);
    if (cachedProperties && cachedProperties.length > 0) {
      let currentProperty = cachedProperties.filter(
        (x) => x.propertyId === cachedPropertyId
      );
      if (currentProperty && currentProperty.length > 0) {
        return currentProperty[0].attachment;
      }
    }

    return [];
  }

  updateSaleEvidence(propertyId, salesEvidence, index) {
    let propertyToUpdate = _properties.filter(
      (x) => x.propertyId === propertyId
    );

    if (propertyToUpdate && propertyToUpdate.length > 0) {
      propertyToUpdate[0].salesEvidence.splice(index, 1, salesEvidence);
    }
  }

  getSalesEvidenceById(propertyId) {
    let property = _properties.filter(
      (x) => x.propertyId === propertyId.toString()
    );
    return property && property.length > 0 ? property[0].salesEvidence : [];
  }

  // *******************************************
  // ************Property Helper****************
  // *******************************************
  getPropertyIdentificationHelper() {
    return _propertyIdentificationHelper;
  }

  getObjectionDetailsHelper() {
    return _objectionDetailsHelper;
  }

  getPropertyDetailsHelper() {
    return _propertyDetailsHelper;
  }

  getSalesEvidenceHelper() {
    return _salesEvidenceHelper;
  }

  getSalesEvidenceHelpers() {
    return _salesEvidenceHelpers;
  }

  initialiseProperty() {
    _propertyId = {};
    _properties = [];
    removeItem(constants.PROPERTIES);
    removeItem(constants.PROPERTY_ID);
  }

  getPropertyLoginError() {
    return _propertyLoginError;
  }
}

// we need to instantiate the store
const store = new PropertyStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.SET_PROPERTY_LOGIN_ERROR:
      _propertyLoginError = action.propertyLoginError;
      break;

    case actionTypes.SAVE_PROPERTY:
      _properties.push(action.property);
      store.emitChange();
      break;

    case actionTypes.SAVE_PROPERTIES:
      // remove the existing properties for this objector
      _properties = _properties.filter(
        (x) => x.objectorId !== action.objectorId
      );
      _properties = [..._properties, ...action.properties];
      store.emitChange();
      break;

    case actionTypes.ADD_PROPERTY:
      _properties = action.properties;
      store.emitChange();
      break;

    case actionTypes.DELETE_PROPERTY:
      _properties = _properties.filter(
        (x) => x.propertyId !== action.propertyId
      );
      //removeItem(constants.PROPERTIES);
      //storeItem(_properties, constants.PROPERTIES);
      store.emitChange();
      break;

    case actionTypes.UPDATE_PROPERTY:
      {
        // getting the current index of this property in the array
        let index = _properties.findIndex(
          (x) => x.propertyId === action.property.propertyId
        );
        // Remove existing property and insert updated object in the same location
        _properties.splice(index, 1, action.property);
      }
      // updating localStorage
      //removeItem(constants.PROPERTIES);
      //storeItem(_properties, constants.PROPERTIES);
      store.emitChange();
      break;

    case actionTypes.SET_PROPERTY_ID:
      _propertyId = action.propertyId;
      //removeItem(constants.PROPERTY_ID);
      //storeItem(_propertyId, constants.PROPERTY_ID);
      store.emitChange();
      break;

    case actionTypes.SUBMIT_PROPERTY:
      {
        // getting the property which is required to be updated
        let propertyToUpdate = _properties.filter(
          (x) => x.propertyId === action.property.propertyId
        );

        // getting the current index of this property in the array
        let index = _properties.findIndex(
          (x) => x.propertyId === action.property.propertyId
        );

        if (!propertyToUpdate || propertyToUpdate.length === 0) {
          // if this property does not exist in the array, insert it
          _properties.push(action.property);
        } else {
          // if this property exist, remove it and insert updated object in the same location
          _properties.splice(index, 1, action.property);
        }
        // updating localStorage
        //removeItem(constants.PROPERTIES);
        //storeItem(_properties, constants.PROPERTIES);
        store.emitChange();
      }
      break;

    case actionTypes.UPDATE_PROPERTY_IDENTIFICATION_HELPER:
      _propertyIdentificationHelper = action.propertyIdentificationHelper;
      store.emitChange();
      break;

    case actionTypes.UPDATE_OBJECTION_DETAILS_HELPER:
      _objectionDetailsHelper = action.objectionDetailsHelper;
      store.emitChange();
      break;

    case actionTypes.UPDATE_PROPERTY_DETAILS_HELPER:
      _objectionDetailsHelper = action.objectionDetailsHelper;
      store.emitChange();
      break;

    case actionTypes.UPDATE_SALES_EVIDENCE_HELPER:
      _salesEvidenceHelpers = [];
      _salesEvidenceHelpers = Object.assign(
        _salesEvidenceHelpers,
        action.salesEvidenceHelpers
      );
      store.emitChange();
      break;

    default:
      //do nothing
      break;
  }
});

export default store;
