const eraBuilt = [
  { id: "-1", name: "Please select era built" },
  { id: "120", name: "2020s" },
  { id: "110", name: "2010s" },
  { id: "100", name: "2000s" },
  { id: "90", name: "1990s" },
  { id: "80", name: "1980s" },
  { id: "70", name: "1970s" },
  { id: "60", name: "1960s" },
  { id: "50", name: "1950s" },
  { id: "40", name: "1940s" },
  { id: "30", name: "1930s" },
  { id: "20", name: "1920s" },
  { id: "10", name: "1910s" },
  { id: "0", name: "1900s" },
  { id: "-10", name: "1890s" },
  { id: "-20", name: "Pre 1880" },
];

export default eraBuilt;
