//#region import
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import PropertyStore from "../../stores/propertyStore";
import ObjectionStore from "../../stores/objectionStore";
import CommonStore from "../../stores/commonStore";
import MDBTextArea from "../../controls/MDBReact-InputTextArea";
import * as PropertyActions from "../../actions/propertyActions";
import * as ObjectionActions from "../../actions/objectionActions";
import ObjectionDetailsHelper from "../../FieldHelper/objectionDetailsHelper";
import FormatCurrency from "../../utilities/currencyformatter";
import { FocusOnErrorControl } from "../../utilities/focusErrorControl";
import constants from "../Constants";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import {
  fieldValidator,
  fieldLevelValidation,
} from "../../utilities/fieldValidator";
import ObjectorStore from "../../stores/objectorStore";
import { getNamefromList } from "../../utilities/getNameFromList";
import Councils from "../../Lists/Councils";
import objectionReasons from "../../Lists/ObjectionReasons";
import MaterialRadio from "../../controls/MaterialUI-Radio";
import Steppers from "../../controls/Steppers/Steppers";
import MobileLinearProgress from "../../controls/MobileLinearProgress/MobileLinearProgress";
import { isEmailLink } from "../../utilities/isEmailLink";
import { ScrollTo } from "../../utilities/focusErrorControl";
import "./ObjectionDetails.scss";
import MaterialInputCurrency from "../../controls/MaterialUI-InputCurrency";
import Alert from "@material-ui/lab/Alert";
import ErrorIcon from "@material-ui/icons/Error";

//#endregion imports

function ObjectionDetails(props) {
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }

  //#region verifying if the loading of this component was using
  // routing or Did user entered a URL and opened this page
  var objector = ObjectorStore.getObjector();
  var propertyId = null;
  if (!objector || !objector.objectorId) {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ObjectorDetails",
      });
  } else {
    // we came here means, we do have an Objector in flux / session
    // now check if we have a propertyId
    if (props && props.location && props.location.propertyId && props.location.propertyId !== '0') {
      propertyId = props.location.propertyId;
    } else {
      props &&
        props.history &&
        props.history.push({
          pathname: "/PropertyIdentification",
          objectorId: objector.objectorId,
        });
    }
  }
  //#endregion verifying the loading of this component

  //#region component declarations
  const [errorMessage, setErrorMessage] = useState(null);
  const Objection_Reason_AVPCC = "7";
  const [objectingToValuesText] = useState(
    CommonStore.getObjectingToValuesText()
  );
  const [objectionCommentsText] = useState(
    CommonStore.getObjectionCommentsText()
  );
  const [groundsForObjectingText] = useState(
    CommonStore.getObjectionGroundsText()
  );
  const [property, setProperty] = useState(
    PropertyStore.getPropertyById(propertyId)
  );
  const [objectionDetailsHelper] = useState(
    ObjectionStore.getObjectionDetailsHelper()
  );
  //#endregion component declaration

  //#region useEffect State hook
  useEffect(() => {
    PropertyStore.addChangeListener(onchange);

    document.title = "Object to rating valuation - Grounds for objection";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    function onchange() {
      setProperty(PropertyStore.getPropertyById(propertyId));
    }

    return () => PropertyStore.removeChangeListener(onchange); //cleans up on unmount of component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange({ target }) {
    let updateProperty = {};
    updateProperty = Object.assign(updateProperty, property);

    if (
      target.name === "contendedSiteValue" ||
      target.name === "contendedCapitalImprovedValue" ||
      target.name === "contendedNetAnnualValue"
    ) {
      if (target.value && target.value !== "") {
        updateProperty.blankValuesError = false;
        updateProperty.hasBlankValues = false;
        updateProperty.displayedAcceptenceCheck = false;
        updateProperty.objectorAcknowledgedNoValues = false;
      }
    }
    updateProperty[target.name] = target.value;
    setProperty(updateProperty);
  }
  //#endregion useEffect State hook

  //#region API calling methods
  function saveObjectionDetails() {
    startLoader();

    let isFieldValidationSuccess = fieldValidator(
      constants.OBJECTION,
      property,
      objectionDetailsHelper
    );

    if (!isFieldValidationSuccess) {
      ObjectionActions.updateObjectionDetailsHelper(objectionDetailsHelper);
      setTimeout(() => {
        stopLoader();
      }, 100);
      FocusOnErrorControl(objectionDetailsHelper);
      return;
    }

    // if all three contended values are left blank we need to turn on the
    // check box, and make user click it to process
    if (!property.isSiteValueObjected && !property.isNetAnnualValueObjected && !property.isCapitalImprovedValueObjected) {
      let fieldName = "displayObjectionAcceptenceCheck";
      setProperty({ ...property, [fieldName]: true });
      let element = document.getElementById("obj-details-csv");
      if (!property.displayObjectionAcceptenceCheck) {
        // if not, display it
        let fieldName = "displayObjectionAcceptenceCheck";
        setProperty({ ...property, [fieldName]: true });

        ScrollTo(element);

        setTimeout(() => {
          stopLoader();
        }, 100);
        return;
      }
      else {
        if (!property.objectorAcknowledgedNoObjection) {
          let fieldName = "blankValuesError";
          setProperty({ ...property, [fieldName]: true });

          ScrollTo(element);

          setTimeout(() => {
            stopLoader();
          }, 100);
          return;
        } else {
          let fieldName = "blankValuesError";
          setProperty({ ...property, [fieldName]: false });
        }
      }
    }
    else if (
      property.contendedSiteValue === "" &&
      property.contendedCapitalImprovedValue === "" &&
      property.contendedNetAnnualValue === "" &&
      !property.objectorAcknowledgedNoObjection 
    ) {
      let fieldName = "displayedAcceptenceCheck";
      setProperty({ ...property, [fieldName]: true });

      // check if the check is Already displayed in DOM
      let element = document.getElementById("obj-details-csv");
      if (!property.displayedAcceptenceCheck) {
        // if not, display it
        let fieldName = "displayedAcceptenceCheck";
        setProperty({ ...property, [fieldName]: true });

        ScrollTo(element);

        setTimeout(() => {
          stopLoader();
        }, 100);
        return;
      } else {
        if (!property.objectorAcknowledgedNoValues) {
          let fieldName = "blankValuesError";
          setProperty({ ...property, [fieldName]: true });
  
          ScrollTo(element);

          setTimeout(() => {
            stopLoader();
          }, 100);
          return;
        } else {
          let fieldName = "blankValuesError";
          setProperty({ ...property, [fieldName]: false });
        }
      }
    }
    if (isFieldValidationSuccess) {
      let errorMessage = 'Objection details failed to save.	Click Back then Next to reload the step and try again.	If the problem persists, contact the council on your notice and lodge your objection directly with the rates team.';
      PropertyActions.updateObjectionDetailsOfProperty(
        property,
        objector.objectorId,
        property.objectorAcknowledgedNoValues
      ).then((response) => {
        if (response && response.errors && response.errors.length > 0) {
          showError(errorMessage);
          stopLoader();
          return;
        }
        else {
        setTimeout(() => {
            stopLoader();
            props &&
              props.history &&
              props.history.push({
                pathname: "/PropertyDetails",
                propertyId: property.propertyId,
                objectorId: objector.objectorId,
              });
            PropertyActions.dispatchUpdateProperty(property);
            ObjectionActions.updateObjectionDetailsHelper(objectionDetailsHelper);
          }, 1500);
        }
      },
        (error) => {
          showError(errorMessage);
          stopLoader();
        });
    } else {
      stopLoader();
      setTimeout(() => { }, 100);
    }
  }

  function showError(message) {
    setErrorMessage(message);
    let element = document.getElementById("objectionDetailsError");
    ScrollTo(element);
  }

  function checkboxChanged({ target }) {
    let updateProperty = {};
    updateProperty = Object.assign(updateProperty, property);
    if (!target.checked) {
      switch (target.name) {
        case 'isSiteValueObjected':
          updateProperty.contendedSiteValue = '';
          break;
        case 'isCapitalImprovedValueObjected':
          updateProperty.contendedCapitalImprovedValue = '';
          break;
        case 'isNetAnnualValueObjected':
          updateProperty.contendedNetAnnualValue = '';
          break;
        default:
          break;
      }
    }
    if (updateProperty.isSiteValueObjected || updateProperty.isCapitalImprovedValueObjected || updateProperty.isNetAnnualValueObjected)
    {
      updateProperty.objectorAcknowledgedNoObjection = false;
      updateProperty.displayObjectionAcceptenceCheck = false;
    }
    else
      updateProperty.objectorAcknowledgedNoObjection = false;
    updateProperty[target.name] = target.checked;
    setProperty(updateProperty);
  }

  function objectionReasonChanged({ target }) {
    let updateProperty = {};
    updateProperty = Object.assign(updateProperty, property);

    if (target.name === "objectionReasonId") {
      if (target.value !== Objection_Reason_AVPCC) {
        updateProperty.contended_property_code = "0";
        updateProperty.contended_property_desc = "No Code Assigned";
      }
    }
    updateProperty.objectionReasonId = target.value;
    setProperty(updateProperty);
  }

  //#region event handler
  function onContendedValueBlur(event) {
    let fieldDetails = ObjectionDetailsHelper.getFieldDetail(event.target.name);
    let finalValue = event.target.value;
    if (finalValue && finalValue !== ""
      && fieldDetails && fieldDetails.type === "currency") {
      finalValue = FormatCurrency.removeCurrencyFormat(event.target.value);
      if (fieldDetails.isPositiveOnly) {
        finalValue = finalValue < 0 ? finalValue * -1 : finalValue;
      }
      finalValue = FormatCurrency.formatCurrency(
        FormatCurrency.removeCurrencyFormat(finalValue)
      );
    }

    // field level required validation
    fieldLevelValidation(
      objectionDetailsHelper[event.target.name],
      event.target.value
    );
    ObjectionActions.updateObjectionDetailsHelper(objectionDetailsHelper);
    setProperty({ ...property, [event.target.name]: finalValue });
  }

  function onTextAreaBlur({ target }) {
    setProperty({ ...property, [target.name]: target.value });
  }

  //#endregion event handler

  function goToPropertyIdentification() {
    props &&
      props.history &&
      props.history.push({
        pathname: "/PropertyIdentification",
        objectorId: objector.objectorId,
      });
  }

  //#region JSX Code
  return (
    <>
      <MDBContainer>
        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <h4>{property.address}</h4>
            <h5>{getNamefromList(Councils, property.councilId)}</h5>
            <h5>Assessment number: {property.assessmentNumber}</h5>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol className="d-none d-md-block stepper-aligned">
            <Steppers location={props.location} />
          </MDBCol>
          <MDBCol className="d-block d-md-none stepper-aligned">
            <MobileLinearProgress
              location={props.location}
            ></MobileLinearProgress>
          </MDBCol>
        </MDBRow>

        <MDBRow className="page-sub-headings mb-3">
          <MDBCol>Grounds for objection (required)</MDBCol>
        </MDBRow>

        {groundsForObjectingText && (
          <>
            <MDBRow className="helper-text-background mb-3">
              <MDBCol>
                <div
                  className="cms-content"
                  dangerouslySetInnerHTML={{ __html: groundsForObjectingText }}
                ></div>
              </MDBCol>
            </MDBRow>
          </>
        )}

        <MDBRow>
          <MDBCol xl="12" lg="12" md="12" sm="12" className="mb-3">
            <div id="objectionDetailsError">
              {errorMessage &&
                <Alert severity="error">
                  {errorMessage}
                </Alert>
              }
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol className="objection-reason mb-3">
            <MaterialRadio
              list={objectionReasons}
              name="objectionReasonId"
              value={property.objectionReasonId}
              aria-required={true}
              radioChanged={objectionReasonChanged}
              required={objectionDetailsHelper.objectionReasonId.required}
              error={objectionDetailsHelper.objectionReasonId.hasError}
              helperText={objectionDetailsHelper.objectionReasonId.errorText}
            ></MaterialRadio>
          </MDBCol>
        </MDBRow>

        {objectingToValuesText && (
          <>
            <MDBRow className="helper-text-background mb-3">
              <MDBCol>
                <div
                  className="cms-content"
                  dangerouslySetInnerHTML={{ __html: objectingToValuesText }}
                ></div>
              </MDBCol>
            </MDBRow>
          </>
        )}
        <MDBRow>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <strong>Rate notice</strong>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <MaterialInputCurrency
              name="siteValue"
              label="Site Value"
              helperText=""
              required={false}
              value={property.siteValue}
              disabled={true}
              hasError={false}
            />
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <MaterialInputCurrency
              name="capitalImprovedValue"
              label="Capital Improved Value"
              required={false}
              helperText=""
              value={property.capitalImprovedValue}
              disabled={true}
              hasError={false}
            />
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <MaterialInputCurrency
              name="netAnnualValue"
              label="Net Annual Value"
              required={false}
              helperText=""
              value={property.netAnnualValue}
              disabled={true}
              hasError={false}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <strong>Object to</strong>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                aria-labelledby="do-not-object"
                type="checkbox"
                className="custom-control-input"
                id="isSiteValueObjected"
                onChange={checkboxChanged}
                name="isSiteValueObjected"
                checked={ property.isSiteValueObjected }
                label="Site Value(SV)"
              />

              <label
                id="label-accept-blank-values"
                className="custom-control-label"
                htmlFor="isSiteValueObjected"
              >
                Site Value(SV)
              </label>
            
            {!property.isSiteValueObjected &&
              !property.isCapitalImprovedValueObjected &&
              !property.isNetAnnualValueObjected &&
              property.displayObjectionAcceptenceCheck && 
              !property.objectorAcknowledgedNoObjection &&
              <ErrorIcon className="no-contended-error-icon-checkbox" />
            }
            </div>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            
            <div className="custom-control custom-checkbox">
              <input
                aria-labelledby="do-not-object"
                type="checkbox"
                className="custom-control-input"
                id="isCapitalImprovedValueObjected"
                onChange={checkboxChanged}
                name="isCapitalImprovedValueObjected"
                checked={
                  property.isCapitalImprovedValueObjected === true ? true : false
                }
              />

              <label
                id="label-accept-blank-values"
                className="custom-control-label"
                htmlFor="isCapitalImprovedValueObjected"
              >
                Capital Improved Value(CIV)
              </label>
            
            {!property.isSiteValueObjected &&
              !property.isCapitalImprovedValueObjected &&
              !property.isNetAnnualValueObjected &&
              property.displayObjectionAcceptenceCheck &&
              !property.objectorAcknowledgedNoObjection &&
                  <ErrorIcon className="no-contended-error-icon-checkbox" />
            }
            </div>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                aria-labelledby="do-not-object"
                type="checkbox"
                className="custom-control-input"
                id="isNetAnnualValueObjected"
                onChange={checkboxChanged}
                name="isNetAnnualValueObjected"
                checked={
                  property.isNetAnnualValueObjected === true ? true : false
                }
              />

              <label
                id="label-accept-blank-values"
                className="custom-control-label"
                htmlFor="isNetAnnualValueObjected"
              >
                Net Annual Value(NAV)
              </label>
            
              {!property.isSiteValueObjected &&
                !property.isCapitalImprovedValueObjected &&
                !property.isNetAnnualValueObjected &&
                property.displayObjectionAcceptenceCheck &&
                !property.objectorAcknowledgedNoObjection &&
                <ErrorIcon className="no-contended-error-icon-checkbox" />
              }
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3">
            <b>
              <b>Contended values</b>
            </b>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3 contended-value">
            <MaterialInputCurrency
              id="obj-details-csv"
              name="contendedSiteValue"
              label=""
              helperText={property.contendedSiteValue ? "Contended Site Value" : "Not contended"}
              value={property.contendedSiteValue}
              onChange={handleChange}
              disabled={!property.isSiteValueObjected}
              required={objectionDetailsHelper.contendedSiteValue.required}
              hasError={objectionDetailsHelper.contendedSiteValue.hasError}
              onBlur={onContendedValueBlur}
              placeholder="No value entered"
            />
            {!property.contendedSiteValue &&
              !property.contendedCapitalImprovedValue &&
              !property.contendedNetAnnualValue &&
              !property.objectorAcknowledgedNoObjection &&
              property.displayedAcceptenceCheck && 
              <ErrorIcon className="no-contended-error-icon" />
            }
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3 contended-value">
            <MaterialInputCurrency
              id="obj-details-cciv"
              name="contendedCapitalImprovedValue"
              label=""
              helperText={property.contendedCapitalImprovedValue ? "Contended Capital Improved Value" : "Not contended"}
              value={property.contendedCapitalImprovedValue}
              onChange={handleChange}
              disabled={!property.isCapitalImprovedValueObjected}
              required={
                objectionDetailsHelper.contendedCapitalImprovedValue.required
              }
              hasError={
                objectionDetailsHelper.contendedCapitalImprovedValue.hasError
              }
              onBlur={onContendedValueBlur}
              placeholder="No value entered"
            />
            {!property.contendedSiteValue &&
              !property.contendedCapitalImprovedValue &&
              !property.contendedNetAnnualValue &&
              !property.objectorAcknowledgedNoObjection &&
              property.displayedAcceptenceCheck && 
              <ErrorIcon className="no-contended-error-icon" />
            }
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="3" xs="3" className="mb-3 contended-value">
            <MaterialInputCurrency
              id="obj-details-cnav"
              name="contendedNetAnnualValue"
              label=""
              helperText={property.contendedNetAnnualValue ? "Contended Net Annual Value" : "Not contended"}
              value={property.contendedNetAnnualValue}
              onChange={handleChange}
              disabled={!property.isNetAnnualValueObjected}
              required={objectionDetailsHelper.contendedNetAnnualValue.required}
              hasError={objectionDetailsHelper.contendedNetAnnualValue.hasError}
              onBlur={onContendedValueBlur}
              placeholder="No value entered"
            />
            {!property.contendedSiteValue &&
              !property.contendedCapitalImprovedValue &&
              !property.contendedNetAnnualValue &&
              !property.objectorAcknowledgedNoObjection &&
              property.displayedAcceptenceCheck && 
                            <ErrorIcon className="no-contended-error-icon" />
            }
          </MDBCol>
        </MDBRow>


        {!property.isNetAnnualValueObjected &&
          !property.isCapitalImprovedValueObjected &&
          !property.isSiteValueObjected &&
          !property.objectorAcknowledgedNoObjection &&
          property.displayObjectionAcceptenceCheck &&
          (
            <>
              <MDBRow className="objecting-values-warning mb-3">
                <MDBCol>
                  <MDBRow>
                    <MDBCol>
                      <div
                      className="cms-content"><h4><strong>You have not objected to any value(s)</strong></h4>
                      <p>You have not included any contended values. Providing contended values is encouraged, as this will assist in the determination of your objection.</p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          aria-labelledby="do-not-object"
                          type="checkbox"
                          className="custom-control-input"
                          id="objectorAcknowledgedNoObjection"
                          onChange={checkboxChanged}
                          name="objectorAcknowledgedNoObjection"
                          checked={property.objectorAcknowledgedNoObjection}
                        />

                        <label
                          id="label-accept-blank-values"
                          className={`custom-control-label ${property.blankValuesError ? "checkboxError" : ""
                            }`}
                          htmlFor="objectorAcknowledgedNoObjection"
                        >
                          I do not object to value(s).
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </>
          )}

        {!property.contendedSiteValue &&
          !property.contendedCapitalImprovedValue &&
          !property.contendedNetAnnualValue &&
          !property.objectorAcknowledgedNoObjection &&
          property.displayedAcceptenceCheck && (
            <>
              <MDBRow className="objecting-values-warning mb-3">
                <MDBCol>
                  <MDBRow>
                    <MDBCol>
                    <div
                      className="cms-content"><h4><strong>You have not included any contended values.</strong></h4>
                      <p>You have not included any contended values. Providing contended values is encouraged, as this will assist in the determination of your objection.</p>
                    </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          aria-labelledby="accept-blank-values"
                          type="checkbox"
                          className="custom-control-input"
                          id="defaultUnchecked"
                          onChange={checkboxChanged}
                          name="objectorAcknowledgedNoValues"
                          checked={property.objectorAcknowledgedNoValues}
                        />

                        <label
                          id="label-accept-blank-values"
                          className={`custom-control-label ${property.blankValuesError ? "checkboxError" : ""
                            }`}
                          htmlFor="defaultUnchecked"
                        >
                          I have intentionally left the contended values blank.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </>
          )}


        {objectionCommentsText && (
          <>
            <MDBRow className="helper-text-background mb-3">
              <MDBCol>
                <div
                  className="cms-content"
                  dangerouslySetInnerHTML={{ __html: objectionCommentsText }}
                ></div>
              </MDBCol>
            </MDBRow>
          </>
        )}
        <MDBRow className="mb-3">
          <MDBCol>
            <MDBTextArea
              label="Objection comments"
              name="objectionComment"
              value={property.objectionComment}
              onChange={onTextAreaBlur}
              disabled={false}
              required={objectionDetailsHelper.objectionComment.required}
              hasError={objectionDetailsHelper.objectionComment.hasError}
            ></MDBTextArea>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <div className="float-left" id="backButton">
              <MDBBtn
                aria-labelledby="backButton"
                className="button-style"
                onClick={goToPropertyIdentification}
              >
                Back
              </MDBBtn>
            </div>

            <div className="float-right" id="nextButton">
              <MDBBtn
                aria-labelledby="nextButton"
                className="button-style"
                onClick={saveObjectionDetails}
              >
                Next
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
  //#endregion JSX Code
}

export default ObjectionDetails;
