//#region Imports
import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import constants from "../components/Constants";
import {
  removeItem,
  retrieveItem,
  storeItem,
} from "../components/Session/Session";
//#endregion Imports

//#region declarations
const CHANGE_EVENT = "change";
let _landingPageText = "";
let _propertyVerificationModalText = "";
let _objectingToValuesText = "";
let _objectionCommentsText = "";
let _propertyDetailsText = "";
let _salesEvidenceText = "";
let _objectingToValuesWarning = "";
let _councilRatesNotice = [];

// the ones below are being fetch all at once
let _objectionGroundsText = "";
let _reviewAndSubmitText = "";
let _objectorDetailsText = "";
let _propertyVerifyMainText = "";
let _propertyVerifyFooterText = "";

//#endregion declarations

class CommonStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback); // change is the event
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getLandingPageText() {
    if (_landingPageText) {
      return _landingPageText;
    }

    // check in session
    let textInSession = retrieveItem(constants.LANDING_PAGE_TEXT);
    if (textInSession) {
      _landingPageText = textInSession;
    }
    return _landingPageText;
  }

  getPropertyVerificationModalText() {
    if (
      _propertyVerificationModalText &&
      _propertyVerificationModalText !== ""
    ) {
      return _propertyVerificationModalText;
    }
    // check in session
    let textInSession = retrieveItem(
      constants.PROPERTY_VERIFICATION_MODAL_TEXT
    );
    if (textInSession && textInSession !== "") {
      _propertyVerificationModalText = textInSession;
    }
    return _propertyVerificationModalText;
  }

  getObjectingToValuesText() {
    if (_objectingToValuesText && _objectingToValuesText !== "") {
      return _objectingToValuesText;
    }

    // check in session
    let textInSession = retrieveItem(constants.OBJECTING_TO_VALUES_TEXT);
    if (textInSession && textInSession !== "") {
      _objectingToValuesText = textInSession;
    }
    return _objectingToValuesText;
  }

  getObjectionCommentsText() {
    if (_objectionCommentsText && _objectionCommentsText !== "") {
      return _objectionCommentsText;
    }

    // check in session
    let textInSession = retrieveItem(constants.OBJECTION_COMMENTS_TEXT);
    if (textInSession && textInSession !== "") {
      _objectionCommentsText = textInSession;
    }
    return _objectionCommentsText;
  }

  getPropertyDetailsText() {
    if (_propertyDetailsText && _propertyDetailsText !== "") {
      return _propertyDetailsText;
    }

    // check in session
    let textInSession = retrieveItem(constants.PROPERTY_DETAILS_TEXT);
    if (textInSession && textInSession !== "") {
      _propertyDetailsText = textInSession;
    }
    return _propertyDetailsText;
  }

  getSalesEvidenceText() {
    if (_salesEvidenceText && _salesEvidenceText !== "") {
      return _salesEvidenceText;
    }

    let textInSession = retrieveItem(constants.SALES_EVIDENCE_TEXT);
    if (textInSession && textInSession !== "") {
      _salesEvidenceText = textInSession;
    }
    return _salesEvidenceText;
  }

  getObjectingToValuesWarning() {
    if (_objectingToValuesWarning && _objectingToValuesWarning !== "") {
      return _objectingToValuesWarning;
    }

    let textInSession = retrieveItem(constants.OBJECTING_TO_VALUES_WARNING);
    if (textInSession && textInSession !== "") {
      _objectingToValuesWarning = textInSession;
    }
    return _objectingToValuesWarning;
  }

  getCouncilRatesNotice(councilId) {
    let councilRatesNotice = _councilRatesNotice.filter(
      (x) => x.councilId === councilId
    );
    if (councilRatesNotice && councilRatesNotice.length > 0) {
      // we will only return the html content part
      return _councilRatesNotice[0].councilRatesNotice;
    }

    // get the array of all councils rates notices from session
    let objectInSession = retrieveItem(constants.COUNCIL_RATES_NOTICE);

    // // check if we have item in array for this particular councilId
    if (objectInSession && objectInSession.length > 0) {
      let councilRatesNotice = objectInSession.filter(
        (x) => x.councilId === councilId
      );
      if (councilRatesNotice) {
        // assigning the entire object
        _councilRatesNotice = councilRatesNotice;
      }
    }

    // we will only return the html content part
    return _councilRatesNotice && _councilRatesNotice.length > 0
      ? _councilRatesNotice[0].councilRatesNotice
      : null;
  }

  setObjectionGroundsText(objectionGroundsText) {
    _objectionGroundsText = objectionGroundsText;
    storeItem(_objectionGroundsText, constants.OBJECTON_GROUNDS_TEXT);
  }

  getObjectionGroundsText() {
    if (_objectionGroundsText && _objectionGroundsText !== "") {
      return _objectionGroundsText;
    }

    let textInSession = retrieveItem(constants.OBJECTON_GROUNDS_TEXT);
    if (textInSession && textInSession !== "") {
      _objectionGroundsText = textInSession;
    }
    return _objectionGroundsText;
  }

  setReviewAndSubmitText(reviewAndSubmitText) {
    _reviewAndSubmitText = reviewAndSubmitText;
    storeItem(_reviewAndSubmitText, constants.REVIEW_AND_SUBMIT_TEXT);
  }

  getReviewAndSubmitText() {
    if (_reviewAndSubmitText && _reviewAndSubmitText !== "") {
      return _reviewAndSubmitText;
    }

    let textInSession = retrieveItem(constants.REVIEW_AND_SUBMIT_TEXT);
    if (textInSession && textInSession !== "") {
      _reviewAndSubmitText = textInSession;
    }

    return _reviewAndSubmitText;
  }

  setObjectorDetailsText(objectorDetailsText) {
    _objectorDetailsText = objectorDetailsText;
    storeItem(_objectorDetailsText, constants.OBJECTOR_DETAILS_TEXT);
  }

  getObjectorDetailsText() {
    if (_objectorDetailsText && _objectorDetailsText !== "") {
      return _objectorDetailsText;
    }

    let textInSession = retrieveItem(constants.OBJECTOR_DETAILS_TEXT);
    if (textInSession && textInSession !== "") {
      _objectorDetailsText = textInSession;
    }

    return _objectorDetailsText;
  }

  setPropertyVerifyMainText(propertyVerifyMainText) {
    _propertyVerifyMainText = propertyVerifyMainText;
    storeItem(_propertyVerifyMainText, constants.PROPERTY_VERIFY_MAIN_TEXT);
  }

  getPropertyVerifyMainText() {
    if (_propertyVerifyMainText && _propertyVerifyMainText !== "") {
      return _propertyVerifyMainText;
    }

    let textInSession = retrieveItem(constants.PROPERTY_VERIFY_MAIN_TEXT);
    if (textInSession && textInSession !== "") {
      _propertyVerifyMainText = textInSession;
    }

    return _propertyVerifyMainText;
  }

  setWarningBlankValues(objectingToValuesWarning) {
    _objectingToValuesWarning = objectingToValuesWarning;
    storeItem(_objectingToValuesWarning, constants.OBJECTING_TO_VALUES_WARNING);
  }

  setSalesEvidenceText(salesEvidenceText) {
    _salesEvidenceText = salesEvidenceText;
    storeItem(_salesEvidenceText, constants.SALES_EVIDENCE_TEXT);
  }

  setPropertyDetails(propertyDetailsText) {
    _propertyDetailsText = propertyDetailsText;
    storeItem(_propertyDetailsText, constants.PROPERTY_DETAILS_TEXT);
  }

  setObjectionComments(objectionComments) {
    _objectionCommentsText = objectionComments;
    storeItem(_objectionCommentsText, constants.OBJECTION_COMMENTS_TEXT);
  }

  setObjectingToValuesText(objectingToValuesText) {
    _objectingToValuesText = objectingToValuesText;
    storeItem(_objectingToValuesText, constants.OBJECTING_TO_VALUES_TEXT);
  }

  setPropertyVerificationModalText(propertyVerificationModalText) {
    _propertyVerificationModalText = propertyVerificationModalText;
    storeItem(
      _propertyVerificationModalText,
      constants.PROPERTY_VERIFICATION_MODAL_TEXT
    );
  }

  setPropertyVerifyFooterText(propertyVerifyFooterText) {
    _propertyVerifyFooterText = propertyVerifyFooterText;
    storeItem(_propertyVerifyFooterText, constants.PROPERTY_VERIFY_FOOTER_TEXT);
  }

  getPropertyVerifyFooterText() {
    if (_propertyVerifyFooterText && _propertyVerifyFooterText !== "") {
      return _propertyVerifyFooterText;
    }

    let textInSession = retrieveItem(constants.PROPERTY_VERIFY_FOOTER_TEXT);
    if (textInSession && textInSession !== "") {
      _propertyVerifyFooterText = textInSession;
    }

    return _propertyVerifyFooterText;
  }
}

// we need to instantiate the store
const store = new CommonStore();

// TODO The calls to removeItem and storeItem are about storing values in the session storage - nothing to
// do with the Redux store. The reducer shouldn't have side effects like this.

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.SET_LANDING_PAGE_TEXT:
      _landingPageText = action.landingPageText;

      removeItem(constants.LANDING_PAGE_TEXT);
      storeItem(_landingPageText, constants.LANDING_PAGE_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_PROPERTY_VERIFICATION_MODAL_TEXT:
      _propertyVerificationModalText = action.propertyVerificationModalText;

      removeItem(constants.PROPERTY_VERIFICATION_MODAL_TEXT);
      storeItem(
        _propertyVerificationModalText,
        constants.PROPERTY_VERIFICATION_MODAL_TEXT
      );
      store.emitChange();
      break;

    case actionTypes.SET_OBJECTING_TO_VALUES_TEXT:
      _objectingToValuesText = action.objectingToValuesText;

      removeItem(constants.OBJECTING_TO_VALUES_TEXT);
      storeItem(_objectingToValuesText, constants.OBJECTING_TO_VALUES_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_OBJECTION_COMMENTS_TEXT:
      _objectionCommentsText = action.objectionCommentsText;

      removeItem(constants.OBJECTION_COMMENTS_TEXT);
      storeItem(_objectionCommentsText, constants.OBJECTION_COMMENTS_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_PROPERTY_DETAILS_TEXT:
      _propertyDetailsText = action.propertyDetailsText;

      removeItem(constants.PROPERTY_DETAILS_TEXT);
      storeItem(_propertyDetailsText, constants.PROPERTY_DETAILS_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_SALES_EVIDENCE_TEXT:
      _salesEvidenceText = action.salesEvidenceText;

      removeItem(constants.SALES_EVIDENCE_TEXT);
      storeItem(_salesEvidenceText, constants.SALES_EVIDENCE_TEXT);
      store.emitChange();
      break;

    case actionTypes.SET_OBJECTING_TO_VALUES_WARNING:
      _objectingToValuesWarning = action.objectingToValuesWarning;

      removeItem(constants.OBJECTING_TO_VALUES_WARNING);
      storeItem(
        _objectingToValuesWarning,
        constants.OBJECTING_TO_VALUES_WARNING
      );
      store.emitChange();
      break;

    case actionTypes.SET_COUNCIL_RATES_NOTICE:
      _councilRatesNotice = _councilRatesNotice.filter(
        (x) => x.councilId !== action.councilId
      );
      _councilRatesNotice.push({
        councilId: action.councilId.toString(),
        councilRatesNotice: action.councilRatesNotice,
      });

      removeItem(constants.COUNCIL_RATES_NOTICE);
      storeItem(_councilRatesNotice, constants.COUNCIL_RATES_NOTICE);
      store.emitChange();
      break;

    default:
      break;
  }
});

export default store;
