export function isEmailLink(props) {
  if (props && props.location && props.location.search) {
    let queryParams = props.location.search.split("?");
    if (queryParams && queryParams.length > 1) {
      // means we have query parameters
      if (queryParams[1].indexOf("objectorId") > -1) {
        let objector = queryParams[1].split("=");
        if (objector && objector.length > 1) {
          return objector[1].toString();
        }
      }
    }
  }
  return null;
}
