import React from "react";
import TextField from "@material-ui/core/TextField";

function MaterialInputText(props) {
  const textfield = {
    width: "100%",
  };

  return (
    <TextField
      InputLabelProps={{
        id: props.name + "-label",
      }}
      aria-label={props.label}
      style={textfield}
      id={props.id}
      inputProps={{
        "aria-labelledby": props.name + "-label",
        maxLength:  props.maxLength || 100
      }}
      name={props.name}
      label={props.label}
      helperText={props.helperText}
      required={
        props.required === "required" || props.required === true ? true : false
      }
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      error={props.hasError}
      onBlur={props.onBlur}
      hidden={props.hidden ? props.hidden : false}
    />
  );
}

export default MaterialInputText;
