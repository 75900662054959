import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import PropertyStore from "../../stores/propertyStore";
import ObjectorStore from "../../stores/objectorStore";
import { getNamefromList } from "../../utilities/getNameFromList";
import Councils from "../../Lists/Councils";
import RespondentTypes from "../../Lists/RespondentTypes";
import Professions from "../../Lists/Professions";
import ObjectionReasons from "../../Lists/ObjectionReasons";
import { startLoader, stopLoader } from "../../actions/applicationActions";
import * as PropertyActions from "../../actions/propertyActions";
import SalesEvidenceStore from "../../stores/salesEvidenceStore";
import Steppers from "../../controls/Steppers/Steppers";
import ReviewPropertyDetails from "./ReviewPropertyDetails/ReviewPropertyDetails";
import ReviewLeaseDetails from "./ReviewLeaseDetails/ReviewLeaseDetails";
import ReviewSalesEvidence from "./ReviewSalesEvidence/ReviewSalesEvidence";
import MobileLinearProgress from "../../controls/MobileLinearProgress/MobileLinearProgress";
import { isEmailLink } from "../../utilities/isEmailLink";
import CommonStore from "../../stores/commonStore";
import Alert from  "@material-ui/lab/Alert";
import { ScrollTo } from "../../utilities/focusErrorControl";
import "./ReviewSubmission.scss";

function ReviewSubmission(props) {
  
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }

  //#region verifying if the loading of this component was using
  // routing or Did user entered a URL and opened this page

  var objector = ObjectorStore.getObjector();
  var propertyId = null;
  if (!objector || !objector.objectorId) {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ObjectorDetails",
      });
  } else {
    // we came here means, we do have an Objector in flux / session
    // now check if we have a propertyId
    if (props && props.location && props.location.propertyId && props.location.propertyId !== '0') {
      propertyId = props.location.propertyId;
    } else {
      props &&
        props.history &&
        props.history.push({
          pathname: "/PropertyIdentification",
          objectorId: objector.objectorId,
        });
    }
  }
  //#endregion verifying the loading of this component
  const [errorMessage, setErrorMessage] = useState(null);
  const RESPONDENT_TYPE_AGENT = "Agent";
  const [agreed, setAgreed] = useState(false);
  //const [objector] = useState(ObjectorStore.getObjector());
  const [property, setProperty] = useState(
    PropertyStore.getPropertyById(propertyId)
  );
  const [salesEvidences, setSalesEvidences] = useState(
    SalesEvidenceStore.getSalesEvidenceByPropertyId(property.propertyId)
  );
  const [reviewAndSubmitText] = useState(CommonStore.getReviewAndSubmitText());
  //#endregion component declarations

  //#region useEffect
  useEffect(() => {
    PropertyStore.addChangeListener(onchange);

    document.title = "Object to rating valuation - Review and submit";

    function onchange() {
      PropertyStore.getPropertyById(property.propertyId);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  

    return () => PropertyStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, [property.propertyId]);

  useEffect(() => {
    SalesEvidenceStore.addChangeListener(onchange);

    function onchange() {
      setSalesEvidences(
        SalesEvidenceStore.getSalesEvidenceByPropertyId(property.propertyId)
      );
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  

    return () => SalesEvidenceStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, [property.propertyId]);
  //#endregion useEffect

  function handleSubmit() {
    const GENERAL_ERROR = 'Objection lodgement failed. Click Back then Next to reload the step and try again. If the problem persists, contact the council on your notice and lodge your objection directly with the rates team.';
    
    startLoader();

    PropertyActions.submitProperty(property).then((response) => {
      if (response && response.errors && response.errors.length > 0) {
        showError(GENERAL_ERROR);
        stopLoader();
        return;
      } else {
        setTimeout(() => {
          stopLoader();
          setProperty({ ...property, "status": "submitted" });
        }, 100);
      }
    }, (error) => {  
      showError(GENERAL_ERROR);
      stopLoader();
      return;
    });
  }

  function showError(message) {
    setErrorMessage(message);
    let element = document.getElementById("submissionError");
    if (element) {
      ScrollTo(element);
    }
  }

  function checkboxChanged() {
    setAgreed(!agreed);
  }

  function goToSalesEvidence() {
    props &&
      props.history &&
      props.history.push({
        pathname: "/SaleEvidence",
        propertyId: property.propertyId,
        objectorId: objector.objectorId,
      });
  }

  function goToPropertyPage() {
    PropertyActions.dispatchUpdateProperty(property);
    setTimeout(() => {
      props &&
        props.history &&
        props.history.push({
          pathname: "/PropertyIdentification",
          objectorId: objector.objectorId,
        });
    }, 100);
  }

  function getContendedValueText(prop, value){
    if(prop !== true)
      return "No objection"
    else if (value !== null && value !== "")
      return "$" + value;
    else 
      return "No value contended"  
  }
  //#endregion Modal handler

  return (
    <>
      <MDBContainer className="review-page-font">
        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <h4>{property.address}</h4>
            <h5>{getNamefromList(Councils, property.councilId)}</h5>
            <h5>Assessment number: {property.assessmentNumber}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3 mb-3">
          <MDBCol className="d-none d-md-block stepper-aligned">
            <Steppers location={props.location} />
          </MDBCol>
          <MDBCol className="d-block d-md-none stepper-aligned">
            <MobileLinearProgress
              location={props.location}
            ></MobileLinearProgress>
          </MDBCol>
        </MDBRow>

        {reviewAndSubmitText && (
          <>
            <MDBRow className="helper-text-background mb-3">
              <MDBCol>
                <div
                  className="cms-content"
                  dangerouslySetInnerHTML={{ __html: reviewAndSubmitText }}
                ></div>
              </MDBCol>
            </MDBRow>
          </>
        )}

        <MDBRow>
          <MDBCol xl="12" lg="12" md="12" sm="12" className="mb-3">
            <div id="submissionError"> 
            { errorMessage && 
              <Alert severity="error">
                { errorMessage }
              </Alert>
            }
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol className="review-section-headings mb-3">
            Objector details
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Objector</span>
              <span className="review-page-field-value">
                {objector.firstName + " " + objector.lastName}
              </span>
            </div>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Postal address</span>
              <span className="review-page-field-value">
                {`${objector.postalAddress} ${objector.suburb} ${objector.state} ${objector.postcode}`}
              </span>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Respondent type</span>
              <span className="review-page-field-value">
                {getNamefromList(RespondentTypes, objector.respondantType)}
              </span>
            </div>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Email</span>
              <span className="review-page-field-value">{objector.email}</span>
            </div>
          </MDBCol>
        </MDBRow>

        {objector.respondantType === RESPONDENT_TYPE_AGENT && (
          <>
            <MDBRow>
              {objector.professionId !== "-1" &&
                objector.professionId !== "" &&
                objector.professionId !== null && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Profession
                      </span>
                      <span className="review-page-field-value">
                        {getNamefromList(Professions, objector.professionId)}
                      </span>
                    </div>
                  </MDBCol>
                )}
              {objector.companyName !== "" && objector.companyName !== null && (
                <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                  <div className="review-page-field">
                    <span className="review-page-field-heading">
                      Company name
                    </span>
                    <span className="review-page-field-value">
                      {objector.companyName}
                    </span>
                  </div>
                </MDBCol>
              )}
            </MDBRow>
          </>
        )}
        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">Phone</span>
              <span className="review-page-field-value">{objector.mobile}</span>
            </div>
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6"></MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol className="review-section-headings mb-3">
            Grounds for objection
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
            <div className="review-page-field">
              <span className="review-page-field-heading">
                Objection grounds
              </span>
              <span className="review-page-field-value">
                {getNamefromList(ObjectionReasons, property.objectionReasonId)}
              </span>
            </div>
          </MDBCol>

          {property.objectionComment !== null &&
            property.objectionComment !== "" && (
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <div className="review-page-field">
                  <span className="review-page-field-heading">
                    Objection comments
                  </span>
                  <span className="review-page-field-value">
                    {property.objectionComment}
                  </span>
                </div>
              </MDBCol>
            )}
        </MDBRow>

        {((property.siteValue !== null &&
          property.siteValue !== "" &&
          property.siteValue !== "0") ||
          (property.contendedSiteValue !== null &&
            property.contendedSiteValue !== "")) && (
          <>
            <MDBRow>
              {property.siteValue !== null &&
                property.siteValue !== "" &&
                property.siteValue !== "0" && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                  <div className="custom-control custom-checkbox">
                      <input
                        aria-labelledby="do-not-object"
                        type="checkbox"
                        className="custom-control-checkbox"
                        id="isSiteValueObjected"
                        name="isSiteValueObjected"
                        checked={property.isSiteValueObjected}
                        disabled
                      />
                      <label
                        id="label-accept-blank-values"
                        htmlFor="isSiteValueObjected"
                      >
                        Site Value
                      </label>
                      <div className="review-page-field-value">
                        {"$" + property.siteValue}
                      </div>        
                    </div>
                  </MDBCol>
                )}
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <div className="review-page-field">
                  <span className="review-page-field-heading">Contended site value</span>
                  <span className="review-page-field-value">
                    {
                      getContendedValueText(property.isSiteValueObjected, property.contendedSiteValue)
                    }
                  </span>
                </div>
              </MDBCol>
            </MDBRow>
          </>
        )}

        {((property.capitalImprovedValue !== null &&
          property.capitalImprovedValue !== "" &&
          property.capitalImprovedValue !== "0") ||
          (property.contendedCapitalImprovedValue !== null &&
            property.contendedCapitalImprovedValue !== "")) && (
          <>
            <MDBRow>
              {property.capitalImprovedValue !== null &&
                property.capitalImprovedValue !== "" &&
                property.capitalImprovedValue !== "0" && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                  <div className="custom-control custom-checkbox">
                      <input
                        aria-labelledby="do-not-object"
                        type="checkbox"
                        className="custom-control-checkbox"
                        id="isCapitalImprovedValueObjected"
                        name="isCapitalImprovedValueObjected"
                        checked={property.isCapitalImprovedValueObjected}
                        disabled
                      />
                      <label
                        id="label-accept-blank-values"
                        htmlFor="isCapitalImprovedValueObjected"
                      >
                        Capital Improved Value
                      </label>
                      <div className="review-page-field-value">
                        {"$" + property.capitalImprovedValue}
                    </div>
                    </div>
                  </MDBCol>
                )}
              
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <div className="review-page-field">
                  <span className="review-page-field-heading">Contended capital improved Value</span>
                  <span className="review-page-field-value">
                    {
                      getContendedValueText(property.isCapitalImprovedValueObjected, property.contendedCapitalImprovedValue)
                    }
                  </span>
                </div>
              </MDBCol>
            </MDBRow>
          </>
        )}

        {((property.netAnnualValue !== null &&
          property.netAnnualValue !== "" &&
          property.netAnnualValue !== "0") ||
          (property.contendedNetAnnualValue !== null &&
            property.contendedNetAnnualValue !== "")) && (
          <>
            <MDBRow>
              {property.netAnnualValue !== null &&
                property.netAnnualValue !== "" &&
                property.netAnnualValue !== "0" && (
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                   <div className="custom-control custom-checkbox">
                        <input
                          aria-labelledby="do-not-object"
                          type="checkbox"
                          className="custom-control-checkbox"
                          id="isNetAnnualValueObjected"
                          name="isNetAnnualValueObjected"
                          checked={property.isNetAnnualValueObjected}
                          disabled
                        />
                        <label
                          id="label-accept-blank-values"
                          htmlFor="isNetAnnualValueObjected"
                        >
                          Net Annual Value
                        </label>
                    <div className="review-page-field-value">
                        {"$" + property.netAnnualValue}
                    </div>
                    </div>
                  </MDBCol>
                )}
              

              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <div className="review-page-field">
                  <span className="review-page-field-heading">Contended net annual value</span>
                  <span className="review-page-field-value">
                    {
                      getContendedValueText(property.isNetAnnualValueObjected, property.contendedNetAnnualValue)
                    }
                  </span>
                </div>
              </MDBCol>
            </MDBRow>

            {property.objectorAcknowledgedNoValues && 
              <MDBRow className="mt-3 mb-3">
                <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      aria-labelledby="objectorAcknowledgedNoValues"
                      type="checkbox"
                      className="custom-control-checkbox"
                      id="objectorAcknowledgedNoValuesId"
                      name="objectorAcknowledgedNoValuesId"
                      checked={property.objectorAcknowledgedNoValues}
                      disabled
                    />
                    <input
                      aria-labelledby="do-not-object"
                      type="checkbox"
                      className="custom-control-checkbox"
                      id="isNetAnnualValueObjected"
                      name="isNetAnnualValueObjected"
                      checked={property.isNetAnnualValueObjected}
                      disabled
                    />
                  
                    <label
                      id="objectorAcknowledgedNoValueslabelId"
                      aria-label="objectorAcknowledgedNoValues"
                      htmlFor="objectorAcknowledgedNoValuesId"
                    >
                      I have intentionally left the contended values blank. 
                    </label>
                  </div>
                </MDBCol>
            </MDBRow>
            }

            {property.objectorAcknowledgedNoObjection &&
              <MDBRow className="mt-3 mb-3">
                <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      disabled
                      className="custom-control-checkbox"
                      id="objectorAcknowledgedNoObjectionId"
                      checked={property.objectorAcknowledgedNoObjection}
                      aria-labelledby="objectorAcknowledgedNoObjection"
                    />
                    <label
                      id="objectorAcknowledgedNoObjectionId"
                      aria-label="objectorAcknowledgedNoObjection"
                      htmlFor="objectorAcknowledgedNoObjectionId"
                    >
                      I do not object to any values.
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
            }

          </>
        )}

        <ReviewPropertyDetails property={property}></ReviewPropertyDetails>

        <ReviewLeaseDetails property={property}></ReviewLeaseDetails>

        <ReviewSalesEvidence
          salesEvidences={salesEvidences}
          landAreaUnitID={property.landAreaUnitID}
        ></ReviewSalesEvidence>

        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="defaultUnchecked"
                onChange={checkboxChanged}
                checked={agreed}
                aria-labelledby="objection-terms-agreed"
                disabled={property.status === "submitted" ? true : false}
              />
              <label
                id="objection-terms-agreed"
                aria-label="objection-terms-agreed"
                className="custom-control-label"
                htmlFor="defaultUnchecked"
              >
                I agree that by submitting this information, I may be contacted
                by valuation personnel from the rating authority, or contract
                valuers regarding this objection application.
              </label>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol hidden={property.status === "submitted" ? true : false}>
            <div className="float-left" id="backButton">
              <MDBBtn
                aria-labelledby="backButton"
                className="button-style"
                onClick={goToSalesEvidence}
              >
                Back
              </MDBBtn>
            </div>

            <div
              className="float-right"
              id="submitButton"
              hidden={property.status === "submitted" ? true : false}
            >
              <MDBBtn
                aria-labelledby="submitButton"
                className="button-style"
                disabled={!agreed}
                onClick={handleSubmit}
              >
                Submit
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="centered-content mt-3 mb-3">
          <MDBCol hidden={property.status !== "submitted" ? true : false}>
            Your objection has been submitted successfully.
          </MDBCol>
        </MDBRow>

        <MDBRow className="centered-content mt-3 mb-3">
          <MDBCol hidden={property.status !== "submitted" ? true : false}>
            <div id="backToPropertyPage">
              <MDBBtn
                aria-labelledby="backToPropertyPage"
                className="button-style"
                onClick={goToPropertyPage}
              >
                Add another property
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default ReviewSubmission;
