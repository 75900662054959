import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";

export function startLoader() {
  dispatcher.dispatch({
    actionType: actionTypes.START_LOADER,
    isLoading: true,
  });
}

export function stopLoader() {
  dispatcher.dispatch({
    actionType: actionTypes.START_LOADER,
    isLoading: false,
  });
}

export function showModal(modalProps) {
  dispatcher.dispatch({
    actionType: actionTypes.SHOW_MODAL,
    modalProps: modalProps,
  });
}

export function hideModal(modalProps) {
  dispatcher.dispatch({
    actionType: actionTypes.HIDE_MODAL,
    modalProps: modalProps,
  });
}
