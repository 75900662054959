const Councils = [
  { id: "-1", name: "Select the council from your rate notice" },
  { id: "300", name: "Alpine Shire Council" },
  { id: "301", name: "Ararat Rural City Council" },
  { id: "302", name: "Ballarat City Council" },
  { id: "303", name: "Banyule City Council" },
  { id: "304", name: "Bass Coast Shire Council" },
  { id: "305", name: "Baw Baw Shire Council" },
  { id: "306", name: "Bayside City Council" },
  { id: "381", name: "Benalla Rural City Council" },
  { id: "307", name: "Boroondara City Council" },
  { id: "360", name: "Borough of Queenscliffe" },
  { id: "308", name: "Brimbank City Council" },
  { id: "309", name: "Buloke Shire Council" },
  { id: "310", name: "Campaspe Shire Council" },
  { id: "311", name: "Cardinia Shire Council" },
  { id: "312", name: "Casey City Council" },
  { id: "313", name: "Central Goldfields Shire Council" },
  { id: "327", name: "City of Greater Geelong" },
  { id: "363", name: "City of Stonnington " },
  { id: "376", name: "City of Yarra " },
  { id: "314", name: "Colac Otway Shire Council" },
  { id: "315", name: "Corangamite Shire Council" },
  { id: "316", name: "Darebin City Council" },
  { id: "319", name: "East Gippsland Shire Council" },
  { id: "320", name: "Frankston City Council" },
  { id: "321", name: "Gannawarra Shire Council" },
  { id: "322", name: "Glen Eira City Council" },
  { id: "323", name: "Glenelg Shire Council" },
  { id: "324", name: "Golden Plains Shire Council" },
  { id: "325", name: "Greater Bendigo City Council" },
  { id: "326", name: "Greater Dandenong City Council" },
  { id: "329", name: "Hepburn Shire Council" },
  { id: "330", name: "Hindmarsh Shire Council" },
  { id: "331", name: "Hobsons Bay City Council" },
  { id: "332", name: "Horsham Rural City Council" },
  { id: "333", name: "Hume City Council" },
  { id: "334", name: "Indigo Shire Council" },
  { id: "335", name: "Kingston City Council" },
  { id: "336", name: "Knox City Council" },
  { id: "337", name: "Latrobe City Council" },
  { id: "338", name: "Loddon Shire Council" },
  { id: "339", name: "Macedon Ranges Shire Council" },
  { id: "340", name: "Manningham City Council" },
  { id: "382", name: "Mansfield Shire Council" },
  { id: "341", name: "Maribyrnong City Council" },
  { id: "342", name: "Maroondah City Council" },
  { id: "343", name: "Melbourne City Council" },
  { id: "344", name: "Melton City Council" },
  { id: "351", name: "Merri-bek City Council" },
  { id: "345", name: "Mildura Rural City Council" },
  { id: "346", name: "Mitchell Shire Council" },
  { id: "347", name: "Moira Shire Council" },
  { id: "348", name: "Monash City Council" },
  { id: "349", name: "Moonee Valley City Council" },
  { id: "350", name: "Moorabool Shire Council" },
  { id: "352", name: "Mornington Peninsula Shire Council" },
  { id: "353", name: "Mount Alexander Shire Council" },
  { id: "354", name: "Moyne Shire Council" },
  { id: "355", name: "Murrindindi Shire Council" },
  { id: "356", name: "Nillumbik Shire Council" },
  { id: "357", name: "Northern Grampians Shire Council" },
  { id: "358", name: "Port Phillip City Council" },
  { id: "359", name: "Pyrenees Shire Council" },
  { id: "361", name: "South Gippsland Shire Council" },
  { id: "362", name: "Southern Grampians Shire Council" },
  { id: "364", name: "Strathbogie Shire Council" },
  { id: "365", name: "Surf Coast Shire Council" },
  { id: "366", name: "Swan Hill Rural City Council" },
  { id: "367", name: "Towong Shire Council" },
  { id: "368", name: "Wangaratta Rural City Council" },
  { id: "369", name: "Warrnambool City Council" },
  { id: "370", name: "Wellington Shire Council" },
  { id: "371", name: "West Wimmera Shire Council" },
  { id: "372", name: "Whitehorse City Council" },
  { id: "373", name: "Whittlesea City Council" },
  { id: "374", name: "Wodonga City Council" },
  { id: "375", name: "Wyndham City Council" },
  { id: "377", name: "Yarra Ranges Shire Council" },
  { id: "378", name: "Yarriambiack Shire Council" },
];

export default Councils;