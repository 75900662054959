import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function MaterialAutoComplete(props) {
  const autoCompletefield = {
    width: "100%",
  };

  return (
    <Autocomplete
      options={props.list}
      getOptionLabel={(option) => option.name}
      defaultValue={props.list[props.defaultValue]}
      style={autoCompletefield}
      onChange={props.onChange}
      id={props.id}
      name={props.name}
      formHelperText={""}
      helperText={""}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={props.label}
          margin="normal"
        />
      )}
    />
  );
}

export default MaterialAutoComplete;
