import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import * as Session from "../Session/Session";
import * as AttachmentActions from "../../actions/attachmentsActions";
import * as BreadcrumbActions from "../../actions/breadcrumbActions";
import * as PropertyActions from "../../actions/propertyActions";
import * as SalesEvidenceActions from "../../actions/salesEvidenceActions";
import * as CommonActions from "../../actions/commonActions";
import CommonStore from "../../stores/commonStore";
import "./HomePage.scss";

function HomePage(props) {
  const [landingPageText, setLandingPageText] = useState(
    CommonStore.getLandingPageText()
  );

  //#region useEffect State Hook
  useEffect(() => {
    document.title = "Object to rating valuation - Home";

    Session.clearAll();

    // we also need to clear the flux store
    AttachmentActions.initialiseAttachment();
    PropertyActions.initialiseProperty();
    SalesEvidenceActions.initialiseSalesEvidence();

    // check if the landingPageText already has the CMS data
    // when first time Homepage is loaded, landingPageText would
    // be empty, so it will call the Action.getLandingPageText()
    // all times afterwards, landingPageText would have data from session
    if (!landingPageText) {
      CommonActions.getLandingPageText().then((response) => {
        setLandingPageText(response);
        CommonActions.dispatchLandingPageText(response);
      });
    }

    CommonActions.getCouncilRatesNotice("0");
    CommonActions.getHelperTexts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion useEffect State Hook

  function goToObjectorDetails() {
    props.history.push({
      pathname: "/ObjectorDetails",
    });
    BreadcrumbActions.isRouting();
  }

  return (
    <>
      <MDBContainer>
        {landingPageText && (
          <>
            <MDBRow className="mt-3 mb-3">
              <MDBCol>
                <div
                  id="home-page-cms-label"
                  className="d-none d-lg-block cms-content"
                  dangerouslySetInnerHTML={{ __html: landingPageText }}
                ></div>
                <div
                  id="home-page-cms-label"
                  className="d-block d-lg-none home-page cms-content"
                  dangerouslySetInnerHTML={{ __html: landingPageText }}
                />
              </MDBCol>
            </MDBRow>
          </>
        )}
        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <div className="float-right">
              <MDBBtn
                aria-labelledby="home-page-cms-label"
                onClick={goToObjectorDetails}
                className="button-style"
              >
                Begin
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default HomePage;
