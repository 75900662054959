import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

function MaterialSelect(props) {
  const textfield = {
    width: "100%",
  };
  return (
    <>
      <FormControl
        error={props.hasError}
        required={props.hasError}
        style={textfield}
      >
        <InputLabel shrink required={props.hasError} id={props.name + "-label"}>
          {props.inputLabel}
        </InputLabel>
        <Select
          inputProps={{
            "aria-labelledby": props.name + "-label",
          }}
          id={props.selectId}
          name={props.name}
          value={props.value}
          onChange={props.onSelectChange}
          disabled={props.disabled}
          required={props.required}
          error={props.hasError}
          onBlur={props.onBlur}
        >
          {props.list.map((value, index) => {
            return (
              <MenuItem key={index} value={value.id}>
                {value.name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{props.formHelperText}</FormHelperText>
      </FormControl>
    </>
  );
}

export default MaterialSelect;
