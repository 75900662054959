import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import { getRequest, saveRequest } from "../api/apiRequest";
import * as PropertyActions from "../actions/propertyActions";
import * as AttachmentActions from "../actions/attachmentsActions";
import * as SalesEvidenceActions from "../actions/salesEvidenceActions";
import { getNamefromList } from "../utilities/getNameFromList";
import professions from "../Lists/Professions";

export function getObjectorById(objectorId) {
  if (!objectorId) {
    return null;
  }

  let apiUrl = "/objector/" + encodeURIComponent(objectorId);
  return getRequest(apiUrl, false).then((objector) => {
    if (objector) {
      dispatchSaveObjector(objector);

      if (objector.property && objector.property.length > 0) {
        // adding objectorId to all the properties
        objector.property.forEach((property) => {
          property.objectorId = objectorId;
          // TODO: Backend api is not returning this field
          property.property_code = "0";

          // store all attachments in flux store
          if (property.attachment && property.attachment.length > 0) {
            property.attachment.forEach((attachment) => {
              attachment.propertyId = property.propertyId;
            });
            AttachmentActions.dispatchSaveAttachments(property.attachment);
          }
          // store all sales evidence in flux store
          if (property.salesEvidence && property.salesEvidence.length > 0) {
            property.salesEvidence.forEach((sale) => {
              sale.propertyId = property.propertyId;
              SalesEvidenceActions.dispatchUpdateSalesEvidence(sale);
            });
          }
        });
        // updating the flux store which will inturn update the cache
        PropertyActions.dispatchUpdateProperties(objectorId, objector.property);
      }
    }
  });
}

export function saveObjector(objector) {
  let apiUrl = "/objector/";
  let objectorId = objector.objectorId;

  objector.profession = getNamefromList(professions, objector.professionId);

  objector.sessionUrl = window.location.origin;

  let parameters = {};
  Object.assign(parameters, objector);
  // for both PUT and PUT objectorId should not be in the body
  delete parameters.objectorId;

  let method = "POST";
  if (objectorId && objectorId !== "") {
    apiUrl = apiUrl + encodeURIComponent(objectorId);
    method = "PUT";
  }

  return saveRequest(apiUrl, method, parameters);
}

export function dispatchSaveObjector(objector) {
  dispatcher.dispatch({
    actionType: actionTypes.SAVE_OBJECTOR,
    objector: objector,
  });
}

export function dispatchUpdateObjectorHelper(objectorHelper) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_OBJECTOR_HELPER,
    objectorHelper: objectorHelper,
  });
}

export function updateValidateObjectorHelper(validateObjectorHelper) {
  dispatcher.dispatch({
    actionType: actionTypes.UPDATE_VALIDATE_OBJECTOR_HELPER,
    validateObjectorHelper: validateObjectorHelper,
  });
}

export function validateObjector(objectorId, councilId, assessmentNumber) {
  let apiUrl =
    "/objector/" +
    encodeURIComponent(objectorId) +
    "/propertymatch?councilid=" +
    encodeURIComponent(councilId.toString()) +
    "&assessmentnumber=" +
    encodeURIComponent(assessmentNumber.toString());

  return getRequest(apiUrl, false).then((response) => {
    return response;
  });
}
