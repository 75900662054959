import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";

const CHANGE_EVENT = "change";

let _application = {
  isLoading: false,
};

let _modal = {
  showModal: false,
  header: "",
  message: "",
};

class ApplicationStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback); // change is the event
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getApplicationStore() {
    return _application;
  }

  getModal() {
    return _modal;
  }
}

const store = new ApplicationStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.START_LOADER:
      _application.isLoading = action.isLoading;
      store.emitChange();
      break;

    case actionTypes.STOP_LOADER:
      _application.isLoading = action.isLoading;
      store.emitChange();
      break;

    case actionTypes.SHOW_MODAL:
      _modal = action.modalProps;
      store.emitChange();
      break;

    case actionTypes.HIDE_MODAL:
      _modal = action.modalProps;
      store.emitChange();
      break;

    default:
      break;
  }
});

export default store;
