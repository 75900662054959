//#region imports
import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import reviewBasis from "../../../Lists/ReviewBasis";
import reviewFrequencies from "../../../Lists/ReviewFrequencies";
import MDBTextArea from "../../../controls/MDBReact-InputTextArea";
import MaterialInputText from "../../../controls/MaterialUI-InputText";
import leaseTerms from "../../../Lists/LeaseTerms";
import MaterialSelect from "../../../controls/MaterialUI-Select";
import MaterialInputDate from "../../../controls/MaterialUI-InputDate";
import MaterialInputCurrency from "../../../controls/MaterialUI-InputCurrency";
//#endregion imports

function PropertyLeaseDetails(props) {
  const PropertyType_Commercial = "2";
  const PropertyType_Industrial = "3";
  const PropertyType_Rural = "1";
  const property = props.property;

  return (
    <>
      <MDBContainer>
        {(property.propertyTypeId === PropertyType_Commercial ||
          property.propertyTypeId === PropertyType_Industrial ||
          property.propertyTypeId === PropertyType_Rural) && (
          <>
            <MDBRow className="page-sub-headings mb-3">
              <MDBCol>Lease Details</MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol>
                If there is an active lease on the property, please detail
                below:
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialSelect
                  inputLabel="Lease term"
                  name="leaseTerm"
                  value={props.property.leaseTerm}
                  onSelectChange={props.handleChange}
                  list={leaseTerms}
                  formHelperText={props.helper.leaseTerm.errorText}
                  disabled={false}
                  required={props.helper.leaseTerm.required}
                  hasError={props.helper.leaseTerm.hasError}
                  onBlur={props.onBlur}
                ></MaterialSelect>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputCurrency
                  label="Gross rent per year ($)"
                  name="leaseAmountPA"
                  value={props.property.leaseAmountPA}
                  onChange={props.handleChange}
                  disabled={false}
                  helperText={props.helper.leaseAmountPA.errorText}
                  required={props.helper.leaseAmountPA.required}
                  hasError={props.helper.leaseAmountPA.hasError}
                  onBlur={props.onBlur}
                ></MaterialInputCurrency>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol
                xl="6"
                lg="6"
                md="6"
                sm="6"
                xs="6"
                className="mb-3 date-two-column"
              >
                <MaterialInputDate
                  id="leaseCommencedId"
                  name="leaseCommenced"
                  label="Lease commenced"
                  value={props.property.leaseCommenced}
                  onChange={(date) => {
                    props.onDateChange(date, "leaseCommenced");
                  }}
                  helperText={props.helper.leaseCommenced.errorText}
                  required={props.helper.leaseCommenced.required}
                  hasError={props.helper.leaseCommenced.hasError}
                />
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialSelect
                  inputLabel="Review frequency"
                  name="reviewFrequency"
                  value={props.property.reviewFrequency}
                  onSelectChange={props.handleChange}
                  list={reviewFrequencies}
                  formHelperText={props.helper.reviewFrequency.errorText}
                  disabled={false}
                  required={props.helper.reviewFrequency.required}
                  hasError={props.helper.reviewFrequency.hasError}
                  onBlur={props.onBlur}
                ></MaterialSelect>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialSelect
                  inputLabel="Review basis"
                  name="reviewBasis"
                  value={props.property.reviewBasis}
                  onSelectChange={props.handleChange}
                  list={reviewBasis}
                  formHelperText={props.helper.reviewBasis.errorText}
                  disabled={false}
                  required={props.helper.reviewBasis.required}
                  hasError={props.helper.reviewBasis.hasError}
                  onBlur={props.onBlur}
                ></MaterialSelect>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                <MaterialInputText
                  label="Options"
                  name="options"
                  value={props.property.options}
                  onChange={props.handleChange}
                  disabled={false}
                  required={props.helper.options.required}
                  hasError={props.helper.options.hasError}
                  helperText={props.helper.options.errorText}
                  onBlur={props.onBlur}
                ></MaterialInputText>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="helper-text-background mb-3">
                Please provide any extra information regarding the lease:
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol>
                <MDBTextArea
                  label="Lease detail"
                  name="reviewDetail"
                  value={props.property.reviewDetail}
                  onChange={props.handleChange}
                  disabled={false}
                  required={props.helper.reviewDetail.required}
                  hasError={props.helper.reviewDetail.hasError}
                  helperText={props.helper.reviewDetail.errorText}
                ></MDBTextArea>
              </MDBCol>
            </MDBRow>
            <MDBRow className="page-sub-headings mb-3">
              <MDBCol>Outgoings</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                Tenant
                <MaterialInputCurrency
                  label="Amount paid per year ($)"
                  name="tenantOutgoings"
                  value={props.property.tenantOutgoings}
                  onChange={props.handleChange}
                  disabled={false}
                  helperText={props.helper.tenantOutgoings.errorText}
                  required={props.helper.tenantOutgoings.required}
                  hasError={props.helper.tenantOutgoings.hasError}
                  onBlur={props.onBlur}
                ></MaterialInputCurrency>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                Owner
                <MaterialInputCurrency
                  label="Amount paid per year ($)"
                  name="ownerOutgoings"
                  value={props.property.ownerOutgoings}
                  onChange={props.handleChange}
                  disabled={false}
                  helperText={props.helper.ownerOutgoings.errorText}
                  required={props.helper.ownerOutgoings.required}
                  hasError={props.helper.ownerOutgoings.hasError}
                  onBlur={props.onBlur}
                ></MaterialInputCurrency>
              </MDBCol>
            </MDBRow>
          </>
        )}
      </MDBContainer>
    </>
  );
}

export default PropertyLeaseDetails;
