let _objectorHelper = {
  firstName: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "text",
    fieldName: "First name",
    errorText: "",
  },
  lastName: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "text",
    fieldName: "Last name",
    errorText: "",
  },
  respondantType: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "select",
    fieldName: "Respondent type",
    errorText: "",
  },
  professionId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Profession",
    errorText: "",
  },
  companyName: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Company name",
    errorText: "",
  },
  objectionAuthorisation: {
    required: false,
    hasError: false,
    helperText: "",
    fieldName: "Objection authorisation",
    type: "check",
    errorText: "",
  },
  // preferredCommunicationMethodId this field has been removed
  preferredCommunicationMethodId: {
    required: false,
    hasError: false,
    helperText: "Required",
    type: "check",
    fieldName: "Preferred communication method",
    errorText: "",
  },
  postalAddress: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "freeText",
    fieldName: "Postal address",
    errorText: "",
    length: 200
  },
  suburb: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "text",
    fieldName: "Suburb",
    errorText: "",
    length: 50
  },
  state: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "text",
    fieldName: "State",
    errorText: "",
    length: 50
  },
  postcode: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "text",
    fieldName: "Postcode",
    errorText: "",
    length: 10
  },
  mobile: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "integer",
    fieldName: "Phone number",
    errorText: "",
    length: 15
  },
  email: {
    required: true,
    hasError: false,
    helperText: "Required",
    type: "email",
    fieldName: "Email",
    errorText: "",
  },
};

class ObjectorHelper {
  getObjectorHelper() {
    return _objectorHelper;
  }

  getFieldDetail(field) {
    let value = _objectorHelper[field];
    return value;
  }
}

// we need to instantiate the store
const objectorHelper = new ObjectorHelper();

export default objectorHelper;
