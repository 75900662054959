import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { getNamefromList } from "../../../utilities/getNameFromList";
import PropertyTypes from "../../../Lists/PropertyTypes";
import dwellingTypes from "../../../Lists/DwellingTypes";
import { valueCheck } from "../../../utilities/valueCheck";
import constructionMaterials from "../../../Lists/ConstructionMaterials";
import eraBuilt from "../../../Lists/EraBuilt";
import PropertyConditions from "../../../Lists/PropertyConditions";
import propertyCategories from "../../../Lists/PropertyCategories";

function ReviewPropertyDetails(props) {
  const property = props.property;
  const Property_Category_AVPCC = "7";
  const Not_Selected_Value = "-1";

  const showPropertyType = valueCheck(
    property.propertyTypeId,
    true,
    true,
    true,
    Not_Selected_Value
  );

  const showNumberOfRooms = valueCheck(
    property.numberRooms,
    true,
    true,
    false,
    null
  );

  const showDwellingType = valueCheck(
    property.dwellingTypeId,
    true,
    true,
    true,
    Not_Selected_Value
  );

  const showBathrooms = valueCheck(
    property.numberBathrooms,
    true,
    true,
    false,
    null
  );

  const showConstructionMaterial = valueCheck(
    property.constructionMaterialId,
    true,
    true,
    true,
    Not_Selected_Value
  );

  const showRooms = valueCheck(
    property.numberBedrooms,
    true,
    true,
    false,
    null
  );

  const showBuildingCondition = valueCheck(
    property.buildingConditionId,
    true,
    true,
    true,
    Not_Selected_Value
  );

  const showLandArea = valueCheck(property.landArea, true, true, false, null);

  const showYearBuilt = valueCheck(
    property.yearBuilt,
    true,
    true,
    true,
    Not_Selected_Value
  );

  const showBuildAreaM2 = valueCheck(
    property.buildAreaM2,
    true,
    true,
    false,
    null
  );

  const showYearRenovation = valueCheck(
    property.yearRenovation,
    true,
    true,
    false,
    null
  );

  const showNetLettableArea = valueCheck(
    property.netLettableArea,
    true,
    true,
    false,
    null
  );

  const showCarParks = valueCheck(property.carParks, true, true, false, null);

  const showDwellingDesc = valueCheck(
    property.dwellingDescription,
    true,
    true,
    false,
    null
  );

  const showPropertyCode =
    property.objectionReasonId === Property_Category_AVPCC ? true : false;

  const showPropertyDetailsComponent =
    showPropertyType ||
    showNumberOfRooms ||
    showDwellingType ||
    showBathrooms ||
    showConstructionMaterial ||
    showRooms ||
    showBuildingCondition ||
    showLandArea ||
    showYearBuilt ||
    showBuildAreaM2 ||
    showYearRenovation ||
    showNetLettableArea ||
    showCarParks ||
    showDwellingDesc;

  return (
    <>
      {showPropertyDetailsComponent && (
        <>
          <div>
            <MDBRow className="mb-3">
              <MDBCol className="review-section-headings">
                Property details
              </MDBCol>
            </MDBRow>

            {(showPropertyType || showNumberOfRooms) && (
              <>
                <MDBRow>
                  {showPropertyType && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Property type
                        </span>
                        <span className="review-page-field-value">
                          {getNamefromList(
                            PropertyTypes,
                            property.propertyTypeId.toString()
                          )}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                  {showNumberOfRooms && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Total number of rooms
                        </span>
                        <span className="review-page-field-value">
                          {property.numberRooms}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </>
            )}

            {showPropertyCode && (
              <>
                <MDBRow>
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Current property code
                      </span>
                      <span className="review-page-field-value">
                        {getNamefromList(
                          propertyCategories,
                          property.property_code.toString()
                        )}
                      </span>
                    </div>
                  </MDBCol>
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Contended property code
                      </span>
                      <span className="review-page-field-value">
                        {getNamefromList(
                          propertyCategories,
                          property.contended_property_code.toString()
                        )}
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </>
            )}

            {(showDwellingType || showBathrooms) && (
              <>
                <MDBRow>
                  {showDwellingType && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Building type
                        </span>
                        <span className="review-page-field-value">
                          {getNamefromList(
                            dwellingTypes,
                            property.dwellingTypeId.toString()
                          )}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                  {showBathrooms && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Bathrooms
                        </span>
                        <span className="review-page-field-value">
                          {property.numberBathrooms}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </>
            )}

            {(showConstructionMaterial || showRooms) && (
              <>
                <MDBRow>
                  {showConstructionMaterial && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Construction material
                        </span>
                        <span className="review-page-field-value">
                          {getNamefromList(
                            constructionMaterials,
                            property.constructionMaterialId.toString()
                          )}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                  {showRooms && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Bedrooms
                        </span>
                        <span className="review-page-field-value">
                          {property.numberBedrooms}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </>
            )}

            {(showBuildingCondition || showLandArea) && (
              <>
                <MDBRow>
                  {showBuildingCondition && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Building condition
                        </span>
                        <span className="review-page-field-value">
                          {getNamefromList(
                            PropertyConditions,
                            property.buildingConditionId.toString()
                          )}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                  {showLandArea && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Land area
                        </span>
                        <span className="review-page-field-value">
                          {property.landArea}
                          {property.landAreaUnitID === "1" ? "m²" : "ha"}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </>
            )}

            {(showYearBuilt || showBuildAreaM2) && (
              <>
                <MDBRow>
                  {showYearBuilt && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Era built
                        </span>
                        <span className="review-page-field-value">
                          {getNamefromList(
                            eraBuilt,
                            property.yearBuilt.toString()
                          )}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                  {showBuildAreaM2 && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Gross floor area
                        </span>
                        <span className="review-page-field-value">
                          {property.buildAreaM2}m²
                        </span>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </>
            )}

            {(showYearRenovation || showNetLettableArea) && (
              <>
                <MDBRow>
                  {showYearRenovation && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Renovation year
                        </span>
                        <span className="review-page-field-value">
                          {property.yearRenovation}
                        </span>
                      </div>
                    </MDBCol>
                  )}
                  {showNetLettableArea && (
                    <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                      <div className="review-page-field">
                        <span className="review-page-field-heading">
                          Net lettable area
                        </span>
                        <span className="review-page-field-value">
                          {property.netLettableArea}m²
                        </span>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              </>
            )}

            {showCarParks && (
              <>
                <MDBRow>
                  <MDBCol xl="6" lg="6" md="6" sm="6" xs="6" className="mb-3">
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Car parks
                      </span>
                      <span className="review-page-field-value">
                        {property.carParks}
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </>
            )}

            {showDwellingDesc && (
              <>
                <MDBRow className="mb-3">
                  <MDBCol>
                    <div className="review-page-field">
                      <span className="review-page-field-heading">
                        Property description
                      </span>
                      <span className="review-page-field-value">
                        {property.dwellingDescription}
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ReviewPropertyDetails;
