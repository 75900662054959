import React from "react";
import { MDBInput } from "mdbreact";

function MDBTextArea(props) {
  return (
    <div id={props.name + "-label"}>
      <MDBInput
        aria-label={props.name + "-label"}
        aria-labelledby={props.name + "-label"}
        type="textarea"
        id={props.id}
        name={props.name}
        label={props.label}
        value={props.value}
        required={props.required}
        onChange={props.onChange}
        disabled={props.disabled}
        error={props.hasError.toString()}
        outline
        maxLength={props.maxLength || 1000}
      />
    </div>
  );
}

export default MDBTextArea;
