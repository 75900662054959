let _salesEvidenceHelper = {
  address: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Address",
    errorText: "",
    length: 200
  },
  saleListingURL: {
    required: false,
    hasError: false,
    helperText: "",
    type: "url",
    fieldName: "Paste web address of online listing",
    errorText: "",
  },
  landAreaM2: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    isPositiveOnly: true,
    fieldName: "Land area",
    errorText: "",
    max: 1000000,
    min: 0,
  },
  buildAreaM2: {
    required: false,
    hasError: false,
    helperText: "",
    type: "numeric",
    isPositiveOnly: true,
    fieldName: "Build area",
    errorText: "",
    max: 100000,
    min: 0,
  },
  saleDate: {
    required: false,
    hasError: false,
    helperText: "",
    type: "date",
    fieldName: "Sale date",
    errorText: "",
  },
  salePrice: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Sale price ($)",
    errorText: "",
    max: 500000000,
    min: 1,
  },
  leaseAmountPa: {
    required: false,
    hasError: false,
    helperText: "",
    type: "currency",
    isPositiveOnly: true,
    fieldName: "Lease amount",
    errorText: "",
    max: 500000000,
    min: 1,
  },
  leaseTermId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "select",
    fieldName: "Lease term",
    errorText: "",
  },
  leaseOptions: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Lease renewal options",
    errorText: "",
  },
  buildingConditionId: {
    required: false,
    hasError: false,
    helperText: "",
    type: "slider",
    fieldName: "Building condition",
    errorText: "",
  },
  saleDescription: {
    required: false,
    hasError: false,
    helperText: "",
    type: "freeText",
    fieldName: "Sale description",
    errorText: "",
  },
};

class SalesEvidenceHelper {
  getSalesEvidenceHelper() {
    let newSaleEvidence = {};
    newSaleEvidence = Object.assign(newSaleEvidence, _salesEvidenceHelper);
    return newSaleEvidence;
  }

  getFieldDetail(field) {
    let value = _salesEvidenceHelper[field];
    return value;
  }

  getFieldDetailFromList(salesEvidence, field) {
    let value = salesEvidence[field];
    return value;
  }
}

// we need to instantiate the store
const salesEvidenceHelper = new SalesEvidenceHelper();

export default salesEvidenceHelper;
