import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

export default function MaterialInputCurrency(props) {
  const currencyfield = {
    width: "100%",
  };

  return (
    <TextField
      style={currencyfield}
      aria-label={props.label}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      disabled={props.disabled}
      error={props.hasError}
      onBlur={props.onBlur}
      hidden={props.hidden ? props.hidden : false}
      helperText={props.helperText}
      required={
        props.required === "required" || props.required === true ? true : false
      }
      placeholder={props.placeholder}
    />
  );
}
