//#region import
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { Redirect } from "react-router-dom";
import PropertyStore from "../../stores/propertyStore";
import SaleEvidenceDetails from "../SaleEvidenceDetails/SaleEvidenceDetails";
import ObjectorStore from "../../stores/objectorStore";
import * as PropertyActions from "../../actions/propertyActions";
import * as SalesEvidenceActions from "../../actions/salesEvidenceActions";
import { getNamefromList } from "../../utilities/getNameFromList";
import Councils from "../../Lists/Councils";
import constants from "../Constants";
import { stopLoader, startLoader } from "../../actions/applicationActions";
import { fieldValidator } from "../../utilities/fieldValidator";
import SalesEvidenceStore from "../../stores/salesEvidenceStore";
import { fieldLevelValidation } from "../../utilities/fieldValidator";
import FormatCurrency from "../../utilities/currencyformatter";
import Steppers from "../../controls/Steppers/Steppers";
import MobileLinearProgress from "../../controls/MobileLinearProgress/MobileLinearProgress";
import { isEmailLink } from "../../utilities/isEmailLink";
import CommonStores from "../../stores/commonStore";
import { ScrollTo } from "../../utilities/focusErrorControl";
import Alert from  "@material-ui/lab/Alert";
import "./SaleEvidence.scss";
//#endregion import

function SaleEvidence(props) {
  let emailObjectorId = isEmailLink(props);
  if (emailObjectorId) {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ValidateObjector",
        objectorId: emailObjectorId,
      });
  }

  //#region verifying if the loading of this component was using
  // routing or Did user entered a URL and opened this page
  var objector = ObjectorStore.getObjector();
  var propertyId = null;
  if (!objector || !objector.objectorId) {
    props &&
      props.history &&
      props.history.push({
        pathname: "/ObjectorDetails",
      });
  } else {
    // we came here means, we do have an Objector in flux / session
    // now check if we have a propertyId
    if (props && props.location && props.location.propertyId && props.location.propertyId !== '0') {
      propertyId = props.location.propertyId;
    } else {
      props &&
        props.history &&
        props.history.push({
          pathname: "/PropertyIdentification",
          objectorId: objector.objectorId,
        });
    }
  }
  //#endregion verifying the loading of this component

  //#region component declarations
  const [errorMessage, setErrorMessage] = useState(null);
  const [property] = useState(PropertyStore.getPropertyById(propertyId));
  const [salesEvidenceHelper, setSalesEvidenceHelper] = useState([]);
  const [salesEvidences, setSalesEvidences] = useState(
    SalesEvidenceStore.getSalesEvidenceByPropertyId(propertyId)
  );
  const [salesEvidenceText] = useState(CommonStores.getSalesEvidenceText());
  //#endregion component declarations

  useEffect(() => {
    PropertyStore.addChangeListener(onPropertyStoreChange);

    function onPropertyStoreChange() {
      setSalesEvidenceHelper(PropertyStore.getSalesEvidenceHelpers());
    }

    return () => PropertyStore.removeChangeListener(onPropertyStoreChange); //cleans up on unmount of component
  }, []);

  //#region useEffect
  useEffect(() => {
    SalesEvidenceStore.addChangeListener(onchange);

    document.title = "Object to rating valuation - Sale evidence";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (
      SalesEvidenceStore.getSalesEvidenceByPropertyId(propertyId).length === 0
    ) {
      SalesEvidenceActions.addSalesEvidence(propertyId);
    }

    function onchange() {
      setSalesEvidences(
        SalesEvidenceStore.getSalesEvidenceByPropertyId(propertyId)
      );
    }

    return () => SalesEvidenceStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, [propertyId]);

  //#endregion useEffect

  //#region Save Sales Evidence
  function saveSaleEvidence(addAnother, redirect) {
    startLoader();
    let count = 1;
    if (salesEvidences && salesEvidences.length > 0) {
      salesEvidences.forEach((sale) => {
        let isFieldValidationSuccess = fieldValidator(
          constants.SALE_EVIDENCE,
          sale,
          salesEvidenceHelper[count - 1]
        );

        if (!isFieldValidationSuccess) {
          setTimeout(() => {
            stopLoader();
            PropertyActions.updateSalesEvidenceHelper(salesEvidenceHelper);
          }, 100);
          return;
        }

        // since, with new material-ui date picker, we have to initialize date
        // with null, so API doesnt like it and returns "Invalid request body"
        // so making the sale date an empty string before api Request
        if (!sale.saleDate) {
          sale.saleDate = "";
        }

        let errorMessage = 'Sale evidence failed to save.	Click Back then Next to reload the step and try again.	If the problem persists, contact the council on your notice and lodge your objection directly with the rates team.';

        SalesEvidenceActions.saveSalesEvidence(
          propertyId,
          objector.objectorId,
          sale
        ).then((response) => {
          if (response && response.errors && response.errors.length > 0) {
            showError(errorMessage);
            stopLoader();
            return;
          } else {
          
          if (response && response.salesEvidenceId) {
            sale.salesEvidenceId = response.salesEvidenceId;
          }
          // since for update PUT, value will be null
          SalesEvidenceActions.dispatchUpdateSalesEvidence(sale);

          if (count === salesEvidences.length) {
            if (addAnother) {
              SalesEvidenceActions.addSalesEvidence(propertyId);
              // taking 1.5% of a second before we can focus on the newly
              // added element, since flux store takes time to refresh
              setTimeout(() => {
                let id = salesEvidences.length;
                let element = document.getElementById(
                  "saleEvidenceId" + id.toString()
                );
                if (element) {
                  ScrollTo(element);
                }
                stopLoader();
              }, 150);
            } else if (Redirect) {
              setTimeout(() => {
                stopLoader();
                props &&
                  props.history &&
                  props.history.push({
                    pathname: "/ReviewSubmission",
                    propertyId: propertyId,
                    objectorId: objector.objectorId,
                  });
              });
            }
          }
          count++;
        }
      }, (error) => {
          showError(errorMessage);
          stopLoader();
        });
      });
    }
  }
  //#endregion Save Sales Evidence

  function showError(message) {
    setErrorMessage(message);
    let element = document.getElementById("saleEvidenceError");
    if (element) {
      ScrollTo(element);
    }
  }

  //#region Go to Property Review Page
  function goToReviewSubmission() {
    saveSaleEvidence(false, true);
  }
  //#endregion  Go to Property Review Page

  //#region Event Handlers
  function sliderChange(event, value, index, fieldName) {
    if (event.target.id !== "" || (fieldName != null && value != null)) {
      let objectSalesEvidence = [...salesEvidences]; // copying the old datas array
      objectSalesEvidence[index][fieldName] = value; // replace e.target.value with whatever you want to change it to
      setSalesEvidences(objectSalesEvidence);
    }
  }

  function goToPropertyDetails() {
    props &&
      props.history &&
      props.history.push({
        pathname: "/PropertyDetails",
        propertyId: property.propertyId,
        objectorId: objector.objectorId,
      });
  }

  function handleChange({ target }, index) {
    salesEvidences[index][target.name] = target.value;
    SalesEvidenceActions.updateAllSalesEvidence(salesEvidences);
    return true;
  }

  function onDateChange(date, name, index) {
    if (isNaN(date) || date === null || date > new Date()) {
      salesEvidences[index][name] = null;
    } else {
      salesEvidences[index][name] = date;
    }
    SalesEvidenceActions.updateAllSalesEvidence(salesEvidences);
    return true;
  }

  function addAnotherSaleEvidence() {
    saveSaleEvidence(true, false);
  }

  function onBlur(event, index) {
    // field level required validation
    let fieldDetails = salesEvidenceHelper[index][event.target.name];

    let finalValue = event.target.value;
    if (finalValue && finalValue !== "") {
      if (fieldDetails && fieldDetails.type === "currency") {
        finalValue = FormatCurrency.removeCurrencyFormat(event.target.value);
        if (fieldDetails.isPositiveOnly) {
          finalValue = finalValue < 0 ? finalValue * -1 : finalValue;
        }
        finalValue = FormatCurrency.formatCurrency(
          FormatCurrency.removeCurrencyFormat(finalValue)
        );
      }
    }
    let objectSalesEvidence = [...salesEvidences]; // copying the old datas array
    objectSalesEvidence[index][event.target.name] = finalValue; // replace e.target.value with whatever you want to change it to
    setSalesEvidences(objectSalesEvidence);

    let field = {};
    field = Object.assign(field, salesEvidenceHelper[index][event.target.name]);
    let isFieldValid = fieldLevelValidation(field, event.target.value);

    let helper = {};
    helper = Object.assign(helper, salesEvidenceHelper[index]);
    helper[event.target.name] = field;
    salesEvidenceHelper.splice(index, 1, helper);
    PropertyActions.updateSalesEvidenceHelper(salesEvidenceHelper);

    if (!isFieldValid) {
      salesEvidences[index][event.target.name] = "";
      SalesEvidenceActions.updateAllSalesEvidence(salesEvidences);
    }
  }

  function deleteSalesEvidence(event) {
    let index = event.currentTarget.id;
    let sale = salesEvidences[index];
    let errorMessage = 'Sale evidence failed to delete.	Click Back then Next to reload the step and try again.	If the problem persists, contact the council on your notice and lodge your objection directly with the rates team.';
    if (sale && sale.salesEvidenceId) {
      SalesEvidenceActions.deleteSalesEvidence(
        propertyId,
        objector.objectorId,
        sale.salesEvidenceId
      ).then((response) => {
        if (response && response.errors && response.errors.length > 0) {       
          showError(errorMessage);
          stopLoader();
          return;
        } else {
          SalesEvidenceActions.dispatchDeleteSalesEvidence(sale.salesEvidenceId);
        }
      }, (error) => {
        showError(errorMessage);
          stopLoader();
          return;
      });
    } else {
      salesEvidences.splice(index, 1);
      SalesEvidenceActions.dispatchDeleteSalesEvidence(sale.salesEvidenceId);
    }
  }

  function addHelper(index) {
    if (index in salesEvidenceHelper === false) {
      let helperList = [];
      helperList = Object.assign(helperList, salesEvidenceHelper);
      helperList.push(PropertyStore.getSalesEvidenceHelper());
      setSalesEvidenceHelper(helperList);
    }
  }
  //#endregion Event Handlers

  //#region JSX code
  return (
    <>
      <MDBContainer>
        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <h4>{property.address}</h4>
            <h5>{getNamefromList(Councils, property.councilId)}</h5>
            <h5>Assessment number: {property.assessmentNumber}</h5>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol className="d-none d-md-block stepper-aligned">
            <Steppers location={props.location} />
          </MDBCol>
          <MDBCol className="d-block d-md-none stepper-aligned">
            <MobileLinearProgress
              location={props.location}
            ></MobileLinearProgress>
          </MDBCol>
        </MDBRow>

        {salesEvidenceText && (
          <>
            <MDBRow className="helper-text-background mb-3">
              <MDBCol>
                <div
                  className="cms-content-property-details"
                  dangerouslySetInnerHTML={{ __html: salesEvidenceText }}
                ></div>
              </MDBCol>
            </MDBRow>
          </>
        )}

        <MDBRow>
          <MDBCol xl="12" lg="12" md="12" sm="12" className="mb-3">
            <div id="saleEvidenceError"> 
            { errorMessage && 
              <Alert severity="error">
                { errorMessage }
              </Alert>
            }
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol xl="12" lg="12" md="12" sm="12" className="mb-3">
            <div id="propertyIdentificationError"> 
            { errorMessage && 
              <Alert severity="error">
                { errorMessage }
              </Alert>
            }
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
            {salesEvidences.map((value, index) => {
              return (
                <div key={index} className="mb-3">
                  {addHelper(index)}

                  <SaleEvidenceDetails
                    key={index}
                    index={index}
                    property={property}
                    salesEvidence={value}
                    salesEvidenceHelper={salesEvidenceHelper[index]}
                    handleChange={handleChange}
                    sliderChange={sliderChange}
                    onBlur={onBlur}
                    handleDelete={deleteSalesEvidence}
                    onDateChange={onDateChange}
                  />
                </div>
              );
            })}
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol>
            <div id="button-add-sale-evidence">
              <MDBBtn
                aria-labelledby="button-add-sale-evidence"
                className="button-style"
                name="AddSaleEvidence"
                onClick={addAnotherSaleEvidence}
              >
                Add another sale
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mb-3">
          <MDBCol id="buttons-section">
            <div className="float-left" id="backButton">
              <MDBBtn
                aria-labelledby="backButton"
                className="button-style"
                onClick={goToPropertyDetails}
              >
                Back
              </MDBBtn>
            </div>
            <div className="float-right" id="nextButton">
              <MDBBtn
                aria-labelledby="nextButton"
                className="button-style"
                onClick={goToReviewSubmission}
              >
                Next
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

//#endregion JSX code
export default SaleEvidence;
