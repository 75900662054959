import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import AttachmentStore from "../stores/attachmentsStore";
import { getRequest } from "../api/apiRequest";

export function getAttachments(propertyId, objectorId) {
  let url =
    "/objector/" + objectorId + "/property/" + propertyId + "/attachment";

  getRequest(url, true).then((result) => {
    if (result && result.length > 0) {
      let attachments = [];
      result.forEach((attachment) => {
        attachments.push({
          propertyId: propertyId,
          attachmentId: attachment.attachmentId,
          mimeType: attachment.mimeType,
          fileName: attachment.fileName,
        });
      });
      dispatchSaveAttachments(attachments);
    }
  });
}

export function dispatchSaveAttachments(attachments) {
  dispatcher.dispatch({
    actionType: actionTypes.SAVE_ATTACHMENTS,
    attachments: attachments,
  });
}

export function dispatchDeleteAttachments(attachmentId) {
  dispatcher.dispatch({
    actionType: actionTypes.DELETE_ATTACHMENT,
    attachmentId: attachmentId,
  });
}

export function initialiseAttachment() {
  AttachmentStore.initialiseAttachment();
}
