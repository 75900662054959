import * as ApplicationActions from "../actions/applicationActions";

export async function handleResponse(response) {
  if (
    response.status === 400 ||
    response.status === 404 ||
    response.status === 403
  ) {
    let result = await response.json();

    if (result.errors && result.errors.length > 0) {
      handleKnownError(result.errors[0]);
    }
    return result;
  }

  if (!response.ok) {
    return { errors: ['Unknown error'] }
  }

  return response && response.ok && response.status !== 204
    ? await response.json()
    : null;
}

function handleKnownError(error) {
  ApplicationActions.stopLoader();

  // WE HAVE TO HARDCODE THIS
  // IF PROPERTY IDENTIFICATION FAILS, WE DONT WANT POPUPS
  if (error.code === "PROPERTY_NOT_FOUND") {
    return;
  }

  setTimeout(() => {
    ApplicationActions.showModal({
      showModal: true,
      header: "Error",
      message: error.detail,
    });
  }, 500);
}

export function handleError(error) {
  ApplicationActions.stopLoader();

  return { errors: ['Unknown error'] }
}
