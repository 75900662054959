import { handleResponse, handleError } from "../api/apiUtils";

const baseUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const header = {
  "x-api-key": apiKey,
  "Content-Type": "application/json",
};

export function saveRequest(apiUrl, method, requestBody) {
  let URL = baseUrl + apiUrl;

  return fetch(URL, {
    headers: header,
    method: method,
    body: JSON.stringify(requestBody),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRequest(apiUrl, async) {
  const url = baseUrl + apiUrl;
  return fetch(url, {
    headers: header,
    method: "GET",
    async: false,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRequest(apiUrl) {
  const url = baseUrl + apiUrl;
  return fetch(url, {
    headers: header,
    method: "Delete",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRequestExternalApi(apiUrl) {
  return fetch(apiUrl, {
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAttachmentUrlRequest(apiUrl, method, requestBody) {
  apiUrl = baseUrl + apiUrl;
  return fetch(apiUrl, {
    headers: header,
    method: method,
    async: false,
    body: JSON.stringify(requestBody),
  })
    .then(async (response) => {
      return response ? await response.json() : null;
    })
    .catch(handleError);
}

export function uploadRequest(signedUrl, method, requestBody) {
  return fetch(signedUrl, {
    method: method,
    body: requestBody,
  })
    .then((response) => {
      return response; // we do not expect a response from upload
    })
    .catch(handleError);
}

export function deleteUpload(apiUrl) {
  return fetch(apiUrl, {
    method: "Delete",
  })
    .then(handleResponse)
    .catch(handleError);
}
