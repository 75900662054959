import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import HomePage from "./components/HomePage/HomePage";
import PropertyIdentification from "./components/PropertyIdentification/PropertyIdentification";
import ObjectorDetails from "./components/ObjectorDetails/ObjectorDetails";
import ObjectionDetails from "./components/ObjectionDetails/ObjectionDetails";
import PropertyDetails from "./components/PropertyDetails/PropertyDetails";
import SaleEvidence from "./components/SaleEvidence/SaleEvidence";
import ReviewSubmission from "./components/ReviewSubmission/ReviewSubmission";
import ModalPage from "./controls/MDBReact-Modal";
import { Route, Switch } from "react-router-dom";
import ApplicationStore from "./stores/applicationStore";
import * as ApplicationActions from "./actions/applicationActions";
import Footer from "./components/Footer/Footer";
import ValidateObjector from "./components/ValidateObjector/ValidateObjector";
import withClearCache from "./ClearCache";
import "./App.scss";

const ClearCacheComponent = withClearCache(MainApp);

function MainApp(props) {
  const [isLoading, setApplication] = useState(
    ApplicationStore.getApplicationStore().isLoading
  );

  const [modalProps, setModalProps] = useState(ApplicationStore.getModal());

  // to detect changes in ApplicationStore object
  useEffect(() => {
    ApplicationStore.addChangeListener(onchange);

    function onchange() {
      setApplication(ApplicationStore.getApplicationStore().isLoading);
      setModalProps(ApplicationStore.getModal());
    }

    return () => ApplicationStore.removeChangeListener(onchange); //cleans up on unmount of component
  }, []);

  function modalToggled() {
    ApplicationActions.hideModal({ showModal: false, header: "", message: "" });
  }

  return (
    <div className="page-container">
      <div className="content-wrap">
        {isLoading === true && (
          <div className="loading">
            <div className="loader"></div>
          </div>
        )}
        <Header />
        <Switch>
          <Route path="/" exact component={HomePage}></Route>
          <Route
            path="/ObjectorDetails"
            component={ObjectorDetails}
            name="Objector details"
          ></Route>
          <Route
            path="/PropertyIdentification"
            component={PropertyIdentification}
          ></Route>
          <Route path="/ObjectionDetails" component={ObjectionDetails}></Route>
          <Route path="/PropertyDetails" component={PropertyDetails}></Route>
          <Route path="/SaleEvidence" component={SaleEvidence}></Route>
          <Route path="/ReviewSubmission" component={ReviewSubmission}></Route>
          <Route path="/ValidateObjector" component={ValidateObjector}></Route>
          <Route component={HomePage} />
        </Switch>
      </div>
      <div className="page-footer">
        <Footer />
      </div>
      {modalProps.showModal && (
        <ModalPage
          header={modalProps.header}
          showModal={modalProps.showModal}
          message={modalProps.message}
          modalToggled={modalToggled}
        />
      )}
    </div>
  );
}

function App() {
  return <ClearCacheComponent />;
}

export default App;
