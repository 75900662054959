import constants from "../Constants";

export function storeItem(value, component) {
  sessionStorage.setItem(component, JSON.stringify(value));
}

export function retrieveItem(component) {
  let value = sessionStorage.getItem(component);
  try {
    return value ? JSON.parse(value) : null;
  }
  catch(error) {
    console.error(error);
    return null;
  }
}

export function removeItem(component) {
  sessionStorage.removeItem(component);
}

export function clearAll() {
  sessionStorage.clear();
}

export function clearAllExceptObjector() {
  // clearing everything from cache except objector details
  removeItem(constants.ATTACHMENT);
  removeItem(constants.PROPERTIES);
  removeItem(constants.PROPERTY_ID);
  removeItem(constants.PROPERTY_ID);
  removeItem(constants.SALE_EVIDENCE);
}
