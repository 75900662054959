//#region Imports
import { EventEmitter } from "events"; // this is node's build in event emitter class
import Dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import ObjectionDetailsHelper from "../FieldHelper/objectionDetailsHelper";
//#endregion Imports

//#region declarations
const CHANGE_EVENT = "change";
let _objectionDetailsHelper = ObjectionDetailsHelper.getObjectionDetailsHelper();
//#endregion declarations

class ObjectionStore extends EventEmitter {
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback); // change is the event
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  getObjectionDetailsHelper() {
    return _objectionDetailsHelper;
  }
}

// we need to instantiate the store
const store = new ObjectionStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
    case actionTypes.UPDATE_OBJECTION_DETAILS_HELPER:
      _objectionDetailsHelper = action.objectionDetailsHelper;
      store.emitChange();
      break;

    default:
      //do nothing
      break;
  }
});

export default store;
