import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

function MaterialCheckbox(props) {
  const checkboxStyle = {
    display: "inline-flex",
  };
  return (
    <>
      <Checkbox
        style={checkboxStyle}
        onChange={props.onChange}
        id={props.name}
        name={props.name}
        color="primary"
        checked={props.checked}
        inputProps={{
          "aria-labelledby": "label" + props.name,
        }}
      />
      <label htmlFor={props.name} aria-label={props.name}>
        {props.label}
      </label>
    </>
  );
}

export default MaterialCheckbox;
