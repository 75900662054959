import React from "react";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

function MaterialInputDate(props) {
  const textfield = {
    width: "100%",
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
          margin="normal"
          id={props.id}
          name={props.name}
          label={props.label}
          format="dd/MM/yyyy"
          value={props.value}
          onChange={props.onChange}
          KeyboardButtonProps={{
            "aria-label": props.label,
          }}
          style={textfield}
          helperText={props.helperText ? props.helperText : ""}
          error={props.hasError}
          disabled={props.disabled}
          required={props.required}
          InputLabelProps={{ shrink: true, id: "label-" + props.name }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default MaterialInputDate;
